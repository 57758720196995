<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Actiepunten', url:'actionCreate'}]"></breadcrumbs-bar>
        <div>
            <h2 class="page__title">Actiepunten</h2>
        </div>
        <standard-bar></standard-bar>
    </div>
</template>

<script>
    import BreadcrumbsBar from '@/components/html/BreadcrumbsBar';
    import StandardBar from '@/pages/layout/StandardBar';
    export default {
        name: 'ActionPointHeader',
        components: { StandardBar, BreadcrumbsBar }
    };
</script>

<style scoped>

</style>