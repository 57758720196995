<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-7">
                <order-debtor></order-debtor>
            </div>
            <div class="col-md-3">
                <order-overview></order-overview>
            </div>
            <div class="col-md-2">
                <order-totals></order-totals>
            </div>
        </div>
        <div class="row mt-4 mb-2">
            <div class="col-md-12">
                <div class="section section__container pb-5 mb-2">
                    <h2 class="page__title">Order plaatsen</h2>
                    <div class="row">
                        <div class="col-md-4">

                            <form-builder :form="putOrderForm.orders"></form-builder>
                        </div>
                        <div class="col-md-4">
                            <form-builder :form="putOrderForm.delivery"></form-builder>

                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <span class="list__separator"></span>
                    <div class="float-right">
                        <button class="button button-green-inverse">Opslaan</button>
                        <button class="button button-danger-inverse ml-1">Verwijderen</button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import OrderDebtor from "@/modules/order/components/OrderDebtor";
    import OrderOverview from "@/modules/order/components/OrderOverview";
    import OrderTotals from "@/modules/order/components/OrderTotals";
    import FormBuilder from "@/components/partials/FormBuilder";
    import putOrderForm from "@/forms/putOrderForm.json";
    export default {
        name: "OrderCreate",
        components: {FormBuilder, OrderTotals, OrderOverview, OrderDebtor},
        data() {
            return {
                putOrderForm: putOrderForm,
            }
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style scoped>

</style>