<template>
    <div>
        <h2 class="" v-if="title">{{ title }}</h2>
        <div class="row">
            <div class="col-8 search__form">
                <label for="search" class="search__form__label"
                       :class="{'search__form__label-active' : focus || value.length > 0}"
                       ref="search_label">{{ $i18n.t('general.search') }}</label>
                <input id="search" class="search__form__input" v-on:focus="focusEventIn" v-on:focusout="focusEventOut"
                       v-model="value" @keyup.enter="search">
            </div>
            <div class="col-4">
                <button class="button button-green ml-4 button-full search__button" style="position: relative;bottom: 5px" type="button" v-on:click="search" ref="search_button">{{ $i18n.t('general.search') }}
                </button>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchInput",
        props: ['title'],
        data() {
            return {
                focus: false,
                value: ""
            }
        },
        methods: {
            focusEventIn() {
                this.focus = true;
                this.$emit("focusEventIn");
            },
            focusEventOut() {
                this.focus = false;
                this.$emit("focusEventOut");
            },
            search() {
                this.$emit('valueSearched', this.value);
                this.$refs.search_button.focus();
            }
        }
    }
</script>

<style scoped>

</style>
