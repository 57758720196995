import i18n from '@/i18n';
import Manager from '@/pages/layout/Manager';
import ManagerManage from '@/modules/manager/pages/ManagerManage';

export default [
    {
        name: 'manager',
        component: Manager,
        children: [
            {
                path: "/manager/manage",
                component: ManagerManage,
                name: "managerManage",
                label: i18n.t("manager_bar.manage")
            }
        ]
    }
]