<template>
    <div>
        <search-input @valueSearched="fetchDebit" @focusEventOut="activeShortcuts = true"
                      @focusEventIn="activeShortcuts = false"></search-input>
        <loading-spinner></loading-spinner>
        <div ref="debit_search">
            <div class="debit__search-container mt-3" v-for="debtor in debtors"
                 v-bind:key="debtor.number" :class="{'debit__search-container-selected' : debtor.selected}"
                 :id="'debit__search-'+debtor.number">
                <debit-search-result :selected="null" :debtor="debtor"
                                     :active="debtor.selected"></debit-search-result>
            </div>
        </div>
    </div>
</template>
<script>
    import SearchInput from "@/components/partials/SearchInput";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    import Debtor from "@/modules/debtor/classes/Debtor";
    import DebitSearchResult from "@/modules/debtor/components/DebitSearchResult";
    export default {
        name: "DebitSearchDebtors",
        components: {DebitSearchResult, LoadingSpinner, SearchInput},
        data() {
            return {
                debtorClass: new Debtor(),
                showingDebtors: [],
                debtors: [],
                activeDebtor: {},
                searchQuery: "",
            }
        },
        created() {
        },
        methods: {
            fetchDebit(searchValue) {
                this.debtorClass.search(searchValue, '' , 'search', 'get').then((response) => {
                    if (Array.isArray(response.data)) {
                        this.debtors = response.data;
                    }else {
                        this.debtors = [response.data];
                    }
                });
                // this.debtorClass.fetchDebtors(searchValue).then((response) => {
                //     this.debtors = response;
                // });
            },
        },

    }
</script>

<style scoped>

</style>