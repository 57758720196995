const productStore = {
    state: {
        activeProduct: undefined,
    },
    mutations: {
        setActiveProduct(state, value) {
            state.activeProduct = value;
        },
    },
    actions: {},
    getters: {}
};

export default productStore;
