<template>
    <div>
        <div class="button__container">
            <div class="text-center d-flex justify-content-center align-items-center w-100 h-100">
                <div>
                    <i :class="icon"></i>

                    <p class="m-0">{{ label }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DragSidebarChoice",
        props: ['label', 'icon']

    }
</script>

<style scoped>
.button__container {
    width: 90px;
    height: 90px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid grey;
}

.button__container:hover {
    background-color: rgba(7, 109, 25, 0.5);
}
</style>