<template>
    <div class="section">
        <div class="section__container">
            <vue-cal style="height: 480px"
                     :events="events" :time-from="6 * 60"></vue-cal>
        </div>

    </div>
</template>

<script>
    import VueCal from 'vue-cal'
    import 'vue-cal/dist/vuecal.css'

    export default {
        name: "CalendarWidget",
        components: {VueCal},
        data() {
            return {
                events: []
            }
        },
        created() {
            this.getEvents();
        },
        methods: {
            getEvents() {
                this.events = [
                    {
                        start: '2021-01-8 10:30',
                        end: '2021-01-8 12:30',
                        title: 'appointment',
                        content: '<i class="v-icon material-icons">local_hospital</i>',
                        class: 'health'
                    },
                    {
                        start: '2021-01-9 10:30',
                        end: '2021-01-9 13:00',
                        // You can also define event dates with Javascript Date objects:
                        // start: new Date(2018, 11 - 1, 16, 10, 30),
                        // end: new Date(2018, 11 - 1, 16, 11, 30),
                        title: 'Control appointment',
                        content: '',
                        class: 'health'
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>
