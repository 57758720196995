<template>
    <div v-if="inv.invoice_number" class="mt-4">
        <information-bar :filter="order.filter" :object="inv" :noFold="true">
            <template v-slot:title>
                <h2 class="debit__result__title">{{ inv.invoice_number }}</h2>
            </template>
            <template v-slot:content>
                <div class="row">
                    <information-bar-content header="Factuurdatum" :text="inv.invoice_date"></information-bar-content>
                    <information-bar-content header="Order bedrag" :text="inv.amount"></information-bar-content>
                    <information-bar-content header="Opdracht" :text="inv.order_number"></information-bar-content>
                </div>
                <div class="row">
                    <information-bar-content header="Leverdatum" :text="inv.delivery_date"></information-bar-content>
                    <information-bar-content header="Routetijd" :text="inv.route_time"></information-bar-content>
                    <information-bar-content header="Routecode" :text="inv.route_code"></information-bar-content>
                </div>
                <div class="row">
                    <information-bar-content header="Status" :text="inv.status"></information-bar-content>
                    <information-bar-content header="Verkoper" :text="inv.seller"></information-bar-content>
                    <information-bar-content header="Verkoper 2" :text="inv.seller_second"></information-bar-content>
                </div>


            </template>
        </information-bar>
        <div class="row mt-4 mb-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <order-packing :data="inv.packing" :extended="true"></order-packing>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Order from "@/modules/debtor/classes/Order";
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    import OrderPacking from "@/modules/order/components/OrderPacking";

    export default {
        name: "DebitPacking",
        components: {OrderPacking, InformationBarContent, InformationBar},
        data() {
            return {
                packing_id: this.$route.params.packing,
                order: new Order(this.$route.params.number),
                inv: []
            }
        },
        created() {
            this.fetchOrder();
        },
        methods: {
            fetchOrder() {
                this.order.all("/" + this.packing_id).then((response) => {
                    this.inv = response.data;
                });
            }
        }
    }
</script>

<style scoped>

</style>