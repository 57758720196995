<template>
    <div>
        <div class="row m0">
            <div class="col-5">
                <p class=" pt-3">{{ label }}</p>
            </div>
            <div class="col-7" v-if="data === 'N'">Nee
            </div>
            <div class="col-7" v-else-if="data === 'J'">
                Ja
            </div>
            <div v-else class="col-7" >
                <edit-component style="margin-top: 11px" :data="data" @dataChanged="dataChanged"></edit-component>
            </div>
            <span class="list__separator"></span>
        </div>
    </div>
</template>
<script>
    import EditComponent from "@/components/partials/form/EditComponent";
    import Debtor from '@/modules/debtor/classes/Debtor';
    import Alert from '@/classes/helper/Alert';
    export default {
        name: "DebitEditComponent",
        components: {EditComponent},
        props: ["label","name","data"],
        data() {
            return {
                debit: new Debtor()
            }
        },
        methods: {
            dataChanged(value) {
                this.debit.fillable.push(this.name)
                this.debit[this.name] = value;
                this.debit.id = this.$route.params.number;
                this.debit.update(this.debit).then(() => {
                    Alert.createAlert('success', 'Debiteur veld opgeslagen')
                });
            }
        }
    }
</script>

<style scoped>

</style>