import GeneralModel from '@/classes/helper/GeneralModel';
export default class extends GeneralModel{
    activeShortcuts = false;
    activeDebtor = undefined;
    fillable = [''];
    id = '';
    name = '';
    constructor(route_affix = "", debtorNumber = "") {
        if (debtorNumber !== "") {
            super( "company" + "/" + debtorNumber +route_affix)
        } else {
            super( "company" + route_affix)
        }
    }

}