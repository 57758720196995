<template>
    <div class="heading__container display-inline">
        <button class="button button-small button-green-inverse" v-on:click="activeLayoutButton" style="width: 80px" :class="{'button-green-inverse-active': activeLayout === heading}">
            {{ heading }}
        </button>
    </div>
</template>
<script>
    export default {
        name: "DragSidebarCategories",
        props: ["heading", "activeLayout"],
        emits: ['activateLayout'],
        created() {
        },
        methods: {
            activeLayoutButton() {
                this.$emit('activateLayout', this.heading);
            }
        }
    }
</script>

<style scoped>
.heading__container {
    text-align: center;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
}
.heading__container:hover {
    background-color: rgba(7, 109, 25, 0.5);
}
</style>