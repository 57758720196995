<template>
    <div class="">
        <div class="table__list">
            <div class="section section__container">
                <h2 class="ml-2 mt-2 float-left">{{ title }}</h2>
                <button v-if="button" class="button button-green button-small float-right mt-4">{{ button }}</button>


                <table class="table ">
                    <thead>
                        <tr>
                            <th scope="col" v-for="(header, index) in headers" v-bind:key="index">{{ header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(order, index) in data" v-bind:key="index">
                            <th scope="col" v-for="(data, index) in order" v-bind:key="index">{{ data }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'ListWidget',
        props: ['title', 'headers', 'data', 'button'],
        created() {
            
        }
    };
</script>

<style scoped>
</style>
