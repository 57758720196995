<template>
    <div>
         <div>
            <report-create :noRedirect="true" location="debtor"></report-create>
        </div>
    </div>
</template>
<script>
import ReportCreate from '@/modules/report/pages/ReportCreate';
import ReportLayout from '@/modules/report/classes/ReportLayout';

export default {
name: "DebitReportCreate",
    components: { ReportCreate},
    data() {
        return {
            options: [],
            reportLayout: new ReportLayout(),
            name: "",
            name_danger: false,
            name_danger_text: "",
        }
    },
    created() {
    },
    methods: {
    }
 
}
</script>

<style scoped>

</style>