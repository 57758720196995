import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    constructor(id = "", route_affix = "") {
        route_affix = "/bonus" + route_affix;
        if (id !== "") {
            super( "debtor" + "/" + id +route_affix)
        } else {
            super( "debtor" + route_affix)
        }
    }
}