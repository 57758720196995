<template>
    <div class="section section__container mt-4">
        <h2 class="page__title">{{ $t("settings.new_debit")}}</h2>
        <div class="row">
            <div class="col-md-4">
                <form-builder :form="form_part_1"></form-builder>
            </div>
            <div class="col-md-4">
                <form-builder :form="form_part_2"></form-builder>
            </div>
            <div class="col-md-4">
                <form-builder :form="form_part_3"></form-builder>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <span class="list__separator"></span>
                <div class="float-right">
                    <button class="button button-green-inverse" v-on:click="submitForm">{{ $t("form.save") }}</button>
                    <button class="button button-danger-inverse ml-4">{{ $t("form.empty") }}</button>
                </div>
                </div>
        </div>
    </div>
</template>

<script>
    import FormBuilder from "@/components/partials/FormBuilder";
    import Debtor from '@/modules/debtor/classes/Debtor';
    let form = require("@/forms/createDebitForm.json");
    export default {
        name: "SettingsCreateDebit",
        components: {FormBuilder},
        data() {
            return {
                form_part_1: form.form_part_1,
                form_part_2: form.form_part_2,
                form_part_3: form.form_part_3,
                debtor: new Debtor()
            }
        },
        methods: {
           submitForm() {
               this.$store.commit('validateForms');
               /*
                * @TODO: Een goede oplossing hiervoor bedenken
                */
               for (let field in this.form_part_1) {
                   this.debtor.fillable.push(field);
                   this.debtor[field] = this.form_part_1[field].value;
               }
               for (let field in this.form_part_2) {
                   this.debtor.fillable.push(field);
                   this.debtor[field] = this.form_part_2[field].value;
               }
               for (let field in this.form_part_3) {
                   this.debtor.fillable.push(field);
                   this.debtor[field] = this.form_part_3[field].value;
               }
               
               this.debtor.store(this.debtor).then((response) => {
                   this.$router.push({
                       name: 'debitInfo',
                       params: {number: response.debiteurnummer}
                   });
               });
           }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>