<template>
    <div class="section section__container">
        <h2 class="page__title">Instellingen</h2>
        <div class="dashboard-create__settings">
            <div class="row">
                <div class="col-4">
                    <p>Type</p>
                    <p>Data</p>
                </div>
                <div class="col-8">
                    <p>{{ $t('dashboard.' + widget.type) }}</p>
                    <select ref="dropdown" id="year" name="year" class="select__input ml-0" v-on:change="selectData" v-model="settings.data">
                        <option v-for="valueSelect in widget.data" v-bind:key="valueSelect" :value="valueSelect" class="select__option">{{ valueSelect }}</option>
                    </select>
                </div>

            </div>
            <div class="row mt-4 pb-2">
                <div class="col-md-12 text-center">
                    <button class="button button-green-inverse" v-on:click="saveSettings">Opslaan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WidgetSettingsPlaceholder',
        components: {},
        props: ['widget', 'col', 'row'],
        emits: ['settingsSaved'],
        data() {
            return {
                settings: {
                    data: this.widget.data[0]
                },
                value: this.widget.data[0]
            };
        },
        methods: {
            saveSettings() {
                this.$emit('settingsSaved', [this.row, this.col, this.settings]);
            },
            selectData() {

            }
        }
    };
</script>

<style scoped>

</style>