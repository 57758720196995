<template>
    <div>
        <div class="section section__container">
            <h2 class="page__title">Bedreigingen</h2>
        </div>
    </div>

</template>

<script>

    export default {
        name: "ThreatsWidget",
        data() {
            return {
            }
        },
        created() {
            // this.threats.update().then((response) => {
            //     console.log(response)
            // });
        }
    }
</script>

<style scoped>

</style>