<template>
    <div>
        <div :class="{'hidden-info' : !(content.value !== '' && disabled)}">
            <div v-if="content.value">
                <div class="p0 p-2 bordered" >
                    <div class="d-flex justify-content-space-between">
                        <label class="flex-1">{{ content.label }}</label>

                        <div class="display-inline text-right flex-1" style="top: -50px!important;">
                            <i class="fas fa-edit icon__show" :class="{'icon__hidden':!disabled}" v-on:click="setEditable"></i>
                            <i class="fas fa-trash ml-2 mt-2  color-danger" :class="{'icon__hidden':!disabled}" v-on:click="deleteLabel"></i>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" :class="{'hidden-info' : (content.value !== '' && disabled)}">
            <div class="col-md-6">
                <input-component label="Label toevoegen" v-model="label" name="label" @changeEvent="changedValue" :initialValue="content.label"
                                 no-trans="true"></input-component>
            </div>
            <div class="col-md-6">
                <select-component no-trans="true" @selectedEvent="changedValue" :data="stats.elements" :selectedValue="content.value"
                                  label="Selecteer type omzet"></select-component>
                <div class="button_end">
                    <i class="fas fa-save icon__show" :class="{'icon__hidden':disabled}" v-on:click="removeEditable"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DragElements from "@/plugins/DragDrop/classes/DragElements";
    import SelectComponent from "@/components/partials/SelectComponent";
    import InputComponent from "@/components/partials/InputComponent";

    export default {
        name: "DragInfoStats",
        components: {InputComponent, SelectComponent},
        props: ['content', 'indexCol', 'indexRow', 'index'],
        data() {
            return {
                disabled: false,
                dragElements: new DragElements(this.indexRow, this.indexCol, this.index),
                options: ["Automaterialen", "DIV"],
                value: "",
                label: "",
                stats: this.$store.state.dragDropStore.stats,
                date: new Date()
            }
        },
        created() {
            if (this.content.value !== '') {
                this.value = this.content.value;
                this.disabled = true;
            }
        },
        methods: {
            deleteLabel() {
                this.dragElements.deleteContent();
            },
            changedValue(e) {
                if (e[0] === "label") {
                    this.label = e[1]
                } else {
                    this.value = e[1];
                }
            },
            setEditable() {
                this.disabled = false;
            },
            removeEditable() {
                if (this.value !== "") {
                    this.disabled = true;
                    this.dragElements.setValueSelect(this.value, this.label);
                }

            }
        }
    }
</script>

<style scoped>

</style>