<template>
    <div class="mt-4">
        <div class="row ml-0 mr-0 section__container--no-round-top">
            <div class="col-md-4 p0 text-center color-main-green pointer tab-inactive" :class="{'tab-active' : activeTab === 0}" v-on:click="setActiveTab(0)">
                <p class="tab-line-height">Rapportage</p>
            </div>
        </div>
        <div class="section section__container section__container--no-round-top relative">
            <report-settings v-if="activeTab === 0 || closing"  :class="{'show' : activeTab === 0 && !closing, 'closing': closing}"></report-settings>
        </div>
    </div>
</template>

<script>
    import ReportSettings from "@/modules/settings/components/ReportSettings";
    export default {
        name: "Settings",
        components: {ReportSettings},
        data() {
            return {
                activeTab: 0,
                closing: false
            }
        },
        methods: {
            setActiveTab(tab) {
                this.$emit("closeAction");
                this.actionPointShows = undefined;
                this.closing = true;
                setTimeout(() => {
                    this.closing = false;
                }, 300);
                this.activeTab =  tab;
            },
        }
    }
</script>

<style scoped>
.hidden {
    display: none;
    position: relative;
    opacity: 0;
    width: 0;
    height: 0;
    right: 0;
    left: 150px;
    transition: left 0.3s ease-in-out, right 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.closing {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    left: -150px;
    right: 0;

}
.show {
    display: block;
    opacity: 1;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    right: 150px;
}

.tab-line-height {
    position: relative;
    top: 8px;
}
.tab-inactive {
    background-color: #f0f0f0;
    transition: background-color 0.3s ease-in-out;
}
.tab-active {
    background-color: white;
}
.tab-inactive:hover {
    background-color: white;

}
</style>