<template>
    <div class="row">
        <div class="col-10">
            <label class="label" v-on:dblclick="setEditable">
                <input v-model="value" class="input" :class="{'input__editable' : !disabled}" :disabled="disabled" ref="inputEdit"
                       v-on:focusout="removeEditable"
                       @keyup.enter="removeEditable">
            </label>
        </div>
        <div class="col-2">
            <i class="fas fa-edit icon__show" :class="{'icon__hidden':!disabled || !$store.state.generalStore.enableEdit}" v-on:click="setEditable"></i>
            <i class="fas fa-save icon__show" :class="{'icon__hidden':disabled}" v-on:click="removeEditable"></i>
        </div>

    </div>
</template>

<script>
    export default {
        name: "EditComponent",
        props: ['data', 'name'],
        emits: ['dataChanged'],
        data() {
            return {
                value: this.data,
                oldData: this.data,
                disabled: true
            }
        },
        methods: {
            removeEditable() {
                this.disabled = true;
                if (this.value !== this.oldData) {
                    this.oldData = this.value;
                    if (this.name) {
                        this.$emit('dataChanged', this.value, this.name);
                    }else {
                        this.$emit('dataChanged', this.value);
                    }
                }
            },
            setEditable() {
                if (this.$store.state.generalStore.enableEdit) {
                    this.disabled = false;
                    setTimeout(() => {
                        this.$refs.inputEdit.focus();

                    }, 50);
                }

            }
        }
    }
</script>

<style scoped>
.icon__show {
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    top: 8px;
    color: rgba(24, 24, 24, 0.3);
}
.icon__hidden {
    z-index: 1;
    opacity: 0;
}
.label {
    width: 100%;
}
.input {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px
}
.input__editable {
    border: 1px solid black;
}
.input:disabled {
    background-color: transparent;
}
</style>