<template>
    <div v-if="data" class="search__form">
        <label for="year" :class="isLabelClass" v-if="noTrans">{{ label }}</label>
        <label for="year" :class="isLabelClass" v-else>{{ $t(label) }}</label>
        <select ref="dropdown" :id="name" :name="name" :class="isClassName" v-on:change="selectData" v-model="value">
            <option v-for="valueSelect in data" v-bind:key="valueSelect" :value="optionData(valueSelect)" class="select__option" 
            :selected="optionData(valueSelect) === valueSelect" >{{optionData(valueSelect, true)}} </option>
        </select>
        <!--<i class="fas  fa-chevron-down select__icon"></i>-->
    </div>
</template>

<script>
    export default {
        name: "SelectComponent",
        props: ['data', 'selectedValue', 'label', 'name', 'noTrans', 'class_name', 'placeholder', 'label_class'],
        emits: ['selectedEvent'],
        data() {
            return {
                value: this.selectedValue,
                open: false,
            }
        },
        computed: {
            isClassName: function(){
                if(this.$props.class_name === undefined){
                     return 'select__input';
                }
                return this.$props.class_name;
               
            },
            isLabelClass: function(){
                if(this.$props.class_name === undefined){
                    return 'select__label';
                }
                return this.$props.label_class;
            }
        },

        methods: {
            openSelect() {

            },
            selectData() {
                this.$emit('selectedEvent', [this.name, this.value]);
            },
            optionData(option ,label = false) {
                let value = null;
                if(option instanceof Object){
                    if(label){
                        value = option.label;
                    }else {
                        value = option.value;
                    }
                }else {
                    value = option;
                }

                return value;
            }
        }
    }
</script>

<style scoped>

</style>