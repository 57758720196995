<template>
    <div>
        <div class="section">
            <div class="section__container float-left">
                <h2 class="page__title">{{ data.settings.data }}</h2>
                <div class="d-flex justify-content-center">
                    <apexchart type="pie" :options="chartOptions" :series="[]"></apexchart>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'PieWidget',
        props: ['data', 'title', 'settings'],
        created() {
           
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        width: '100%',
                        type: 'pie',
                    },
                    labels: [],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 380
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                }
            };
        }
    };
</script>

<style scoped>

</style>
