<template>
  <search-input @valueSearched="fetchDebit" @focusEventOut="activeShortcuts = true"
                      @focusEventIn="activeShortcuts = false"></search-input>
  <loading-spinner></loading-spinner>

</template>
<script>
    import SearchInput from "@/components/partials/SearchInput";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";

    export default {
        name: "ActionPointSearch",
        components: {LoadingSpinner, SearchInput},
        data() {
            return {
               
            }
        },
    }
</script>