<template>
    <div>
        <div v-for="(element, index) in elements.layout" v-bind:key="element" class="mt-1">
            <div v-if="element.component === 'label'">
                <drag-label :content="element" :index="index" :indexCol="indexCol" :indexRow="indexRow"></drag-label>
            </div>
            <div v-else-if="element.component === 'input'">
                <drag-input :content="element" :index="index" :indexCol="indexCol" :indexRow="indexRow"></drag-input>
            </div>
            <div v-else-if="element.component === 'info-stats'">
                <drag-info-stats :content="element" :index="index" :indexCol="indexCol" :indexRow="indexRow"></drag-info-stats>
            </div>
            <div v-else-if="element.component === 'info-debit'">
                <drag-info-debit :content="element" :index="index" :indexCol="indexCol" :indexRow="indexRow"></drag-info-debit>
            </div>
            <div v-else-if="element.component === 'info-actionpoint'">
                <drag-action-point></drag-action-point>
            </div>
            <div v-else-if="element.component === 'info-educations'">
                <drag-educations></drag-educations>
            </div>
            <div v-else-if="element.component === 'info-part-concept'">
                <drag-part-concept></drag-part-concept>
            </div>
            <div v-else-if="element.component === 'input-debtor'">
                <drag-debit-input :content="element" :index="index" :indexCol="indexCol" :indexRow="indexRow"></drag-debit-input>
            </div>
            <div v-else-if="element.component === 'opt-selection'">
                <drag-opt-selection :content="element" :index="index" :indexCol="indexCol" :indexRow="indexRow"></drag-opt-selection>
            </div>
        </div>
    </div>
</template>

<script>
    import DragLabel from '@/plugins/DragDrop/elements/DragLabel';
    import DragInput from "@/plugins/DragDrop/elements/DragInput";
    import DragInfoStats from "@/plugins/DragDrop/elements/DragInfoStats";
    import DragInfoDebit from "@/plugins/DragDrop/elements/DragInfoDebit";
    import DragActionPoint from "@/plugins/DragDrop/elements/DragActionPoint";
    import DragEducations from "@/plugins/DragDrop/elements/DragEducations";
    import DragPartConcept from "@/plugins/DragDrop/elements/DragPartConcept";
    import DragDebitInput from "@/plugins/DragDrop/elements/DragDebitInput";
    import DragOptSelection from "@/plugins/DragDrop/elements/DragOptSelection";
    export default {
        name: 'DragElementsBuilder',
        components: {DragInfoDebit, DragInfoStats, DragInput, DragLabel, DragActionPoint, DragEducations, DragPartConcept, DragDebitInput, DragOptSelection },
        props: ['elements', 'indexCol', 'indexRow'],
        methods: {
        }
    };
</script>

<style scoped>

</style>