<template>
  <div>
    <vue-cal style="height: 480px"
           v-if="events"
           :events="events"
           :time-from="6 * 60"
           :time-to="21 * 60"
           :disable-views="['years']"
           :on-event-click="onEventClick"
           locale="nl"
  ></vue-cal>
    <loading-spinner-manual :spinner="spinner"></loading-spinner-manual>
  </div>

</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/nl.js'
import 'vue-cal/dist/vuecal.css'
import Api from "@/classes/helper/Api";
import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

export default {
  name: 'AgendaComponent',
  components: {LoadingSpinnerManual, VueCal},
  props: ['rayon'],
  data() {
    return {
      events: undefined,
      showDialog: false,
      api: new Api(),
      spinner: false,
    }
  },
  watch: {
    rayon: function () {
        this.getEvents();
    }
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    onEventClick (event, e) {
      this.selectedEvent = event
      this.showDialog = true

      this.$router.push({name: "debitInfo", params: {'number': event.debtor_id}})
      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation()
    },

    //onEventClick(e) {
      //this.$router.push({name: "debitReport", params: {'number': e.class}})
    //},
    getEvents() {
      this.spinner = true;
      

      this.api.get("visitschema/visits", [{"rayon": this.$props.rayon }]).then((response) => {
        this.events = response.data;
        this.spinner = false;
      }).catch(() => {
        this.events = [];
        this.spinner = false;
      });
    }
  }
};
</script>

<style scoped>

</style>