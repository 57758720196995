import Debtor from '@/modules/debtor/classes/Debtor';

export default class extends Debtor {
    div = {};
    license = {};
    equipment = {};
    car = {};
    fluids = {};
    garage = {};
    tyre = {};
    id = undefined;
    elements = ["div", "license", "equipment", "car", "fluids", "garage", "tyre"];

    constructor(id = "") {
        super(id,'/statistics' );
        this.id = id
    }

    setStats(statistics){
        Object.entries(statistics).forEach( statisticData=> {
             this[statisticData[0]] = statisticData[1];
        });
    }

    parseStats(statistics) {
        for (let stat in statistics) {
            let statData = statistics[stat];
            let statisticParsed = {};
            statisticParsed[stat] = {};
            for (let revenue in statData) {
                let rev = parseFloat(statData[revenue]);
                if (revenue.includes('omzet')) {
                    if (!revenue.includes('omzetret')) {
                        let year = revenue.slice(5,9);
                        let month = revenue.slice(9,11);
                        if (statisticParsed[stat][year] === undefined) {
                            statisticParsed[stat][year] = {};
                        }
                        if (statisticParsed[stat][year][month] === undefined) {
                            statisticParsed[stat][year][month] = rev;
                        }
                        statisticParsed[stat][year][month] += rev;
                        statisticParsed[stat][year][month] = parseFloat(statisticParsed[stat][year][month]).toFixed(2)
                    }
                }
            }
            this[stat] = statisticParsed[stat];
            this.parseStatsYearlyRevenue(stat)
        }
    }

    getYearRevenue(objectName, year) {
        if (this[objectName][year]=== undefined) {
            return 0;
        }
        return parseFloat(this[objectName][year].yearly);
    }

    parseStatsYearlyRevenue(objectName) {
        let stat = this[objectName];
        for (let statElement in stat) {
            let countYearRevenue = 0
            for (let yearData in stat[statElement]) {
                countYearRevenue += parseFloat(stat[statElement][yearData]);
            }
            stat[statElement].yearly = parseFloat(countYearRevenue.toString()).toFixed(2);
        }
    }

    parseTotalsAllGroupMonthly(year) {
        let arrayCombined = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let j = 0; j < this.elements.length; j++) {
            let countedRevenue = this.countMonthsRevenue(year, this.elements[j]);
            for (let i = 0; i < 12; i++) {
                if (countedRevenue[i] !== undefined) {

                    arrayCombined[i] = parseFloat(arrayCombined[i] + countedRevenue[i]).toFixed(2);

                }
            }
        }
        return arrayCombined;
    }

    countMonthsRevenue(year, objectName){
        let monthRevenue = [];
        if (this[objectName][year] !== undefined) {
            for (let stat in this[objectName][year]) {
                if (stat.toString() !== 'yearly') {
                    monthRevenue.push(this[objectName][year][stat]);
                }

            }
        }
        return monthRevenue
    }
}