import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    name = "";
    goal = "";
    id = "";
    type = "";
    rayon = "";
    report_layout = "";
    visited = new Date().format();
    report = [];
    reports = [];
    reportsParsed = [];
    activeReport =[];
    report_layout_id = 0;
    actionpoints = [];
  
    fillable = ['report', 'name', 'goal', 'type','report_layout_id', 'visited', 'report_layout', 'rayon'];
    constructor(id = "", id_report = "", route_affix = "") {
        route_affix = '/report' + route_affix;
        if (id !== "") {
            if (id_report !== "") {
                super( "debtor" + "/" + id +route_affix + "/" + id_report)
            }else {
                super( "debtor" + "/" + id +route_affix)
            }
        } else {
            super( "debtor" + route_affix)
        }
    }

    parseReports() {
        this.reports.forEach((report) => {
            if (report.goal === null) {
                report.goal = "Geen opgegeven"
            }
            switch (report.type) {
                case 0:
                    report.type = "Lang rapport"
                    break;
                case 1:
                    report.type = "kort rapport"
            }
            
            let tmpReport = {
                id: report.id,
                visited: report.visited,
                goal: report.goal,
                type: report.type,
                report_layout: report.layout,
                rayon: report.rayon,
                actionpoints: report.actionpoints,
            }
            this.reportsParsed.push(tmpReport);
        });
    }
}