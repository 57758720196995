<template>
    <div>
        <div v-on:click="sidebarEvent">
            <hamburger :open="!openSidebar"></hamburger>
        </div>
        <div v-on:click="sidebarEvent" :class="{'sidebar__shadow': $store.state.helperStore.windowWidth < 1280,'sidebar__shadow-closed' : openSidebar && !closing3s && $store.state.helperStore.windowWidth < 1280, 'sidebar__shadow-closing' : closing3s }">

        </div>
        <div class="sidebar" :class="{'sidebar-closed' : openSidebar}">

            <div class="sidebar__container" :class="{'sidebar__container-closed' : openSidebar}">
                <img src="/img/logo.svg" alt="logo" class="sidebar__logo">

                <div class="container" v-if="$route.matched[0]">
                    <ul class="sidebar__item-container">
                        <language-switcher></language-switcher>
                        <!--          <router-link :to="'/search'" class="sidebar__item" active-class="sidebar__item-active">-->
                        <!--            <li class="sidebar__icon">-->
                        <!--            </li>-->
                        <!--            <li class="sidebar__link">Zoeken</li>-->
                        <!--          </router-link>-->

                    </ul>
                    <ul class="sidebar__item-container">
                        <router-link :to="{name: 'dashboardGeneral', params: {index:1}}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'dashboardGeneral' || $route.matched[0].name === 'dashboard'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-home sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.dashboard') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container">
                        <router-link :to="{name: 'rayonOverview', params: {index:1}}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'rayonOverview' || $route.matched[0].name === 'rayonOverview'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-home sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.rayon') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="$store.state.generalStore.user.checkRoleTo(['Ontwikkelaar'])">
                        <router-link :to="{name: 'productSearch'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'productSearch' || $route.matched[0].name === 'product'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-box-open sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.product') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="$store.state.generalStore.user.checkPermissionTo(['manage users'])">
                        <router-link :to="{name: 'debitSearch'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'debitSearch' || $route.matched[0].name === 'debit'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-user sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.debit') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="$store.state.generalStore.user.checkRoleTo(['Ontwikkelaar'])">
                        <router-link :to="{name: 'creditSearch'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'creditSearch' || $route.matched[0].name === 'credit'}">
                            <li class="sidebar__icon-container">
                                <i class="far fa-user sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.credit') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="$store.state.generalStore.user.checkRoleTo(['Ontwikkelaar'])">
                        <router-link :to="{name: 'managerManage'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'managerManage' || $route.matched[0].name === 'manager'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-user-tie sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.manager') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="$store.state.generalStore.user.checkRoleTo(['Ontwikkelaar'])">
                        <router-link :to="{name: 'orderCreate'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'orderCreate' || $route.matched[0].name === 'order'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-shopping-cart sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.order') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="$store.state.generalStore.user.checkRoleTo(['Ontwikkelaar'])">
                        <router-link :to="{name: 'reversionCreate'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'reversionCreate' || $route.matched[0].name === 'reversion'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-undo sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.return') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container">
                        <router-link :to="{name: 'settings'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'settings'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-wrench sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.management') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container">
                        <router-link :to="{name: 'actionIndex'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'actionIndex'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-exclamation sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.action-point') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container">
                        <router-link :to="{name: 'agendaIndex'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.name === 'agendaIndex'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-calendar-week sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.agenda') }}</li>
                        </router-link>
                    </ul>
                    <ul class="sidebar__item-container" v-if="reportSettings">
                        <router-link :to="{name: 'reportIndex'}" class="sidebar__item"
                                     :class="{'sidebar__item-active' : $route.matched[0].name === 'report'}">
                            <li class="sidebar__icon-container">
                                <i class="fas fa-book sidebar__icon"></i>
                            </li>
                            <li class="sidebar__link">{{ $i18n.t('general.report') }}</li>
                        </router-link>
                    </ul>
                </div>
                <!--            <div class="container">-->
                <!--                <span class="list__separator"></span>-->
                <!--                <div class="mt-3"></div>-->
                <!--                <sidebar-action-point></sidebar-action-point>-->

                <!--            </div>-->
                <div class="sidebar__item-container-bottom">
                    <user-management class="float-right"></user-management>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Hamburger from '@/components/html/Hamburger';
    import UserManagement from '@/components/html/sidebarComponents/UserManagement';
    import LanguageSwitcher from '@/components/html/LanguageSwitcher';

    export default {
        name: 'Sidebar',
        components: { LanguageSwitcher, UserManagement, Hamburger },
        data() {
            return {
                openSidebar: false,
                closing3s: false,
                reportSettings: false
            };
        },
        mounted() {
            this.$store.commit('initSettings');


            if (window.innerWidth < 1280) {
                this.sidebarEvent();
            }
            window.addEventListener('resize', () => {
                this.$store.commit("setWindowWidth", window.innerWidth);
            });
        },
        methods: {
            sidebarEvent() {
                if(!this.openSidebar) {
                    this.closing3s = true;
                    setTimeout(() => {
                        this.closing3s = false;
                    }, 300);
                }
                this.openSidebar = ! this.openSidebar;
                this.$store.commit('toggleSidebar');
            }
        },
        watch: {
            '$store.state.settingsStore.reportSettings': function () {
                if(this.$store.state.settingsStore.reportSettings !== null) {
                    this.reportSettings = this.$store.state.settingsStore.reportSettings.defaultDebit !== null;
                }else {
                    this.reportSettings = false;
                }
            },
            '$store.state.helperStore.windowWidth': function () {
                if (this.openSidebar !== true) {
                    if (window.innerWidth < 1280) {
                        this.sidebarEvent();
                    }
                }else {
                    if (window.innerWidth > 1280) {
                        this.sidebarEvent();
                    }
                }
            },
        },

    };
</script>

<style scoped>

</style>
