<template>
    <breadcrumbs-bar :links="[{name: 'Zoeken', url:'productSearch'},{name: 'Info', url: 'productInfo'}]"></breadcrumbs-bar>
    <div>
        <h2 class="page__title debit__result-small__title">Settings</h2>
    </div>
</template>

<script>
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    export default {
        name: "Settingsheader",
        components: {BreadcrumbsBar}
    }
</script>

<style scoped>

</style>