<template>
    <div class="debit__result__headers col-md">
        <div class="row">
            <div class="col-4">
                <p class="debit__result-small display-inline font-weight-bold">{{ header }}</p>
            </div>
            <div class="col-8">
                <p class="debit__result-small display-inline">{{ text }}</p>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "InformationBarContent",
        props: ['header', 'text']
    }
</script>

<style scoped>

</style>