import Api from '@/classes/helper/Api';

export default class extends Api {
    fillAble = ['name', 'code'];
    name = "";
    code = "";
    constructor() {
        super('reversion');
    }


}