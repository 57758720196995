<template>
    <div>Actionpoints</div>
</template>
<script>
    import DragElements from "@/plugins/DragDrop/classes/DragElements";
   

    export default {
        name: "DragActionPoint",
        components: {},
        props: ['indexCol', 'indexRow', 'index'],
        data() {
            return {
                dragElements: new DragElements(this.indexRow, this.indexCol, this.index),
                label: ''
            }
        },
        methods: {
            deleteLabel() {
                this.dragElements.deleteContent();
            },
            changedValue(e) {
                if (e[0] === "label") {
                    this.label = e[1]
                } else {
                    this.value = e[1];
                }
            },
            setEditable() {
                this.disabled = false;
            },
            removeEditable() {
                if (this.value !== "") {
                    this.disabled = true;
                    this.dragElements.setValueSelect(this.value, this.label);
                }
            }
        }
    }

</script>