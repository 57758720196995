<template>
    <div>
        <div class="d-flex justify-content-space-between">
            <label class="flex-1">{{ label }}</label>
            <p class="display-inline flex-1">{{ value }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DragInfoComponent",
        props: ['label', 'value']
    }
</script>

<style scoped>

</style>