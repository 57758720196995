<template>
    <div>
        <div class="row mt-4" v-if="serviceListed">
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">Afmelden</h2>
                </div>
            </div>
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">Targets voor 2021</h2>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-else>
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">Bandenwinkel Service</h2>
                    <div>
                        <input-component class="mt-4" label="E-mailadres ontvanger bestellingen (verplicht)"></input-component>
                        <input-component class="mt-4" label="Target voor 2021 (verplicht)"></input-component>
                        <input-component class="mt-4" label="Weergegeven bedrijfsnaam bij bandenbestelling (optioneel)"></input-component>
                        <div>
                            <checkbox-component class="mt-4"></checkbox-component>
                            <p class="display-inline ml-1">Debiteur wil alleen service gebruiken.</p>
                        </div>
                        <button class="button button-green-inverse mt-4">Geef toegang</button>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import InputComponent from "@/components/partials/InputComponent";
    import CheckboxComponent from "@/components/partials/checkboxComponent";
    export default {
        name: "DebitTiresIframe",
        components: {CheckboxComponent, InputComponent},
        data() {
            return {
                serviceListed: false,
            }
        }
    }
</script>

<style scoped>

</style>