<template>
    <div class="container">
        <div class="row d-flex justify-content-center" style="margin-top: 25%">
            <form>
                <p class="h4 text-center mb-4">{{ $t('general.login') }}</p>

                <div class="form__group">
                    <label for="wholesaler" class="grey-text">{{ $t('form.wholesaler') }}</label>

                    <select name="" id="wholesaler" class="form-control" v-model="form.wholesaler.value">
                        <option v-for="(wholesaler, key) in wholesalers" :key="key" :value="wholesaler.setting_value" v-text="wholesaler.name"></option>
                    </select>

                    <small class="danger__text" :class="{'danger__text-active': form.wholesaler.danger}">{{ form.wholesaler.danger_text }}</small>
                </div>

                <div class="form__group">
                    <label for="email" class="grey-text">{{ $t('form.email') }}</label>
                    <input id="email" type="email" class="form-control" v-model="form.email.value"/>
                    <small class="danger__text" :class="{'danger__text-active': form.email.danger}">{{ form.email.danger_text }}</small>
                </div>

                <div class="form__group">
                    <label for="password" class="grey-text">{{ $t('form.password') }}</label>
                    <input type="password" id="password" class="form-control" v-model="form.password.value"/>
                    <small class="danger__text" :class="{'danger__text-active': form.password.danger}">{{ form.password.danger_text }}</small>
                </div>
                <div class="text-center mt-4">
                    <button class="btn btn-indigo" type="button" v-on:click="login">{{ $t('general.login') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import User from '@/classes/User';
    import axios from 'axios';
    import Api from '@/classes/helper/Api';
    import store from '@/store';

    export default {
        name: 'Login.vue',
        data() {
            return {
                form: {
                    wholesaler: {
                        value: '',
                        danger: false,
                        danger_text: '',
                        rules: 'required'
                    },
                    email: {
                        value: '',
                        danger: false,
                        danger_text: '',
                        rules: 'required|max:255'
                    },
                    password: {
                        value: '',
                        danger: false,
                        danger_text: '',
                        rules: 'required|max:255'
                    }
                },
                user: new User(),
                wholesalers: []
            };
        },
        methods: {
            login() {
                // this.$http.post();
                this.user.login(this.form);
            }
        },

        async created() {
            const api = new Api;

            const { data } = await axios.get(`${api.apiUrl}/crm/settings`);

            store.commit('setSettings', data);

            this.wholesalers = data.enabled_wholesalers;
        }
    };
</script>