<template>
    <div>
        <div class="dashboard__content" :class="{'dashboard__content-large': sidebar, 'dashboard__content-no-min': sidebarOver}">
            <div class="container-fluid">
                <router-view name="header"></router-view>

                <router-view name="bar"></router-view>

                <alert-bar-component></alert-bar-component>

                <router-view v-slot="slotProps" name="default">
                    <transition name="route" mode="out-in">
                        <component :is="slotProps.Component"></component>
                    </transition>
                </router-view>
            </div>
        </div>
    </div>
</template>

<script>

    import StandardBar from '@/pages/layout/StandardBar';
    import AlertBarComponent from "@/components/partials/AlertBarComponent";
    export default {
        name: 'Standard',
        components: {AlertBarComponent, StandardBar },
        data() {
            return {
                sidebar: false,
                sidebarOver: false,
            }
        },
        mounted() {
            this.sidebar = this.$store.state.helperStore.sidebar;
            this.sidebarOver = window.innerWidth < 1280;
        },
        watch: {
            '$store.state.helperStore.windowWidth': function () {
                this.sidebarOver = window.innerWidth < 1280;
            },
            '$store.state.helperStore.sidebar': function () {
                this.sidebar = this.$store.state.helperStore.sidebar;
            }
        },
    };
</script>

<style scoped>

</style>