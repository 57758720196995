<template>
    <div class="section section__container">
        <div class="table__list">
            <h2 class="ml-2 mt-2 float-left">{{ $t('list.action_points') }}</h2>
            <div class="row">
                <div class="col-md-8">
                </div>
                <div class="col-md-4">
                    <button class="button button-green float-right mt-3 mb-1" v-on:click="openModal">{{ $t('button.add') }}</button>
                </div>
            </div>
            <modal-container :open-modal="openCreation" @closeModalEvent="closeModal" type="small">
                <action-points-create-component></action-points-create-component>
            </modal-container>
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">{{ $t('list.widget.title') }}</th>
                    <th scope="col">{{ $t('list.widget.datum') }}</th>
                    <th scope="col">{{ $t('list.widget.performers') }}</th>
                    <th scope="col">{{ $t('list.widget.start') }}</th>
                    <th scope="col">{{ $t('list.widget.end') }}</th>
                    <th scope="col">{{ $t('list.widget.status') }}</th>
                </tr>
                </thead>
                <tbody v-if="data.length > 0">
                <tr v-for="(order, index) in data" v-bind:key="index">
                    <th scope="col" v-for="(data, index) in order" v-bind:key="index"
                        :class="{'list__money' : type==='money' && index !== 0}">{{ data }}
                    </th>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <th>Er zijn geen {{ $t('list.action_points') }}</th>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import ActionPointsCreateComponent from "@/modules/debtor/components/ActionPointsCreateComponent";
    import ModalContainer from "@/components/html/ModalContainer";
    export default {
        name: "ActionPointsWidget",
        components: {ModalContainer, ActionPointsCreateComponent},
        data() {
            return {
                data: [],
                openCreation: false
            }
        },
        methods: {
            openModal() {
                this.openCreation = true
            },
            closeModal() {
                this.openCreation = false
            }
        }
    }
</script>

<style scoped>

</style>