<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <order-history-bar :values="[0,0,5,3,10,15]"></order-history-bar>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <open-orders></open-orders>
      </div>
      <div class="col-md-6">
        <pie-widget title="Weekomzet in percentage artikelgroepen" :data="[22, 18, 55, 66, 77]" :headers="['DIV', 'AUTOMATERIALEN', 'BANDEN EN WIELEN', 'EQUIPMENT', 'KENTEKENPLATEN']"></pie-widget>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <column-widget :data="[19000, 8000, 12000]" :headers="['OFFLINE', 'MIJNGROSSIER', 'VROOAM SHOP']"></column-widget>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
</template>

<script>
import PieWidget from "@/components/widgets/PieWidget";
import OpenOrders from "@/components/widgets/OpenOrders";
import ColumnWidget from "@/components/widgets/ColumnWidget";
import OrderHistoryBar from "@/components/widgets/OrderHistoryBar";
export default {
  name: "DashboardWeekly",
  components: {OrderHistoryBar, ColumnWidget, OpenOrders, PieWidget}
}
</script>

<style scoped>

</style>
