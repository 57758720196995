<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-8">
        <list title="Open posten" button="Verversen" @buttonEvent="getPosts"></list>
      </div>
      <div class="col-md-4">
        <check-out-card></check-out-card>
      </div>
    </div>
  </div>
</template>

<script>
import CheckOutCard from "@/modules/debtor/components/CheckOutCard";
import List from "@/components/widgets/ListWidget";
export default {
  name: "DebitCheckout",
  components: {List, CheckOutCard},
  data() {
    return {
      paymentType: 0
    }
  },
  mounted() {
    this.getPosts();
  },
  methods: {
    getPosts() {

    }
  }
}
</script>

<style scoped>

</style>
