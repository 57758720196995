<template>
    <div>
        <div class="mb-4">
            <div class="row mt-4">
                <div class="col-12">
                    <div class="section section__container">
                        <h2 class="page__title">Rapportage invoeren</h2>
                        <div class="row" v-if="debtor">
                            <div class="col-md-3">
                                Debiteur:
                            </div>
                            <div class="col-md-3">
                                {{ debtor.debtor_number}}
                            </div>
                            <div class="col-md-6"></div>
                            <div class="col-md-3">
                                Naam:
                            </div>
                            <div class="col-md-3">
                                {{ debtor.name}}
                            </div>
                            <div class="col-md-6"></div>
                        </div>
                        <select-component name="type" @selectedEvent="selectType" label="Type rapportage"  no-trans="true" :data="types"></select-component>

                        <select-component name="layout" @selectedEvent="selectLayout" label="Layout rapportage"  no-trans="true" :data="reportLayout.layoutsNamedList"></select-component>

                        <div class="row mt-3">
                            <div class="col-md-6">
                                <input-component no-trans="true" label="Doel van het bezoek" @changeEvent="goalChanged" ></input-component>
                                <date-picker-component v-model="report.visited" no-trans="true" label="Dag dat je bent langsgeweest" class="mt-4"></date-picker-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeLayout.length > 0 ">
                <div v-if="statistics != ''">
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="section section__container">
                                <debit-report-layout-builder @saveReport="saveReport" :layouts="activeLayout" :rayon="rayon" :statistics="statistics" :debtor="debtor" :location="location"></debit-report-layout-builder>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <loading-spinner-manual :spinner="true"></loading-spinner-manual>
                </div> 
            </div> 
        </div>
    </div>
</template>

<script>
    import Alert from '@/classes/helper/Alert';
    import Debtor from "@/modules/debtor/classes/Debtor";
    import SelectComponent from '@/components/partials/SelectComponent';
    import InputComponent from '@/components/partials/InputComponent';
    import DatePickerComponent from '@/components/partials/DatePickerComponent';
    import DebitReportLayoutBuilder from '@/modules/report/components/DebitReportLayoutBuilder';
    import ReportLayout from '@/modules/report/classes/ReportLayout';
    import Report from '@/modules/report/classes/Report';
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";


    export default {
        name: 'ReportCreate',
        props: ['noRedirect', 'location'],
        components: { DebitReportLayoutBuilder, DatePickerComponent, InputComponent, SelectComponent, LoadingSpinnerManual },
        data() {
            return {
                reportLayout: new ReportLayout(),
                report: new Report(this.$route.params.number),
                activeLayout: [],
                debtorObject: new Debtor(),
                debtor: undefined,
                activeLayoutId: 0,
                types: {'1': 'Bezoek', '2': 'Telefonisch'},
                type: '',
                rayon: '',
                statistics: '',
                date: new Date(),
            }
        },
        created() {
            this.fetchLayouts();
            this.fetchDebtor();
        },
        methods: {
            fetchDebtor(){
                if(this.$store.state.debitStore.activeDebtor != undefined && this.debtor === undefined)
                {
                    this.debtor = this.$store.state.debitStore.activeDebtor;
                }
                else if(this.$store.state.reportStore.activeReportDebtor != undefined && this.debtor === undefined)
                {
                    this.debtor = this.$store.state.debitStore.activeDebtor;

                }
                else if(this.$store.state.settingsStore.activeReportDebtor != undefined && this.debtor === undefined)
                {
                     this.debtor = this.$store.state.settingsStore.activeReportDebtor;
                }
                else if(this.$route && this.$route.params.number && this.debtor === undefined ){
                    this.debtor = this.fetchDebtorData( this.$route.params.number);
                }
                else if(this.$store.state.settingsStore.reportSettings.defaultDebit){
                    this.debtor = this.fetchDebtorData(this.$store.state.settingsStore.reportSettings.defaultDebit);
                }
                
                if(this.debtor != undefined && this.debtor.stats != undefined){
                    this.statistics = this.debtor.statistics
                }
            },
            fetchDebtorData(debtor){
                this.debtorObject.id = debtor
                this.debtorObject.show(this.debtorObject).then((response) => {
                    this.debtor = response.data.debtor;
                    this.$store.commit("setActiveDebtor", response.data.debtor);
                    this.statistics = this.debtor.stats
                });
            },
            selectDate(date) {
                this.report.visited = date[1];
            },
            goalChanged(item) {
                this.report.goal = item[1];
            },
            saveReport(data) {
                this.report.report = JSON.stringify(data.report);
                
                if(this.debtor != undefined && this.debtor != ''){
                    this.report.rayon = this.debtor.rayon;
                }
                
                if(data.report){
                     this.report.route_prefix = 'debtor/'+data.debtor+'/report';
                }
                else {
                      this.report.route_prefix = 'debtor/000000/report';
                }
                this.report.store(this.report).then(() => {
                    if(!this.$props.noRedirect){
                       this.$router.push({name: 'reportIndex'});
                    }
                    
                    if(data.debtor){
                        this.$router.push({name: "debitReport", params: {'number': data.debtor}})
                    }

                    Alert.createAlert('success', 'Rapport opgeslagen')
                });
            },
            selectLayout(name) {
                this.reportLayout.layouts.forEach((layout) => {
                    if (layout.name === name[1]) {
                        this.activeLayout = layout.layout;
                        this.report.report_layout_id = layout.id;
                        this.report.report_layout = JSON.stringify(layout.layout);
                    }
                });
            },
            selectType(type) {
                this.report.type = type[1];
            },
            fetchLayouts() {
                this.reportLayout.all().then((response) => {
                    if(this.$store.state.reportStore.activeReportDebtor) {
                        //this.debtor = this.$store.state.reportStore.activeReportDebtor;
                        this.reportLayout.layouts = response.data;
                       
                        //this.$store.commit('setActiveReportDebtor', undefined);
                    }else {
                        response.data.forEach((layout) => {
                            let usingStats = false;
                            //let lay = JSON.parse(layout.layout);
                            //lay.forEach((row) => {
                                //row.layout.forEach((col) => {
                                    //col.layout.forEach((element) => {
                                        //if (element.component === 'info-stats' || element.component === 'info-debit') {
                                          //  usingStats = true;
                                       // }
                                    //});
                               // })
                           // });
                            if (!usingStats) {
                                this.reportLayout.layouts.push(layout);
                            }
                        });
                    }
                    this.reportLayout.layoutsParsed();
                });
            },
        }
    };
</script>

<style scoped>

</style>