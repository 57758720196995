<template>
    <div class="search__result__button-shortcut-container ml-3" v-on:mouseenter="showTooltip = true"
         v-on:mouseleave="showTooltip = false">
        <p class="search__result__button-tooltip" :class="{'search__result__button-tooltip-active' : showTooltip || (active && selected === number)}">{{
                tooltip
            }}</p>
        <span class="search__result__button-shortcut" :class="{'search__result__button-shortcut-show': active}">{{ shortcut }}</span>
        <router-link :to="{name: link, params: {product: product.article_number}}" class="search__result__button-container"
                     :class="{'search__result__button-container-active': active && selected === number}">{{ code }}
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "ProductButton",
        props: ['product', 'shortcut', 'tooltip', 'code', 'link', 'active', 'selected', 'number'],
        data() {
            return {
                showTooltip: false
            }
        },
        methods: {},
        watch: {
            '$store.state.hotkeyStore.keyEnter': function(val) {
                if (val && this.active && this.selected === this.number) {
                    this.$router.push({name: this.link, params: {product: this.product.articlenumber}});
                }
            },
        }
    }
</script>

<style scoped>

</style>
