import store from '@/store';

export default class {
    indexRow;
    indexCol;
    index;
    constructor(indexRow, indexCol, index) {
        this.indexRow = indexRow;
        this.indexCol = indexCol;
        this.index = index;
    }

    deleteContent() {
        store.commit('deleteContent', [this.indexRow, this.indexCol]);
    }
    setValueSelect(value, label, file_id) {
        store.commit('setSelectContent', [this.indexRow, this.indexCol, this.index, value, label, file_id]);
    }
    setValue(value) {
        store.commit('setLabelContent', [this.indexRow, this.indexCol, this.index, value]);
    }
}