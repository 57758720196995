<template>
    <div>
        <div :class="{'hidden-info' : (content.value !== '' && !disabled)}">
            <div v-if="content.value" class="bordered">
                <div class="d-flex justify-content-space-between search__form" :class="{'disabled':disabled}">
                    <label class="flex-1">{{ content.label }}</label>
                    <p class="display-inline flex-1">{{ debtor[content.value] }}</p>
                </div>
                <div class="display-inline edit__buttons">
                    <i class="fas fa-edit icon__show" :class="{'icon__hidden':!disabled}"
                       v-on:click="setEditable"></i>
                    <i class="fas fa-trash ml-4 icon__show color-danger" :class="{'icon__hidden':!disabled}"
                       v-on:click="deleteLabel"></i>
                </div>
            </div>
        </div>
        <div class="row" :class="{'hidden-info' : (content.value !== '' && disabled)}">
            <div class="col-md-6">
                <input-component label="Label toevoegen" v-model="label" name="label" @changeEvent="changedValue"
                                no-trans="true" :initialValue="content.label"></input-component>
                <input-component label="file_id" v-model="file_id" name="file_id" @changeEvent="changedValue"  :initialValue="content.file_id" no-trans="true"></input-component>
                <input-component label="name" v-model="value" name="value" @changeEvent="changedValue"  :initialValue="content.value" no-trans="true"></input-component>       

            </div>
            <div class="col-md-6">
                <div class="button_end">
                    <i class="fas fa-save icon__show" :class="{'icon__hidden':disabled}"
                       v-on:click="removeEditable"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import InputComponent from "@/components/partials/InputComponent";    
    import DragElements from "@/plugins/DragDrop/classes/DragElements";
    import i18n from '@/i18n';

    export default {
        name: "DragOptSelection",
        components: {InputComponent},
        props: ['content', 'indexCol', 'indexRow', 'index'],
        data() {
            return {
                disabled: false,
                dragElements: new DragElements(this.indexRow, this.indexCol, this.index),
                value: "",
                label: "",
                file_id: "",
                debtor: this.$store.state.debitStore.activeDebtor,
                debtorDataParsed: [],
                date: new Date(),
                debtorDateExceptions: ['actionPoints', 'route', 'exactid', 'groep', 'educations', 'tech_specialist', 'specialisms','specialisms', 'sub-concepts'],
            }
        },
        created() {
            if (this.content.value !== "") {
                this.value = this.content.value;
                this.disabled = true;

            }
           
            for (let debt in this.debtor) {
                if(!this.debtorDateExceptions.includes(debt)){
                  //  [{ key: value}]
                    let fieldTrans = i18n.t("debtor." + debt);
                    let fieldData = { label: fieldTrans , value: debt};
                    this.debtorDataParsed.push(fieldData);
                }
            }
        },
        methods: {
            deleteLabel() {
                this.dragElements.deleteContent();
            },
            changedValue(e) {
                if (e[0] === "label") {
                    this.label = e[1]
                } else if(e[0] === "file_id"){
                    this.file_id = e[1];
                }
                else {
                    this.value = e[1];
                }
            },
            setEditable() {
                this.disabled = false;
            },
            removeEditable() {
                if (this.value !== "") {
                    this.disabled = true;
                    this.dragElements.setValueSelect(this.value, this.label, this.file_id);
                }
            },
        }
    }
</script>

<style scoped>
    .disabled {
        background-color: lightgray;
    }
</style>