import i18n from '@/i18n';
//import SettingsCreateDebit from '@/modules/settings/pages/SettingsCreateDebit';
import Standard from '@/pages/layout/Standard';
import StandardBar from '@/pages/layout/StandardBar';
import SettingsHeader from '@/modules/settings/components/SettingsHeader';
import Settings from '@/modules/settings/pages/Settings';
//import SettingsUserLog from '@/modules/settings/pages/SettingsUserLog';

export default [
    {
        component: Standard,
        meta: {
            routes: "settings/settingsRoutes",
        },
        path: "/settings",
        name: "settingsLayout",
        children: [
            {
                path: "",
                components: {
                    header: SettingsHeader,
                    bar: StandardBar,
                    default: Settings
                },
                name: "settings",
                label: i18n.t("debtor.settings")
            },
            //{
            //    path: "/settings/create/debit",
            //   components: {
            //        header: SettingsHeader,
            //        bar: StandardBar,
            //        default: SettingsCreateDebit
            //    },
            //    name: "settingsDebit",
            //    label: i18n.t("debtor.add")
            //},
            //{
            //    path: "/settings/user/log",
            //    components: {
            //        header: SettingsHeader,
            //        bar: StandardBar,
            //        default: SettingsUserLog
            //    },
            //    name: "SettingsUserLog",
            //    label: i18n.t("general.user_log")
            //}
        ]
    }
]