export default class {
    errors = false;
    form = {}

    constructor() {
    }

    init(form) {
        this.form = form;
        for (let field in form) {
            form[field].danger = false;
            form[field].value = "";
            form[field].danger_text = "";
        }

    }

    editValue(field) {
        this.form[field[0]].value = field[1];
    }

    validate(form = null) {
        if (form == null) {
            form = this.form;
        }
        for (let field in form) {
            form[field].danger = false;
            form[field].danger_text = "";
            this.errors = false;
            if (form[field].rules === undefined) {
                return;
            }
            let rules = form[field].rules.split("|");
            for (let rule of rules) {
                let rule_splitted = rule.split(":");

                switch (rule_splitted[0]) {
                    case "required":
                        if (this.required(form[field].value)) {
                            let errorMessage = field + " Is een verplicht veld.";
                            this.errors = true;
                            form[field].danger = true;
                            form[field].danger_text = errorMessage;
                        }
                        break;
                    case "max":
                        if (this.max(form[field].value, rule_splitted[1])) {
                            let errorMessage = field + " Is langer dan het toegestaande " + rule_splitted[1] + " tekens.";
                            this.errors = true;
                            form[field].danger = true;
                            form[field].danger_text = errorMessage;
                        }
                        break;
                }
            }
        }
        return form;
    }

    max(value, max) {
        return value.length > max;
    }

    required(value) {
        return value === undefined || value.length <= 0;

    }

    parseForm(form) {
        let tmpForm = new FormData();
        for (let field in form) {
            tmpForm.append(field, form[field].value);
        }
        return tmpForm;
    }

    parseErrors(form, errors) {
        for (let err in errors) {
            form[err].danger = true;
            form[err].danger_text = errors[err][0];
        }
    }
}
