const reportStore = {
    state: {
        activeReportDebtor: undefined,
        debtorstats: undefined,

    },
    mutations: {
        setActiveReportDebtor(state, value){
            state.activeReportDebtor = value;
        },
        setDebtorStats(state, value){
            state.debtorstats = value;
        },
    },
    actions: {},
    getters: {}
};

export default reportStore;
