export default class {
    useLocalSave = false;

    constructor() {
        if (this.useLocalSave) {
            this.getData();
        }
    }

    getData() {

    }
}