<template>
    <div class="debit__result__button-shortcut-container ml-3" v-on:mouseenter="showTooltip = true"
         v-on:mouseleave="showTooltip = false">
        <p class="debit__result__button-tooltip"
           :class="{'debit__result__button-tooltip-active' : showTooltip || (active && selected === number)}">{{
                tooltip
            }}</p>
        <span class="debit__result__button-shortcut"
              :class="{'debit__result__button-shortcut-show': active}">{{ shortcut }}</span>
        <router-link :to="{name: link, params: params}" class="debit__result__button-container"
                     :class="{'debit__result__button-container-active': active && selected === number}">{{ code }}
        </router-link>
    </div>
</template>

<script>
    import Hotkey from "@/classes/helper/Hotkey";

    export default {
        name: "InformationBarShortcut",
        props: ['shortcut', 'tooltip', 'code', 'link', 'active', 'selected', 'number', 'id', 'params'],
        data() {
            return {
                showTooltip: false,
                hotkey: new Hotkey([this.shortcut])
            }
        },
        created() {
        },
        watch: {
            '$store.state.hotkeyStore.keyPressed': function () {
                if (this.$store.state.hotkeyStore.keyPressed) {
                    if (this.$store.state.hotkeyStore["key" + this.shortcut]) {
                        this.$router.push({name: this.link, params: this.params})
                    }
                }
            }
        }

    }
</script>

<style scoped>

</style>