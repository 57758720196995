<template>
    <teleport to="#modal">
        <div class="modal-custom" :class="{'modal-custom-open': open}">
            <div class="modal-custom__background" v-on:click="closeModal"></div>
            <div class="modal-custom__container" :class="'modal-custom__container-' + type">
                <slot></slot>
            </div>

        </div>
    </teleport>

</template>

<script>
    export default {
        name: "ModalContainer",
        props: ['openModal', 'type'],
        emits: ['closeModalEvent'],
        data() {
            return {
                open: this.openModal,
            }
        },
        created() {
        },
        methods: {
            closeModal(){
                this.$emit('closeModalEvent')
            }
        },
        watch: {
            openModal(val) {
                this.open = val;
            }
        }
    }
</script>

<style scoped>

</style>