<template>

    <div class="display-inline">
            <span type="checkbox" class="form__input-checkbox" :id="customId"
                  v-on:click="emitCheckboxEvent">
                <i class="fas fa-check form__icon-checkbox" :class="{'form__icon-checkbox-active': modelValue}"></i>
            </span>
        <p class="display-inline ml-2">{{label}}</p>
    </div>

</template>

<script>
    export default {
        name: "checkboxComponent",
        props: ['label', 'name', 'modelValue' , 'customId'],
        emit: ['emitCheckboxEvent', 'update:modelValue'],
        data() {
            return {
            }
        },
        methods: {
            emitCheckboxEvent() {
                if (this.name) {
                    this.$emit('emitCheckboxEvent', [this.name, !this.modelValue]);
                }else {
                    this.$emit('emitCheckboxEvent', !this.modelValue);
                }
                this.$emit('update:modelValue', !this.modelValue);
            }
        }

    }
</script>

<style scoped>

</style>