<template>
    <div id="chart">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        name: "LineStatsComponent",
        props: ['data', 'headers', 'title', 'categories', 'markers'],
        data () {
            return {
                series: this.data,
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#77B6EA', '#545454','#3A47EF','#FB5553','#3AA2EF','#3AEF79', '#EFDF3A'],
                    dataLabels: {
                        enabled: this.markers,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: '.',
                        align: 'right'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1
                    },
                    xaxis: {
                        categories: this.categories,
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    }
                },
            }
        }
    }
</script>

<style scoped>

</style>