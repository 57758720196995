<template>
    <div>
        <div v-for="(row, indexRow) in layout" v-bind:key="indexRow" class="row mt-4">
            <div v-for="(col, index) in row" v-bind:key="index" :class="'col-xl-' + col.width">
                <div  v-if="col.type === 'placeholder'" >
                    <div class="placeholder text-center mt-4" v-on:click="addColumnType(indexRow, index)">
                        <h2 style="position: relative; top: 40%">Voeg item toe</h2>
                    </div>
                    <i class="fas fa-plus-circle select-row-minus placeholder-minus"
                       v-on:click="removeColumnPlaceholder(indexRow, index)"></i>
                </div>
                <div v-else-if="col.type === 'pie' && col.settings">
                    <pie-widget :data="col" :settings="col.settings"></pie-widget>
                </div>
                <div v-else-if="col.type === 'column' && col.settings">
                    <column-widget :data="col" :settings="col.settings"></column-widget>
                </div>
                <div v-else>
                    <widget-settings-placeholder :row="indexRow" :col="index" @settingsSaved="saveSettings" :widget="col"></widget-settings-placeholder>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import WidgetSettingsPlaceholder from "@/modules/dashboard/components/WidgetSettingsPlaceholder";
    import PieWidget from '@/components/widgets/PieWidget';
    import ColumnWidget from '@/components/widgets/ColumnWidget';
    export default {
        name: "DashboardCreateBuilder",
        props: ['layout'],
        emit: ['removeColumn', 'columnClicked', 'saveSettings'],
        components: { ColumnWidget, PieWidget, WidgetSettingsPlaceholder},
        data() {
            return {
            }
        },
        methods: {
            saveSettings(settings) {
                this.$emit('saveSettings', settings);
            },
            addColumnType(rowIndex, colIndex) {
                this.$emit('columnClicked', [rowIndex, colIndex]);

            },
            removeColumnPlaceholder(rowIndex, colIndex){
                this.$emit('removeColumn', [rowIndex, colIndex]);
            }
        }

    }
</script>

<style scoped>
.placeholder-row {
    position: relative;
}

.placeholder-minus {
    position: absolute;
    right: 20px;
    top: 30px;
    font-size: 28px;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    transform: rotate(45deg);
}

.placeholder-plus {
    position: absolute;
    right: 2px;
    top: 110px;
    font-size: 28px;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
}

.placeholder {
    position: relative;
    height: 200px;
    width: 100%;
    display: inline-block;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
</style>