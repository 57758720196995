<template>
    <div v-if="data.length > 0" class="section section__container">
        <div style="max-height: 400px; overflow-x: hidden">
            <table-component :data="usedChances" style="background-color: white;">
                <template v-slot:title>
                    <h2 class="page__title">{{ label }}</h2>
                </template>
                <template v-slot:button>
                    <input-component label="general.search" class="mt-3" style="float: right" @update:modelValue="filterSearch"></input-component>
                </template>
                <template v-slot:header>
                    <th>{{ $i18n.t('general.subject') }}</th>
                    <th>{{ $i18n.t('general.company_id') }}</th>
                    <th>{{ $i18n.t('general.description') }}</th>
                    <th>{{ $i18n.t('general.controls') }}</th>
                </template>
                <template v-slot:data>
                    <tbody v-if="data && data.length > 0">
                        <tr v-for="(chance) in usedChances" v-bind:key="chance">
                            <td>{{ chance.subject }}</td>
                            <td>{{ chance.debtor_data.number }}</td>
                            <td>{{ chance.description }}</td>
                            <td>
                                <div class="text-center color-main-green color-main-green-hover pointer"
                                    v-on:click="openChance(chance)">
                                    <i class="fas fa-eye font-large"></i>
                                    <span class="button-small-line color-main-green color-main-green-hover"></span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:empty>
                    <p>Er zijn geen {{ label }}</p>
                </template>
            </table-component>
        </div>
    </div>
</template>
<script>
    import TableComponent from '@/components/partials/TableComponent';
    import InputComponent from "@/components/partials/InputComponent";

    export default {
        name: 'ChancesComponent',
        props: ['data', 'label'],
        components: {InputComponent, TableComponent},
        data() {
            return {
                usedChances: []
            }
        },
        methods: {
            filterSearch(search) {
                this.usedChances = this.data.filter((item) => {
                    if (item.company_id === null) {
                        item.company_id = "";
                    }
                   
                    return item.subject.toLowerCase().includes(search.toLowerCase()) || item.company_id.toString().toLowerCase().includes(search.toLowerCase()) || item.description.toString().toLowerCase().includes(search.toLowerCase());
                });
            },
            openChance(chance) {
                this.$router.push({name: "debitInfo", params: {'number': chance.debtor_data.number}})
            }
        },
        watch: {
            data(val) {
                this.usedChances = val;
            }
        }
    };
</script>

<style scoped>

</style>