<template>
    <div class="dashboard-create__bar-icon__container" :class="{'dashboard-create__bar-icon__container-selected': widget.selected}">
        <i class="dashboard-create__bar-icon" :class="widget.icon" v-on:click="selectWidget"></i>
        <div class="dashboard-create__bar-icon__sub-options" :class="{'dashboard-create__bar-icon__sub-options-open': widget.selected}">
            <div class="dashboard-create__bar-icon__sub__container" :class="{'dashboard-create__bar-icon__sub__container-open': widget.selected}">
                <div v-for="(option, index) in widget.options" v-bind:key="option">
                    <i class="dashboard-create__bar-icon__sub" v-on:click="selectOption(index)" :class="{'dashboard-create__bar-icon__sub-selected': option.selected}">{{ option.name }}</i>
                    <span class="list__separator" v-if="index < widget.options.length-1"></span>
                    <span v-else style="height: 1px; width: 100%; display: inline-block"></span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardSelectWidget",
        props: ['widget', 'index'],
        emits: ['selectWidget', 'selectOption'],
        data() {
            return {

            }
        },
        methods: {
            selectWidget() {
                this.$emit('selectWidget', this.index);
            },
            selectOption(optionIndex) {
                this.$emit('selectOption', [this.index, optionIndex]);
            }
        }
    }
</script>

<style scoped>

</style>