const helperStore = {
    state: {
        spinner: false,
        sidebar: false,
        validateForms: false,
        validator: [],
        windowWidth: window.innerWidth
    },
    mutations: {
        setWindowWidth(state, width) {
            state.windowWidth = width;
        },
        toggleSpinner(state, value= undefined) {
            if (value !== undefined) {
                state.spinner = value;
            }else {
                state.spinner = !state.spinner;
            }
        },
        toggleSidebar(state) {
            state.sidebar = !state.sidebar;
        },
        validateForms(state) {
            state.validateForms = true;
            setTimeout(() => {
                state.validateForms = false;
            }, 50);
        },
        addValidatorForms(state, forms) {
            state.validator = forms;
        }
    },
    actions: {},
    getters: {}
};

export default helperStore;
