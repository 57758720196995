import Standard from '@/pages/layout/Standard';
import RayonOverview from '@/modules/rayon/pages/RayonOverview';
import RayonHeader from '@/modules/rayon/components/layout/header/RayonHeader';

export default [
    {
        component: Standard,
        name: "rayon",
        meta: {
            routes: "/rayon",
        },
        children: [
            {
                path: '/rayon',
                components: {
                    header: RayonHeader,
                    default: RayonOverview,
                },
                name: 'rayonOverview',
                label: "Rayon"
            },
        ]

    },
]