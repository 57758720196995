<template>
    <div class="row mb-4 mt-4 page__contents">
        <div class="col-md-12">
            <div class="section_bar">
                <div class="section__container" v-if="info">
                    <div class="list m5">
                        <h2 class="list__title">
                            Gegevens
                        </h2>
                        <credit-details :data="info"></credit-details>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CreditDetails from "@/modules/creditor/components/CreditDetails";

    export default {
        name: "CreditInfo",
        components: {CreditDetails},
        data() {
            return {
                info: this.$store.state.creditStore.activeCreditor
            }
        },
        watch: {
            '$store.state.creditStore.activeCreditor': function() {
                this.info = this.$store.state.creditStore.activeCreditor
            },
        }
    }
</script>

<style scoped>

</style>