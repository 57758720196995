<template>
    <div v-if="actionPoint">
        <div class="row d-flex justify-content-between h-100">
            <div class="col-md-12">
                <div class="section section__container sticky">
                    <div class="row">
                        <div class="col-md-6">
                            <h2 class="page__title">Actiepunt</h2>
                        </div>
                        <div class="col-md-6 text-right font-large color-main-green mt-4">
                            <div class="display-inline mr-2 color-main-green-hover pointer" style="transition: color 0.2s ease-in-out" v-if="actionPoint.watch === true">
                                <i class="fas fa-eye-slash font-large" id="unWatchAction" v-on:click="unWatch"></i>
                            </div>

                            <div class="display-inline mr-2 color-main-green-hover pointer" style="transition: color 0.2s ease-in-out" v-if="actionPoint.watch === false">
                                <i class="fas fa-eye " id="WatchAction" v-on:click="addWatch"></i>
                            </div>

                            {{ $store.state.generalStore.user.email}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <p class="font-weight-bold">Eigenaar:</p>
                            <p>{{actionPoint.owner.first_name}} {{actionPoint.owner.last_name}}</p>

                        </div>
                        <div class="col-md-4">
                            <p class="font-weight-bold">Toegewezen:</p>
                            <p>{{actionPoint.user.first_name}} {{actionPoint.user.last_name}}</p>

                        </div>
                        <div class="col-md-12">
                            <p class="font-weight-bold">Onderwerp:</p>
                            <p>{{actionPoint.subject}}</p>

                        </div>
                        <div class="col-md-12">
                            <p class="font-weight-bold">Beschrijving:</p>
                            <p style="word-wrap: break-word">{{actionPoint.description}}</p>
                        </div>
                    </div>
                    <h2 class="page__title">Comments</h2>
                    <div v-if="actionPoint.comments.length > 0">
                        <div class="row" v-for="comment in actionPoint.comments" v-bind:key="comment">
                            <div class="col-md-12 mt-2">
                                <action-info-show-comment :comment="comment"></action-info-show-comment>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p>Er zijn nog geen comments</p>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xl-10">
                            <input-component id="commentInput" no-trans="true" label="Comment" type="area" v-model="comment.comment">

                            </input-component>
                        </div>
                        <div class="col-xl-2">
                            <button class="button button-small button-green-inverse" v-on:click="addComment">Versturen</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputComponent from '@/components/partials/InputComponent';
    import Comment from '@/modules/action-point/classes/Comment';
    import ActionInfoShowComment from '@/modules/action-point/components/ActionInfoShowComment';
    import ActionPoint from '@/modules/action-point/classes/ActionPoint';
    export default {
        name: 'ActionInfoShow',
        components: { ActionInfoShowComment, InputComponent },
        props: ['actionPoint'],
        emits: ['newComment', 'removedWatcher', 'addWatcher'],
        data() {
            return {
                comment: new Comment(),
                comments: [],
                date: new Date()
            }
        },
        methods: {
            addWatch() {
                let action = new ActionPoint();
                action.post("/"+ this.actionPoint.id + "/watch").then((response) => {
                    this.comment.alert.createAlert('success', "Toezichthouder toegevoegd");
                    this.$emit('addWatcher', response.data);
                });
            },
            unWatch() {
                let action = new ActionPoint();
                action.delete(this.actionPoint,  "/unwatch").then((response) => {
                    this.comment.alert.createAlert('success', "Toezichthouder verwijderd");
                    this.$emit('removedWatcher', response.data);
                });
            },
            addComment() {
                this.comment.store(this.comment, "action-point/" + this.actionPoint.id + "/comment").then((response) => {
                    this.comment.alert.createAlert('success', "Comment toegevoegd");
                    this.comment.comment = "";
                    this.$emit('newComment', response.data);
                });
            }
        },
        watch: {
            actionPoint(val) {
                if (val) {
                    this.comments = val.comments;
                }
            }
        }
    };
</script>

<style scoped>
    .sticky {
        width:auto; 
        position: sticky;
        top:10px;
    }
</style>