import Filter from '@/classes/helper/Filter';

export default class extends Filter{
		filterOption = 5;

		/*
		* 1 = Number
		* 2 = Name
		* 3 = back to original list
		*/
		filterOptions(option) {
			this.filterOption = option;
			switch (option) {
				case 1:
					this.arrayFilter.sort(function (a, b) {
					return a.number - b.number;
				});
				break;
				case 2:
					this.arrayFilter.sort(function (a, b) {
					let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}
							return 0;
					});
				break;
				case 3:
					this.arrayFilter = this.arrayUnfilteredFilter;
				break;
				case 4:
					this.arrayFilter.sort(function (a, b) {
						return a.revenue - b.revenue;
					});
				break;
				case 5:
					this.arrayFilter.sort(function (a, b) {
					let cityA = a.city.toLowerCase(), cityB = b.city.toLowerCase();
					if (cityA < cityB) {
					return -1;
					}
					if (cityA > cityB) {
					return 1;
					}
					return 0;
				});
			break;

			}
		}
}
