const dragDropStore = {
    state: {
        layout: [],
        isDragging: false,
        itemDragging: {},
        stats: {}
    },
    mutations: {
        setStats(state, item) {
            state.stats = item;
        },
        setDragLayout(state, layout) {
            state.layout = layout;
        },
        dragAddLayout(state, item) {
            state.layout.push(item);
        },
        removeLayout(state, index) {
            state.layout.splice(index, 1);

        },
        deleteContent(state, item) {
            state.layout[item[0]].layout[item[1]].layout = state.layout[item[0]].layout[item[1]].layout[item[1]] = undefined;
        },
        dragAddContent(state, item) {
            if (state.layout[item[0]].layout[item[1]].layout === undefined) {
                state.layout[item[0]].layout[item[1]].layout = [];
            }
            state.layout[item[0]].layout[item[1]].layout.push(item[2])
        },
        setSelectContent(state, item) {
            state.layout[item[0]].layout[item[1]].layout[item[2]].value = item[3];
            state.layout[item[0]].layout[item[1]].layout[item[2]].label = item[4];
            if(item[5]){
                state.layout[item[0]].layout[item[1]].layout[item[2]].file_id = item[5];
            }
        },
        setLabelContent(state, item) {
            state.layout[item[0]].layout[item[1]].layout[item[2]].value = item[3];
        },
        dragSetDragging(state, value) {
            state.isDragging = value;
        },
        dragSetItemDragging(state, value) {
            state.itemDragging = value;
        },
        resetLayout(state) {
            state.layout = [];
        }
    },
    actions: {},
    getters: {}
};

export default dragDropStore;
