<template>
    <div class="datepicker__container">
        <label  class="search__form__label search__form__label-active">{{ $t(label) }}</label>
        <vue-timepicker mode="time" format="HH:mm" input-width="100%" :minute-interval="10" v-model="value" style="width: 100%;"></vue-timepicker>
    </div>
</template>

<script>

    import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
    import 'vue3-timepicker/dist/VueTimepicker.css'
    export default {
        name: "TimePickerComponent",
        props:['modelValue', 'label'],
        emits: ['update:modelValue'],
        components: {
            VueTimepicker
        },
        data() {
            return {
                value: ""
            }
        }

    }
</script>

<style scoped>
.input.vue__time-picker-input {
    border: none;
}
.vue__time-picker {
    border: 0.5px solid rgba(51, 51, 51, 0.5) !important;
    border-radius: 3px;
    z-index: 6;
}
</style>