<template>
    <div>
        <breadcrumbs-bar></breadcrumbs-bar>
        <div>
            <div>
                <h2 class="page__title">{{ $i18n.t('general.reports') }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbsBar from '@/components/html/BreadcrumbsBar';
    export default {
        name: 'ReportHeader',
        components: { BreadcrumbsBar }
    };
</script>

<style scoped>

</style>