<template >
    <table class="table">
        <thead>
        <tr>
            <th scope="col">Regel</th>
            <th scope="col">Artikel</th>
            <th scope="col">Omschrijving</th>
            <th class="text-right" v-if="extended">Brutoprijs</th>
            <th class="text-right" v-if="extended">Korting</th>
            <th class="text-right" v-if="extended">Nettoprijs</th>
            <th class="text-right" scope="col">Aantal</th>
            <th class="text-right" v-if="extended">Totaal</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(packing, index) in data" v-bind:key="packing">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ packing.factory_code }}</td>
            <td>{{ packing.description }}</td>
            <td class="text-right" v-if="extended">€ {{ Math.round((packing.gross * 100)) / 100 }}</td>
            <td class="text-right" v-if="extended">€ {{ Math.round((packing.discount * 100)) / 100 }} %</td>
            <td class="text-right" v-if="extended">€ {{ Math.round((packing.net * 100)) / 100 }}</td>
            <td class="text-right">{{ packing.amount }}</td>
            <td class="text-right">€ {{ Math.round((packing.amount * packing.net) * 100) / 100 }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "OrderPacking",
        props: ['data', 'extended'],
    }
</script>

<style scoped>

</style>