<template>
  <div class="search__result__button-shortcut-container ml-3" v-on:mouseenter="showTooltip = true"
       v-on:mouseleave="showTooltip = false"  v-on:click="setActiveDebot">
    <p class="search__result__button-tooltip" :class="{'search__result__button-tooltip-active' : showTooltip || (active && selected === number)}">{{
        tooltip
      }}</p>
    <span class="search__result__button-shortcut" :class="{'search__result__button-shortcut-show': active}">{{ shortcut }}</span>
    <router-link :to="{name: link, params: {number: debtor.debtor_number}}" class="search__result__button-container"
                 :class="{'search__result__button-container-active': active && selected === number}">{{ code }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DebitButton",
  props: ['debtor', 'shortcut', 'tooltip', 'code', 'link', 'active', 'selected', 'number'],
  data() {
    return {
      showTooltip: false
    }
  },
  methods: {
    setActiveDebot(){
      this.$store.commit('setActiveReportDebtor', this.debtor); 
    }
  }
}
</script>

<style scoped>

</style>
