<template>
    <div class="user-icon" v-on:click="openUser = !openUser">
        <button class="button button-icon"><i class="fas fa-user-tie"></i></button>
        <div class="user__container section section__container" :class="{'user__container-show' : openUser}">
            <button class="button button-full button-green-inverse" v-on:click="logout">{{ $i18n.t('general.logout') }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserManagement",
        data() {
            return {
                openUser: false
            }
        },
        methods: {
            logout() {
                localStorage.clear();
                this.$router.push({name: 'login'})
            }
        }
    }
</script>

<style scoped>
.user-icon {
    position: relative;
}

.user__container {
    opacity: 0;
    position: absolute;
    left: 50px;
    top: -40px;
    width: 150px;
    transition: opacity 0.2s ease-in-out;
    display: none;
}

.user__container-show {
    opacity: 1;
    display: inline;

}
</style>