<template>
    <div class="dashboard_bar">
        <div class="dashboard_bar__container">
            <div v-for="link in routes" v-bind:key="link.path" class="display-inline">
                <router-link class="dashboard_bar__link" :to="{name: link.name}" v-if="link.type === 'link'" v-on:click="link.meta.behavior()">
                    {{ link.label }}
                    <span class="dashboard_bar__link-underbar"
                          :class="{'dashboard_bar__link-underbar-active' : $route.name === link.name}"></span>
                </router-link>
                <router-link class="dashboard_bar__link" :to="{name: link.name}" v-else>
                    {{ link.label }}
                    <span class="dashboard_bar__link-underbar"
                          :class="{'dashboard_bar__link-underbar-active' : $route.name === link.name}"></span>
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'StandardBar',
        data() {
            return {
                routes: []
            }
        },
        created() {
            if (this.$route.meta.routes) {
                this.routes = require('../../modules/' + this.$route.meta.routes).default[0].children;
            }
        },
        watch: {
            '$route': function() {
                if (this.$route.meta.routes) {
                    this.routes = require('../../modules/' + this.$route.meta.routes).default[0].children;
                }
            }
        }

    };
</script>

<style scoped>

</style>