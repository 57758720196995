<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <input-component v-model="rapport.defaultDebit" :initial-value="rapport.defaultDebit" label="debtor.default"></input-component>
            </div>
            <div class="col-md-6">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <span class="list__separator"></span>
                <button class="button button-green-inverse " style="float: right" v-on:click="saveSettings">{{ $i18n.t('general.save') }}</button>
            </div>
        </div>
    </div>

</template>

<script>
    import InputComponent from "@/components/partials/InputComponent";
    import Alert from "@/classes/helper/Alert";

    export default {
        name: "ReportSettings",
        components: {InputComponent},
        data() {
            return {
                rapport: {
                    defaultDebit: ""
                }
            }
        },
        created() {
            if (localStorage.getItem('rapport') !== null) {
                let rapport = JSON.parse(localStorage.getItem('rapport'));
                for (let rapportKey in rapport) {
                    this.rapport[rapportKey] = rapport[rapportKey];
                }
            }
        },
        methods: {
            saveSettings() {
                this.$store.commit('saveReportSettings', this.rapport)
                Alert.createAlert('success', "Instellingen opgeslagen")
            }
        }
    }
</script>

<style scoped>

</style>