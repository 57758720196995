<template>
    <div v-if="data">
        <div class="row px-1 m0">
            <h2 class="list__title">{{$i18n.t('general.rayon_info')}}</h2>
        </div>
        <span class="list__separator"/>
        <div class="row m0 pt-2">
            <label class="col-md-2 m0">{{$i18n.t('general.name')}}</label>
            <p class="col-md-10 my-0">{{data.person.first_name}} {{data.person.last_name}}</p>
        </div>
        <span class="list__separator"/>
        <div class="row m0 pt-2">
            <label class="col-md-2">{{$i18n.t('general.email')}}</label>
            <p class="col-md-10">{{data.email}}</p>    
        </div>
    </div>
</template>

<script>
    export default {
        name: "RayonManagerInfo",
        components: {},
        props: ['data']
    }
</script>