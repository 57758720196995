<template>
    <breadcrumbs-bar :links="[{name: 'Zoeken', url:'productSearch'},{name: 'Info', url: 'productInfo'}]"></breadcrumbs-bar>
    <div v-if="$store.state.productStore.activeProduct">
        <h2 class="page__title debit__result-small__title">{{ $store.state.productStore.activeProduct.factorycode }}</h2>
        <p class="debit__result-small display-inline">- {{$store.state.productStore.activeProduct.brand}} {{$store.state.productStore.activeProduct.description}} ( Artikel #{{ $store.state.productStore.activeProduct.articlenumber }} )</p>
    </div>
</template>

<script>
    import BreadcrumbsBar from '@/components/html/BreadcrumbsBar';
    import Product from '@/modules/product/classes/Product';
    export default {
        name: 'ProductHeader',
        components: { BreadcrumbsBar },
        data() {
            return {
                product: new Product()
            }
        },
        mounted() {
            this.fetchDebtor();
        },
        methods: {
            fetchDebtor() {
                this.product.id = this.$route.params.product;
                this.product.show(this.product).then((response) => {
                    this.$store.commit("setActiveProduct", response.data);
                });
            }
        }
    };
</script>

<style scoped>

</style>