<template>
    <div>
        
        <div class="mt-3" v-for="item in product.filter.arrayFilter"
                         v-bind:key="item.id"
                         :id="'debit__search-'+item.number">
                    <product-search-result :filter="product.filter" :selected="selectedShortcut" :product="item"
                                           :active="item.selected"></product-search-result>
                </div>
        </div>
</template>

<script>
    import Product from '@/modules/product/classes/Product';
    import ProductSearchResult from "@/modules/product/components/ProductSearchResult";

    export default {
        name: 'ProductEquivalent',
        components: {ProductSearchResult},
        data() {
            return {
                product: new Product(this.$route.params.product),
                products: [],
            }
        },
        created() {
            this.product.get("/" + this.product.id +"/equivalents").then((response) => {    
                if (!Array.isArray(response.data)) {
                    response.data = [response.data];
                }

                this.products = response.data;
                this.product.filter.setArrayFilter(this.products);
                this.product.filter.setSelected(0);

                if (this.products.length > 0) {
                    this.activeProduct = this.products[0];
                    this.products[0].selected = true;
                }
            });
        }
    };
</script>

<style scoped>

</style>