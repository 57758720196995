<template>
  <div class="section">
    <div class="section__container float-left">
      <h2 class="page__title">{{ title }}</h2>
      <div id="chart">
<!--        <apexchart type="bar" height="480" :options="chartOptions" :series="series"></apexchart>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColumnWidget",
  props: ['data', 'headers', 'title'],
  data () {
    return {
        series: [{
            name: '',
            data: this.data
        }],
        chartOptions: {
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'bottom', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    // return val + "%";
                    return val ;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: this.headers,
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return val + "";
                    }
                }
            },
        },
    }
  }
}
</script>

<style scoped>

</style>
