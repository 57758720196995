<template>
  <div class="mt-4">
      <dashboard-builder :layout="layout"></dashboard-builder>
  </div>
</template>

<script>
import DashboardBuilder from "@/components/widgets/DashboardBuilder";
export default {
  name: "DashboardIrel",
  components: {DashboardBuilder},
    data() {
      return {
          layout: [
              [
                  {
                      type: "calendar",
                      width: 6
                  },
                  {
                      type: "actionPoint",
                      width: 6
                  },
              ],
              [
                  {
                      type: "chances",
                      width: 6
                  },
                  {
                      type: "threats",
                      width: 6
                  }
              ]
          ]
      }
    }
}
</script>

<style scoped>

</style>
