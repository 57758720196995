import DebitSearch from "@/modules/debtor/pages/DebitSearch";
import DebitInfo from "@/modules/debtor/pages/DebitInfo";
//import RunningOrders from "@/modules/debtor/pages/RunningOrders";
//import OpenPosts from "@/modules/debtor/pages/OpenPosts";
//import DebitCheckout from "@/modules/debtor/pages/DebitCheckout";
import DebitStats from "@/modules/debtor/pages/DebitStats";
//import History from "@/modules/debtor/pages/History";
//import DebitBonus from '@/modules/debtor/pages/DebitBonus';
// import ProductHistory from "@/modules/debtor/pages/ProductHistory";
// import DebitTiresIframe from '@/pages/debit/DebitTiresIframe';
// import DebitNewsLetter from '@/pages/debit/DebitNewsLetter';
// import DebitEducation from '@/pages/debit/DebitEducation';
import DebitWebsite from '@/modules/debtor/pages/DebitWebsite';
import DebitReport from '@/modules/debtor/pages/rapport/DebitReport';
import DebitIrelReport from '@/modules/debtor/pages/rapport/DebitIrelReport';

import i18n from '@/i18n';
import DebitPacking from '@/modules/debtor/pages/DebitPacking';
import DebitReportCreate from '@/modules/debtor/pages/rapport/DebitReportCreate';
import ActionPointsCreateComponent from '@/modules/debtor/components/ActionPointsCreateComponent';

import DebitReportStore from '@/modules/debtor/pages/rapport/DebitReportStore';
import DebitReportShow from '@/modules/debtor/pages/rapport/DebitReportShow';
import Standard from '@/pages/layout/Standard';
import DebitHeader from '@/modules/debtor/components/DebitHeader';
//import store from '@/store';
//import router from '@/router';
import StandardBar from '@/pages/layout/StandardBar';

export default [
    {
        component: Standard,
        meta: {
            routes: "debtor/debitRoutes",
        },
        children: [
            {
                path: "/debit/:number/info",
                components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: DebitInfo
                },
                name: "debitInfo",
                label: i18n.t("debtor.info"),

            },
            // {
            //     path: "/debit/:number/orders",
            //     components: {
            //         header: DebitHeader,
            //         bar: StandardBar,
            //         default: RunningOrders
            //     },
            //     name: "debitOrders",
            //     label: i18n.t("order.current_orders")
            // },
            // {
            //     path: "/debit/:number/open",
            //     components: {
            //         header: DebitHeader,
            //         bar: StandardBar,
            //         default: OpenPosts
            //     },
            //     name: "debitOpen",
            //     label: i18n.t("general.open_posts")
            // },
            //{
            //    path: "/debit/:number/checkout",
            //    components: {
            //        header: DebitHeader,
            //        bar: StandardBar,
            //        default: DebitCheckout
            //    },
            //    name: "debitCheckout",
            //    label: i18n.t("general.checkout")
            //},
            {
                path: "/debit/:number/stats",
                components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: DebitStats
                },
                name: "debitStats",
                label: i18n.t("general.stats")

            },
            {
                 path: "/debit/:number/website",
                 components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: DebitWebsite
                 },
                 name: "debitWebsite",
                 label: i18n.t("general.website")
            },
            //{
            //    path: "/order/create",
            //    name: "orderCreate",
            //    label: i18n.t("order.create"),
            //    type: "link",
            //    meta: {
            //        behavior() {
            //            store.commit('setActiveSelectedDebtor', store.state.debitStore.activeDebtor);
            //            router.push({name: 'orderCreate'});
            //        }
            //    }
            //},
            //{
            //    path: "/debit/:number/history",
            //    components: {
            //        header: DebitHeader,
            //        bar: StandardBar,
            //        default: History
            //    },
            //    name: "debitHistory",
            //    label: i18n.t("general.history")
            //},
            //{
            //    path: "/debit/:number/history/product",
            //    components: {
            //       header: DebitHeader,
            //        bar: StandardBar,
            //        default: ProductHistory
            //    },
            //    name: "debitProductHistory",
            //    label: i18n.t("general.product_history")
            //},
            //{
            //    path: "/debit/:number/bonus",
            //    components: {
            //        header: DebitHeader,
            //        bar: StandardBar,
            //        default: DebitBonus
            //    },
            //    name: "debitBonus",
            //    label: i18n.t("general.bonuses")
            //},
            // {
            //     path: "/debit/:number/tireIframe",
            //     component: DebitTiresIframe,
            //     name: "debitIframe",
            //     label: i18n.t("debit_bar.iframe")
            // },
            // {
            //     path: "/debit/:number/news/letter",
            //     component: DebitNewsLetter,
            //     name: "debitNews",
            //     label: i18n.t("debit_bar.news")
            // },
            {
                path: "/debit/:number/report",
                components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: DebitReport
                },

                name: "debitReport",
                label: i18n.t("general.rapport")
            },
            {
                path: "/debit/:number/irel_rapport",
                components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: DebitIrelReport
                },
                name: "debitIrelRapport",
                label: i18n.t("general.report_irel")
            },
            {
                path: "/debit/:number/report/create",
                components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: DebitReportCreate
                },

                name: "DebitReportCreate",
                label: i18n.t("general.rapport_create")
            },
            // {
            //     path: "/debit/:number/education",
            //     component: DebitEducation,
            //     name: "debitEducation",
            //     label: i18n.t("debit_bar.education")
            // },
            {
                path: "/action-point/:number/create",
                components: {
                    header: DebitHeader,
                    bar: StandardBar,
                    default: ActionPointsCreateComponent
                },
                name: "debitActionPoint",
                label: i18n.t("general.action-point_create")
            },
        ]
    },
    {
        component: Standard,
        name: "debitExtra",
        children: [
            {
                path: "/debit",
                components: {
                    default: DebitSearch
                },
                name: "debitSearch"
            },
            {
                path: "/debit/:number/orders/:packing/show",
                components: {
                    header: DebitHeader,
                    default: DebitPacking
                },
                name: "debitPacking"
            },
            {
                path: "/debit/:number/report/create",
                components: {
                    header: DebitHeader,
                    default: DebitReportStore
                },
                name: "debitReportStore"
            },
            {
                path: "/debit/:number/report/:report/show",
                components: {
                    header: DebitHeader,
                    default: DebitReportShow
                },
                name: "debitReportShow"
            },
            {
                path: "/debit/:number/report/layout/create",
                components: {
                    header: DebitHeader,
                    default: DebitReportCreate
                },
                name: "debitReportCreate"
            },
        ]
    }
];
