<template>
    <div>
        <modal-container :open-modal="openCreate" @closeModalEvent="closeCreateManager">
            <manager-link-form @managerLinked="closeCreateManager"></manager-link-form>
        </modal-container>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">

                    <table-component :data="manager.managers">
                        <template v-slot:title>
                            <h2 class="page__title">Managers</h2>
                        </template>
                        <template v-slot:button>
                            <button class="button button-green" v-on:click="openCreateManager">Toevoegen</button>
                        </template>
                        <template v-slot:header>
                            <th>Id</th>
                            <th>Manager</th>
                            <th>Debiteur</th>
                            <th>Controls</th>
                        </template>
                        <template v-slot:data>
                            <tbody v-if="data && data.length > 0">
                                <tr v-for="(manager, index) in manager.managers" v-bind:key="manager">
                                    <td>{{ manager.id }}</td>
                                    <td>{{ manager.username }}</td>
                                    <td>{{ manager.debtor }}</td>
                                    <td>
                                        <button class="button button-danger-inverse button-small" v-on:click="removeManager(manager, index)">
                                        Verwijderen
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </table-component>
                    <div v-if="manager.managers.length === 0">
                        <p>Er zijn nog geen managers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Manager from '@/modules/manager/classes/Manager';
    import ModalContainer from '@/components/html/ModalContainer';
    import ManagerLinkForm from '@/modules/manager/components/ManagerLinkForm';
    import TableComponent from '@/components/partials/TableComponent';

    export default {
        name: 'ManagerManage',
        components: { TableComponent, ManagerLinkForm, ModalContainer },
        data() {
            return {
                manager: new Manager(),
                openCreate: false,
            };
        },
        created() {
            this.fetchManagers();
        },
        methods: {
            removeManager(manager, index) {
                this.manager.id = manager.id;
                this.manager.delete(this.manager).then(() => {
                    this.manager.managers.splice(index, 1);
                    this.manager.alert.createAlert('success', "Manager is succesvol verwijderd");
                });
            },
            closeCreateManager(manager) {
                this.openCreate = false;
                this.manager.alert.createAlert('success', "Manager is succesvol toegevoegd");
                this.manager.managers.push(manager);
            },
            openCreateManager() {
                this.openCreate = true;
            },
            fetchManagers() {
                this.manager.all().then((response) => {
                    this.manager.managers = response.data;
                });
            }
        }
    };
</script>

<style scoped>

</style>