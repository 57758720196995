<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Zoeken', url: 'debitSearch'}]"></breadcrumbs-bar>
        <h2 class="page__title">{{ $i18n.t('debtor.debtors') }}</h2>
        <div class="debit__search-container mb-3">
            <search-input @valueSearched="fetchDebit" @focusEventOut="activeShortcuts = true"
                          @focusEventIn="activeShortcuts = false"></search-input>
        </div>
        <filter-component :revenue="revenue" :options="[1, 2, 3,4, 5]" :filter="debtor.filter"></filter-component>
        <div>
            <div>
                <loading-spinner :spinning="spinning"></loading-spinner>
            </div>
            <div ref="debit_search">
                <div class="debit__search-container mt-3" v-on:click="selectDebtor(debtor)" v-for="debtor in debtors"
                     v-bind:key="debtor.number" :class="{'search__search-container-selected' : debtor.selected}"
                     :id="'debit__search-'+debtor.number">
                    <debit-search-result :selected="selectedShortcut" :debtor="debtor"
                                         :active="debtor.selected"></debit-search-result>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import SearchInput from "@/components/partials/SearchInput";
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    import DebitSearchResult from "@/modules/debtor/components/DebitSearchResult";
    import Debtor from "@/modules/debtor/classes/Debtor";
    import FilterComponent from '@/components/partials/FilterComponent';

    export default {
        name: "DebitSearch",
        components: { FilterComponent, DebitSearchResult, LoadingSpinner, BreadcrumbsBar, SearchInput},
        data() {
            return {
                debtor: new Debtor(),
                pageCount: 1,
                showingDebtors: [],
                debtors: [],
                activeDebitor: {},
                searchQuery: "",
                filterOption: 4,
                filterDirection: "up",
                originalList: [],
                spinning: false,
                activeShortcuts: false,
                selectedShortcut: 1,
                revenue: 0,
                currentYear: new Date().getFullYear(),
            }
        },
        created() {
   
            window.addEventListener('keydown', this.keyHandler);
        },
        mounted() {
            if(this.$store.state.debitStore.searchQuery) {
                this.fetchDebit(this.$store.state.debitStore.searchQuery);
                this.$store.commit("setSearchQuery", undefined);
            }
        },
        beforeUnmount() {
            window.removeEventListener('keydown', this.keyHandler);
        },
        methods: {
            keyHandler(e) {
                if (!this.activeShortcuts || this.debtors === undefined) {
                    return
                }
                let index = this.debtors.indexOf(this.activeDebitor);
                let debtorElemenet = "";
                switch (e.key) {
                    case "Tab":
                        e.preventDefault();
                        index++;
                        if (index > this.debtors.length - 1) {
                            index = 0;
                        }
                        this.selectDebtor(this.debtors[index]);
                        debtorElemenet = document.getElementById("debit__search-" + this.debtors[index].number);
                        debtorElemenet.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
                        break;

                    case "ArrowDown":
                        index++;
                        if (index > this.debtors.length - 1) {
                            index = 0;
                        }
                        this.selectDebtor(this.debtors[index]);
                        e.preventDefault();
                        debtorElemenet = document.getElementById("debit__search-" + this.debtors[index].number);
                        debtorElemenet.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
                        break;
                    case "ArrowUp":
                        index--;
                        if (index < 0) {
                            // index = this.debtors.length-1;
                            index = 0;
                        }
                        this.selectDebtor(this.debtors[index]);
                        e.preventDefault();
                        debtorElemenet = document.getElementById("debit__search-" + this.debtors[index].number);
                        debtorElemenet.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
                        break;
                    case "ArrowRight":
                        this.selectedShortcut++;
                        if (this.selectedShortcut > 8) {
                            this.selectedShortcut = 1;
                        }
                        e.preventDefault();
                        break;
                    case "ArrowLeft":
                        this.selectedShortcut--;
                        if (this.selectedShortcut < 1) {
                            this.selectedShortcut = 8
                        }
                        e.preventDefault();
                        break;
                    case "Enter":
                        e.preventDefault();
                        this.debtor.activeDebtor = this.debtors[index];
                        this.selectDebtor(this.debtors[index]);
                        break;
                    default:
                        this.debtor.activeDebtor = this.debtors[index];
                        this.selectDebtor(this.debtors[index]);
                }
            },
            selectDebtor(debtor) {
                if (debtor !== undefined) {
                    this.activeDebitor.selected = false;
                    debtor.selected = true;
                    this.activeDebitor = debtor;
                }
            },
            fetchDebit(debit) {
                this.debtor.get("", [{search:  debit}]).then((response) => {
                    if (response.data !== undefined) {
                        this.debtors = response.data;

                        this.revenue = 0;
                        this.debtors.forEach((debtor) => {
                            if (debtor.revenue !== undefined) {
                                this.revenue = Number(debtor.revenue) + this.revenue;
                            }
                        });
                        
                        this.debtor.filter.setArrayFilter(this.debtors);

                        this.originalList = this.debtors;
                        this.$store.commit("setSearchResults", this.debtors);
                        this.debtor.setData(this.debtors);
                        this.debtor.filter.filterOptions(4);
                        this.debtor.filter.filterDirection = 'up';
                        this.debtor.filter.changeFilterDirection();

                        if (this.debtors.length > 0) {
                                this.activeDebitor = this.debtors[0];
                                this.debtors[0].selected = true;
                                // this.setShowingDebtors();
                        }
                    }

                })
                this.debtor.filter.filterOptions(4);
                this.debtor.filter.filterDirection = 'up';
                this.debtor.filter.changeFilterDirection();
            }
        },
        watch: {
            '$store.state.hotkeyStore.keyEnter': function () {
                this.$store.commit('setKeyPressed', 'key'+this.selectedShortcut);
            },
            '$store.state.hotkeyStore.key1': function () {
                this.debtor.moveRoute('debitInfo');
            },
            '$store.state.hotkeyStore.key2': function () {
                this.debtor.moveRoute('debitOrders');
            },
            '$store.state.hotkeyStore.key3': function () {
                this.debtor.moveRoute('debitOpen');
            },
            '$store.state.hotkeyStore.key4': function () {
                this.debtor.moveRoute('debitCheckout');
            },
            '$store.state.hotkeyStore.key5': function () {
                this.debtor.moveRoute('debitHistory');
            },
            '$store.state.hotkeyStore.key6': function () {
                this.debtor.moveRoute('debitProductHistory');
            },
            '$store.state.hotkeyStore.key7': function () {
                this.debtor.moveRoute('debitStats');
            },
            '$store.state.hotkeyStore.key8': function () {
                this.debtor.moveRoute('debitStats');
            }
        }
    }
</script>

<style scoped>

</style>
