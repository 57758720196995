import store from '@/store';

export default class  {
    arrayListedKeys = [];
    constructor(arrayListedKeys) {
        this.arrayListedKeys = arrayListedKeys;
        window.addEventListener('keydown', this.keyHandler.bind(this));
    }

    keyHandler(e) {
        if (this.arrayListedKeys !== undefined) {
            this.arrayListedKeys.forEach((key) => {
                if (e.key === key) {
                    e.preventDefault();
                }
            });
        }
        store.commit('setKeyPressed', 'key'+e.key);
    }
}