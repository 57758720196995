<template>
    <div>
        <div class="section section__container">
            <h2 class="page__title">Debiteur</h2>
            <debit-search-debtors class="max-height-400" v-if="!$store.state.debitStore.selectedDebtor"></debit-search-debtors>
            <div v-else class="pb-4">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-4">
                                <p class="font-weight-bold">{{ $t('name') }}</p>
                                <p class="font-weight-bold">{{ $t('form.debit_number') }}</p>
                                <p class="font-weight-bold">{{ $t('form.address') }}</p>
                                <p class="font-weight-bold">{{ $t('form.phone') }}</p>
                            </div>
                            <div class="col-8">
                                <p>{{ $store.state.debitStore.selectedDebtor.name }}</p>
                                <p>{{ $store.state.debitStore.selectedDebtor.number }}</p>
                                <p>{{ $store.state.debitStore.selectedDebtor.address }}</p>
                                <p>{{ $store.state.debitStore.selectedDebtor.phone }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <p class="font-weight-bold">{{ $t('bon_type') }}</p>
                        <div>
                            <label class="form__radio">
                                <input type="radio" name="bon" checked>
                                Kontant
                            </label>
                        </div>

                        <div>
                            <label  class="form__radio">
                                <input type="radio" name="bon" >
                                Offerte
                            </label>
                        </div>
                    </div>
                </div>
                <span class="list__separator"></span>
                <div>
                    <button class="button button-green-inverse float-right" v-on:click="changeDebtor">Andere debiteur kiezen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import DebitSearchDebtors from "@/modules/debtor/components/DebitSearchDebtors";

    export default {
        name: "OrderDebtor",
        components: {DebitSearchDebtors},
        data() {
            return {
                selectedDebtor: undefined
            }
        },
        methods: {
            changeDebtor() {
                this.$store.commit('setActiveSelectedDebtor', undefined);
            }
        }

    }
</script>

<style scoped>

</style>