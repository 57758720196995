<template>
    <div class="bordered m-1" v-if="content.value !== '' && disabled">
        <h2 class="display-inline px-2 py-2" v-if="content.capital === 'high'">{{ content.value }}</h2>
        <p class="display-inline" v-else>{{ content.value }}</p>
        <div class="display-inline edit__buttons mx-5">
            <i class="fas fa-edit icon__show" :class="{'icon__hidden':!disabled}" v-on:click="setEditable"></i>
            <i class="fas fa-trash ml-4 icon__show color-danger" :class="{'icon__hidden':!disabled}" v-on:click="deleteLabel"></i>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-12">
                <input-component :initial-value="value" label="Vul hier de waarde in"  no-trans="true" @changeEvent="changedValue">
                    <template v-slot:buttonEnd>
                        <div class="button_end">
                            <i class="fas fa-save icon__show" :class="{'icon__hidden':disabled}" v-on:click="removeEditable"></i>
                        </div>
                    </template>
                </input-component>
            </div>
        </div>
    </div>
</template>

<script>
    import InputComponent from '@/components/partials/InputComponent';
    import DragElements from "@/plugins/DragDrop/classes/DragElements";
    export default {
        name: 'DragLabel',
        components: { InputComponent },
        props: ['content', 'indexCol', 'indexRow', 'index'],
        data() {
            return {
                disabled: false,
                value: "",
                dragElements: new DragElements(this.indexRow, this.indexCol, this.index)
            }
        },
        created() {
            if (this.content.value !== '') {
                this.value = this.content.value;
                this.disabled = true;
            }
        },
        methods: {
            deleteLabel() {
                this.dragElements.deleteContent();
            },
            changedValue(e) {
                this.value = e[1];
            },
            setEditable() {
                this.value = this.content.value;
                this.disabled = false;
            },
            removeEditable() {
                if (this.value !== "") {
                    this.disabled = true;
                    this.dragElements.setValue(this.value);
                }

            }
        }
    };
</script>

<style scoped>
.button_end {
    display: inline-block;
    position: absolute;
    right: 40px;
    top: 4px;
    z-index: 10;
}
.icon__show {
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    top: 8px;
    color: green;
}
.icon__hidden {
    z-index: 1;
    opacity: 0;
}
</style>