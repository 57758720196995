import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    user_id = 0;
    debtor_id = "";
    managers = [];
    fields = ['username', 'debtor', 'email']
    fillable = ['user_id', 'debtor_id'];
    constructor(route_affix = "", managerNumber = "") {
        if (managerNumber !== "") {
            super( "manager" + "/" + managerNumber + route_affix)
        } else {
            super( "manager" + route_affix)
        }
    }
}