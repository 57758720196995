<template>
    <div class="mb-4">
        <div v-for="(row, index) in layout" v-bind:key="index" class="row mt-2">
            <div v-for="(col, index) in row" v-bind:key="index" :class="'col-xl-' + col.width">
                <order-history-bar :values="[0,0,5,3,10,15]" v-if="col.type === 'history'"></order-history-bar>
                <open-orders v-if="col.type === 'openOrders'"></open-orders>
                <pie-widget title="Dagomzet in percentage artikelgroepen" :data="[44, 55, 13, 43, 22]"
                            :headers="['DIV', 'AUTOMATERIALEN', 'BANDEN EN WIELEN', 'EQUIPMENT', 'KENTEKENPLATEN']"
                            v-if="col.type === 'pie'"></pie-widget>
                <column-widget :data="[12000, 1700, 14500]" :headers="['OFFLINE', 'MIJNGROSSIER', 'VROOAM SHOP']"
                               title="Dagomzet"
                               v-if="col.type === 'column'"></column-widget>
                <calendar-widget v-if="col.type === 'calendar'"></calendar-widget>
                <action-points-widget v-if="col.type === 'actionPoint'"></action-points-widget>
                <chances-widget v-if="col.type === 'chances'"></chances-widget>
                <threats-widget v-if="col.type === 'threats'"></threats-widget>
            </div>
        </div>
    </div>
</template>

<script>
    import OpenOrders from "@/components/widgets/OpenOrders";
    import PieWidget from "@/components/widgets/PieWidget";
    import ColumnWidget from "@/components/widgets/ColumnWidget";
    import OrderHistoryBar from "@/components/widgets/OrderHistoryBar";
    import CalendarWidget from "@/components/widgets/CalendarWidget";
    import ActionPointsWidget from "@/components/widgets/ActionPointsWidget";
    import ChancesWidget from "@/components/widgets/ChancesWidget";
    import ThreatsWidget from "@/components/widgets/ThreatsWidget";

    export default {
        name: "DashboardBuilder",
        components: {
            ThreatsWidget,
            ChancesWidget,
            ActionPointsWidget, CalendarWidget, OrderHistoryBar, ColumnWidget, PieWidget, OpenOrders
        },
        props: ['layout'],
        mounted() {
        }
    }
</script>

<style scoped>
.placeholder {
    height: 200px;
    width: 100%;
    display: inline-block;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
</style>