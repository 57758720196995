<template>
    <div class="row mt-4">
        <div class="col-12">
            <div class="">
                <h2 class="page__title">{{ $i18n.t('debtor.running_orders')}}</h2>
                <filter-component :filter="order.filter" :options="[1, 3]"></filter-component>
                <div v-for="(inv, index) in order.filter.arrayFilter" v-bind:key="inv">
                    <information-bar :filter="order.filter" :index="index" :object="inv">
                        <template v-slot:title>
                            <h2 class="debit__result__title">{{ inv.invoice_number }}</h2>
                        </template>
                        <template v-slot:shortcuts>
                                <information-bar-shortcut :id="$route.params.number" :number="1" :selected="selectedShortcut" :active="inv.selected" link="debitPacking" :params="{number: $route.params.number, packing: inv.invoice_number}" tooltip="Pakbon" shortcut="1" code="PB"></information-bar-shortcut>

                        </template>
                        <template v-slot:content>
                            <div class="row">
                                <information-bar-content header="Factuurdatum" :text="inv.invoice_date"></information-bar-content>
                                <information-bar-content header="Order bedrag" :text="inv.amount"></information-bar-content>
                                <information-bar-content header="Opdracht" :text="inv.order_number"></information-bar-content>
                            </div>
                        </template>
                        <template v-slot:foldout>
                            <div class="row">
                                <information-bar-content header="Leverdatum" :text="inv.delivery_date"></information-bar-content>
                                <information-bar-content header="Routetijd" :text="inv.route_time"></information-bar-content>
                                <information-bar-content header="Routecode" :text="inv.route_code"></information-bar-content>
                            </div>
                            <div class="row">
                                <information-bar-content header="Status" :text="inv.status"></information-bar-content>
                                <information-bar-content header="Verkoper" :text="inv.seller"></information-bar-content>
                                <information-bar-content header="Verkoper 2" :text="inv.seller_second"></information-bar-content>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="list__separator"></span>
                                    <order-packing :data="inv.packing"></order-packing>
                                </div>
                            </div>
                        </template>
                    </information-bar>
                </div>
                <div v-if="order.filter.arrayFilter.length === 0">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="section section__container">
                                <h2 class="page__title">Er zijn geen lopende posten</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Order from "@/modules/debtor/classes/Order";
    import FilterComponent from "@/components/partials/FilterComponent";
    import Hotkey from '@/classes/helper/Hotkey';
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    import OrderPacking from "@/modules/order/components/OrderPacking";
    import InformationBarShortcut from "@/components/partials/information/InformationBarShortcut";

    export default {
        name: "RunningOrders",
        components: {InformationBarShortcut, OrderPacking, InformationBarContent, InformationBar, FilterComponent},
        data() {
            return {
                headers: [],
                tableData: [],
                order: new Order(this.$route.params.number),
                hotkey: new Hotkey(['ArrowUp', 'ArrowDown']),
                selectedShortcut: 1
            }
        },
        mounted() {
            this.order.filter.useScroll = false;
            this.getInvoices();

        },
        methods: {
            getInvoices() {
                this.order.all().then((response) => {
                    this.order.filter.setArrayFilter(response.data);
                    this.order.filter.setSelected(0);
                });
            }
        },
        watch: {
            '$store.state.hotkeyStore.keyArrowDown': function () {
                if (this.$store.state.hotkeyStore.keyArrowDown) {
                    this.order.filter.increaseSelected()
                }
            },
            '$store.state.hotkeyStore.keyArrowUp': function () {
                if (this.$store.state.hotkeyStore.keyArrowUp) {
                    this.order.filter.decreaseSelected()
                }
            },
        }
    }
</script>

<style scoped>

</style>
