import Validator from '@/classes/helper/Validator';
import axios from 'axios';
import Api from '@/classes/helper/Api';
import router from '@/router';
import store from '@/store';
import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    id = undefined;
    api_key = undefined;
    email = undefined;
    checkedApiKey = false;
    permissions = [];
    roles = [];

    constructor(user = null) {
        super('users');
     
        if (user !== null) {
            this.permissions = JSON.parse(localStorage.getItem('permissions'));
            this.roles = JSON.parse(localStorage.getItem('roles'));
            this.api_key = user.api_key;
            this.id = user.id;
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.api_key;
            axios.defaults.params = 
            {
               access_token: user.api_key
            }
            this.email = user.email;
        }
    }

    checkPermissionTo(permission_array) {
        let gotPermission = false;
        if (this.permissions === null) {
            router.push('login');
            return;
        }
        permission_array.forEach((permName) => {
            this.permissions.forEach((permission) => {
                if (permission.name.toString() === permName.toString()) {
                    gotPermission = true;
                }
            });
        });

        return gotPermission;
    }

    checkPermissions(permission_array){
        let gotPermission = false;
        permission_array.forEach((permName) => {
            this.permissions.forEach((permission) => {
                if (permission.name.toString() === permName.toString()) {
                    gotPermission = true;
                }
            });
        });

        return gotPermission;
    }

    checkRoleTo(role) {
        let gotRole = false;
        if (this.roles === null) {
            router.push('login');
            return;
        }
        this.roles.forEach((roleUser) => {
            if (role.includes(roleUser.name.toString())) {
                gotRole = true;
            }
        });
        return gotRole;
    }

    login(form) {
        let validator = new Validator();
        let formValidated = validator.validate(form);
        let api = new Api();
        if (validator.errors) {
            form = formValidated;
        } else {
            store.commit('setApiUrl', form.wholesaler.value);
            axios.post(api.apiUrl + '/crm/login', validator.parseForm(form)).then((response) => {
                this.email = form.email.value;
                this.api_key = response.data[0].api_token;
                this.roles = response.data[0].roles;
                this.permissions = response.data[0].permissions;
                this.id = response.data[0].id;

                // this.roles.forEach((role) => {
                //     console.log(role);
                //     role.permissions.forEach((perm) => {
                //         let gotPermission = false;
                //         this.permissions.forEach((permission) => {
                //             if (perm.id === permission.id) {
                //                 gotPermission = true;
                //             }
                //         });
                //         if (!gotPermission) {
                //             this.permissions.push(perm);
                //         }
                //     });
                // });

                store.commit('setPermissions', response.data[0].permissions);
                store.commit('setRoles', response.data[0].roles);
                router.push({ name: 'dashboardGeneral', params: { 'index': 0 } });
                
                store.commit('setUser', this);
                setTimeout(() => {
                    router.push({ name: 'dashboardGeneral', params: { 'index': 0 } });
                }, 200);

            }).catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        form.email.danger = true;
                        form.email.danger_text = 'False credentials';
                        form.password.danger = true;
                        form.password.danger_text = 'False credentials';
                    } else {
                        validator.parseErrors(form, err.response.data.errors);
                    }
                }
            });
        }
    }

    checkTokenValidity() {
        this.checkedApiKey = true;
        store.commit('setUser', this);
        this.all("/token").then((response) => {
            if (!response.valid.active) {
                 router.push({name: "login"})
            }
        });
        return true;
    }

    isAuthenticated() {
        if (store.state.generalStore.user.checkedApiKey && this.api_key !== undefined) {
            return true;
        } else {
            return this.checkTokenValidity();
        }
    }

    async userWithPermission(query_params = [], no_query = false) {
        let query = '';
        if (! no_query) {
            query = '?';
            query_params.forEach((param) => {
                query += Object.keys(param)[0] + '=' + param[Object.keys(param)[0]];
            });
        }

        return new Promise((resolve, reject) => {
            axios.get(this.api_url + '/crm/users/permission' + query).then((response) => {
                resolve(response.data.data);
            }).catch((err) => {
                this.errorHandler(err);
                reject(err.response.data);
            });
        });
    }
}
