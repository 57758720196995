<template>
    <div class="datepicker__container" style="max-height: 100px">
        <label  class="search__form__label search__form__label-active" v-if="noTrans" style="left: 10px">{{ label }}</label>
        <label  class="search__form__label search__form__label-active" v-else>{{ $t(label) }}</label>
    
     <date-picker v-model="date" @input="changes()" class="mt-2">
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="search__form__input"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
    </div>
</template>
<script>
    import { DatePicker } from 'v-calendar';
    export default {
        name: "DatePickerComponent",
        props: ['label', 'name', 'noTrans', 'modelValue', 'disabled'],
        emits: ['selectedEvent', 'update:modelValue'],
        components: {
            DatePicker
        },
        created() {
            this.parseDate();
        },
        data() {
            return {
                date: this.modelValue,
                openDatePicker: false,
                closing: false
            }
        },
        watch: {
            date(val) {
                this.parseDate();
                this.$emit('selectedEvent', [this.name, val]);
                this.$emit('update:modelValue', val);
            }
        },
        methods: {
            parseDate() {
                if (this.date !== "") {
                    const dateObj = new Date(this.date);
                    const month = String((Number(dateObj.getMonth())+1)).padStart(2, '0');
                    const day = String(dateObj.getDate()).padStart(2, '0');
                    const year = dateObj.getFullYear();
                    const output = year  + '/'+ month  + '/' + day;
                    this.date = output
                }

            },
            openClosePicker() {
                if (this.openDatePicker) {
                    this.closing = true
                    this.openDatePicker = false;
                    setTimeout(() => {
                        this.closing = false;
                    },200);
                }else {
                    this.openDatePicker = true;
                }

            }
        }
    }
</script>

<style scoped>
    .select-disabled {
        background-color: lightgray;
    }
</style>