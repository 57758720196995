<template>
    <div>
        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">{{  $i18n.t('general.revenue') }}</h2>
<!--                    <line-stats-component v-if="revenueYears.length > 0" :markers="1" :categories="revenueCategories" :data="revenueYears"></line-stats-component>-->
                    <apexchart v-if="revenueYears" :options="chartOptions" :series="revenueYears"></apexchart>
                    <loading-spinner></loading-spinner>
                </div>
            </div>
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">{{ $i18n.t('general.amount') }}</h2>
                    <apexchart v-if="amountYears" :options="chartOptions" :series="amountYears"></apexchart>
                    <loading-spinner></loading-spinner>
                    <!--                    <line-stats-component v-if="amountYears.length > 0" :markers="1" :categories="revenueCategories" :data="amountYears"></line-stats-component>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Product from '@/modules/product/classes/Product';
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    export default {
        name: 'ProductStats',
        components: {LoadingSpinner },
        data() {
            return {
                revenueYears: undefined,
                revenueCategories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

                amountYears: undefined,
                product: new Product(this.$route.params.product),
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#77B6EA', '#545454'],
                    dataLabels: {
                        enabled: this.markers,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: '.',
                        align: 'right'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1
                    },
                    xaxis: {
                        categories: this.categories,
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    }
                },
            }
        },
        mounted() {
            this.product.get("/" + this.product.id + "/revenue").then((response) => {
                let revenue = [];
                let amount = [];
                for ( let year in response) {
                    let revenueYear = {}
                    let amountYear = {}

                    amountYear[year] = [];
                    revenueYear[year] = [];
                    for (let month in response[year]) {
                        revenueYear[year].push(response[year][month].omzet);
                        amountYear[year].push(response[year][month].aantal);
                    }
                    revenue.push({name: year, data: revenueYear[year]});
                    amount.push({name: year, data: amountYear[year]})
                }
                this.revenueYears = revenue;
                this.amountYears = amount;
            });
        }
    };
</script>

<style scoped>

</style>