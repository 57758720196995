<template>
  <div class="section">
    <div class="section__container">
      <div id="chart">
        <h2 class="page__title pt-3">Orders per kwartier</h2>
        <apexchart ref="realtimeChart" type="bar" :width="'1500'" height="480" :options="chartOptions" :series="series" v-if="mountedComponent"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderHistoryBar",
  data () {
    return {
      mountedComponent: false,

      series: [{
        name: '',
        data: []
      }],
      responsive: [{
        breakpoint: undefined,
        options: {},
      }],
      chartOptions: {
        chart: {
          height: 350,
          width: '100%',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // return val + "%";
            return val ;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: ["07:00", "", "", "", "08:00", "", "", "", "09:00", "", "", "",
            "10:00", "", "", "", "11:00", "", "", "", "12:00", "", "", "", "13:00", "", "", "",
            "14:00", "", "", "", "15:00", "", "", "", "16:00", "", "", "", "17:00", "", "", ""],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "";
            }
          }
        },
      },
      dataValues: []
    }
  },
  created() {

  },
  mounted() {
    setTimeout(() => {
      this.checkValues();
    }, 100);
    this.mountedComponent = true;
  },
  methods: {
    checkValues() {
      let numberIterations = Math.floor(Math.random()*40);
      for (let i = 0; i < numberIterations; i++) {
        let value = Math.floor(Math.random()*35);
        this.dataValues.push(value);
      }

      if (this.dataValues.length < 40) {
        let numberValues = 40-this.dataValues.length
        for (let i = 0; i < numberValues; i++) {
          this.dataValues.push("0");
        }
      }
      this.series= [{
        data: this.dataValues
      }];
    }
  },
}
</script>

<style scoped>

</style>
