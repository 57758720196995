import i18n from '@/i18n';
import Order from '@/pages/layout/Order';
import OrderCreate from '@/modules/order/pages/OrderCreate';

export default [
    {

        name: 'order',
        component: Order,
        children: [
            {
                path: "/order/create",
                component: OrderCreate,
                name: "orderCreate",
                label: i18n.t("order_bar.order_create")
            }
        ]
    }
];