import Api from '@/classes/helper/Api';
import store from '@/store';
import router from '@/router';
import GeneralModel from '@/classes/helper/GeneralModel';
export default class extends GeneralModel{
    activeShortcuts = false;
    activeDebtor = undefined;
    id = '';
    fillable = [];
    constructor(id = "",route_affix = "") {
        if(id !== "" && route_affix !== ""){
            super( "debtor" + "/" + id + route_affix)
        }
        else if (id !== "") {
            super( "debtor" + "/" + id)
        } else {
            super( "debtor" + route_affix)
        }
    }

    moveRoute(name) {
        if (this.activeDebtor !== undefined) {
            router.push({name: name, params: {number: this.activeDebtor.debtor_number}});
        }
    }

    async fetchDebtors(debitSearch) {
        return new Promise(resolve => {
            let api = new Api();
            this.debtors = [];

            store.commit("toggleSpinner");
            api.get("/crm/debtor/search", [["name", debitSearch]]).then((response) => {
                this.debtors = response.data.debtor;
                store.commit("toggleSpinner");
                store.commit("setSearchResults", this.debtors);
                resolve(this.debtors);
                if (this.debtors) {
                    return this.debtors;
                }
            }).catch((err) => {
                resolve(err);
                this.$store.commit("toggleSpinner")
            });
        });

    }
}