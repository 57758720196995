<template>
    <div class="mb-4" v-if="articleData">
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">Omzet artikelgroepen ({{ year }})</h2>
                    <column-stats-component v-if="articleData" :data="articleData"
                                            :headers="articleHeaders"></column-stats-component>
                </div>
            </div>
            <div class="col-md-6">
<!--                <column-widget :data="revenueData"-->
<!--                               title="Omzet (2021)"-->
<!--                               :headers="revenueHeaders" class="m0"></column-widget>-->
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <list-component :skip_first_align="true" :align_right="true" title="Omzet artikelgroepen" :data="revenueArticleData" :headers="[' ',$i18n.t('general.forecast'), year-2, year-1, year, $i18n.t('general.total')]"></list-component>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="section section__container">
                    <h2 class="page__title">Omzet per jaar</h2>
                    <line-stats-component v-if="revenueYears.length > 0" :markers="1" :categories="revenueCategories" :data="revenueYears"></line-stats-component>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <h2 class="page__title">OmzetVerloop</h2>
                    <line-stats-component v-if="revenueTable.length > 0" :categories="revenueCategories" :data="revenueTable"></line-stats-component>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <loading-spinner></loading-spinner>
    </div>
</template>

<script>
    import i18n from '@/i18n';
    import Stats from "@/modules/debtor/classes/Stats";
    import ColumnStatsComponent from "@/components/widgets/stats/ColumnStatsComponent";
    import ListComponent from "@/components/partials/Listcomponent";
    import LineStatsComponent from "@/components/widgets/stats/LineStatsComponent";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";

    export default {
        name: "DebitStats",
        components: {LoadingSpinner, LineStatsComponent, ListComponent, ColumnStatsComponent},
        data() {
            return {
                year: new Date().getFullYear(),
                articleHeaders: ["Totaal","Overige", "Automaterialen", "Banden en wielen", "Gereedschap", "Kentekenplaten", "Vloeistoffen", "Werkplaats"],

                articleData: undefined,

                revenueArticleData: [],
                revenueHeaders: ["OFFLINE", "MIJNGROSSIER", "VROOAM SHOP"],

                revenueData: [],
                revenueCategories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

                revenueYears: [],
                revenueTableCategories: [],

                revenueTable: [],
                stats: new Stats(this.$route.params.number),

                totalYears: []
                
            }
        },
        mounted() {
            this.getInfo();
            this.$store.commit('toggleSpinner', true);
        },
        methods: {
            setRevenueTable(year) {
                let data = [];

                for( let month in this.stats.omzet.[year].months){
                    month = this.stats.omzet.[year].months.[month]
                    data.push(month);
                }

                this.revenueTable.push({
                    name: year,
                    data: data
                });
            },
            setRevenueYear() {
                this.revenueYears.push({
                    name: this.year,
                    data: this.stats.parseTotalsAllGroupMonthly(this.year)
                });
                this.revenueYears.push({
                    name: this.year-1,
                    data: this.stats.parseTotalsAllGroupMonthly(this.year-1)
                });
                this.revenueYears.push({
                    name: this.year-2,
                    data: this.stats.parseTotalsAllGroupMonthly(this.year-2)
                });
                this.revenueYears.push({
                    name: "Gemiddeld",
                    data: this.totalYears

                })
            },
            setRevenueArticleData(group){
                let intl = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

                this.revenueArticleData.push([
                    i18n.t('general.articlegroup.' + group),
                    intl.format(this.stats.prognose[this.year].total.[group]),
                    intl.format(this.stats.omzet[this.year-2].total.[group]),
                    intl.format( this.stats.omzet[this.year-1].total.[group]),
                    intl.format( this.stats.omzet[this.year].total.[group]),
                    intl.format(
                        this.stats.omzet[this.year-2].total.[group] +  
                        this.stats.omzet[this.year-1].total.[group] + 
                        this.stats.omzet[this.year].total.[group]
                    ),
                ]);
            },
            
            currentYear(){
                let totalYear = [];

                Object.entries(this.stats.omzet[this.year].total).forEach(element => {
                    totalYear.push(element[1]);
                });

                this.articleData = totalYear;
            },

            groupYearMontly(year){
                let monthsRevenue = [];
            
                Object.entries(this.stats.omzet.[year].months).forEach(omzet => {
                    monthsRevenue.push(this.stats.omzet.[year].months.[omzet[0]]);
                });
                
                this.revenueYears.push({name: year, data: monthsRevenue });
            },

            averageRevenue(){
                let averageRevenue = [];

                Object.entries(this.stats.omzet.average).forEach(average => {
                     averageRevenue.push(this.stats.omzet.average.[average[0]]);
                });

                this.revenueYears.push({name: i18n.t('general.average'), data: averageRevenue });
            },

            getInfo() {
                let years = [this.year-2,this.year-1,this.year];
                this.debtor = this.$store.state.debitStore.activeDebtor;
                    this.stats.all().then((response) => {
                        this.$store.commit('toggleSpinner', false);
                        this.stats.setStats(response.data);
                        this.currentYear();
                        this.setRevenueArticleData('car');
                        this.setRevenueArticleData('tyre');
                        this.setRevenueArticleData('fluids');
                        this.setRevenueArticleData('license');
                        this.setRevenueArticleData('equipment');
                        this.setRevenueArticleData('garage');
                        this.setRevenueTable(this.year);
                        this.setRevenueTable(this.year-1);
                        this.setRevenueTable(this.year-2);

                        years.forEach(year => {
                             this.groupYearMontly(year);
                        });
                        this.averageRevenue();
                    });
                
            },

        },
    }
</script>

<style scoped>

</style>
