export default class Alert {
    constructor() {
    }
    createAlert(type, text) {
        Alert.alertCreation(type, text);
    }

    static createAlert(type, text) {
        this.alertCreation(type, text);
    }

    static alertCreation(type, text) {
        let alertContainer = document.querySelector('.alert');
        if (alertContainer === null) {
            alertContainer = document.createElement('div');
            alertContainer.className = 'alert';
            document.querySelector('body').append(alertContainer);
        }
        let alert = document.createElement('div')
        alert.className = `alert__container alert__type-${ type }`;
        let alertText = document.createElement('p');
        alertText.className = "alert__text";
        alertText.innerHTML = text;
        alert.appendChild(alertText);

        alertContainer.appendChild(alert);

        // Delay to render component
        setTimeout(() => {
            alert.className = alert.className + " alert__container-show";
        }, 50);

        // Animation fadeout
        setTimeout(() => {
            alert.className = `alert__container alert__type-${ type } alert__container-hiding`;
        }, 2000);

        //remove element from DOM
        setTimeout(() => {
            alert.remove();
        }, 2500);
    }
}