<template>
    <div class="locale-changer display-inline">
        <select v-model="locale">
            <option v-for="(lang, i) in languages" :key="i" :value="lang">
                {{ lang }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'LanguageSwitcher',
        data () {
            return {
                languages: ['NL', 'EN', 'DE', 'FR'],
                locale: 'NL'
            }
        },
        watch: {
            'locale': function(val) {
                this.$i18n.setLocale(val);
            }
        }
    };
</script>

<style scoped>

</style>