<template>
    <div>
        <div class="section section__container pb-0">
            <h2 class="page__title">Rayon</h2>
            <div class="max-height-400">
                <search-input @valueSearched="fetchManager" @focusEventOut="activeShortcuts = true"
                              @focusEventIn="activeShortcuts = false"></search-input>
                <loading-spinner></loading-spinner>
                <div ref="debit_search"  class="max-height-400">
                    <div class="debit__search-container mt-3" v-for="manager in managers"
                         v-bind:key="manager" :class="{'debit__search-container-selected' : manager.selected}"
                         :id="'debit__search-'+manager.number">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import SearchInput from '@/components/partials/SearchInput';
    import LoadingSpinner from '@/components/partials/LoadingSpinner';
    import Manager from '@/modules/manager/classes/Manager';
    export default {
        name: 'ManagerSearch',
        components: { LoadingSpinner, SearchInput },
        data() {
            return {
                managers: [],
                manager : new Manager
            }
        },
        methods: {
            fetchManager(searchValue) {
                this.manager.search(['zoekkode', searchValue]).then((response) => {
                    this.managers = response;
                });
            }
        }
    };
</script>

<style scoped>

</style>