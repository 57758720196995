<template>
    <div>
        <div class="row ml-0 mr-0 section__container--no-round-top">
            <div class="col-md-2 p0 text-center color-main-green pointer tab-inactive" :class="{'tab-active' : activeTab === 0}" v-on:click="setActiveTab(0)">
                <p class="tab-line-height">Eigen actiepunten</p>
            </div>
            <div class="col-md-2 p0 text-center color-main-green pointer tab-inactive" :class="{'tab-active' : activeTab === 1}" v-on:click="setActiveTab(1)">
                <p class="tab-line-height">Ingeschoten actiepunten</p>
            </div>
            <div class="col-md-2 p0 text-center color-main-green pointer tab-inactive" :class="{'tab-active' : activeTab === 2}" v-on:click="setActiveTab(2)">
                <p class="tab-line-height">Toezicht actiepunten</p>
            </div>
            <div class="col-md-2 p0 text-center color-main-green pointer tab-inactive" :class="{'tab-active' : activeTab === 3}" v-on:click="setActiveTab(3)">
                <p class="tab-line-height">Afgeronde actiepunten</p>
            </div>
            <div class="col-md-2 p0 text-center color-main-green pointer tab-inactive" :class="{'tab-active' : activeTab === 4}" v-on:click="setActiveTab(4)">
                <p class="tab-line-height">Soft delete</p>
            </div>
        </div>
        <div class="section section__container section__container--no-round-top relative">
            <action-tab-table no-controls="false" type="Own" title="Eigen actiepunten" class="hidden" v-if="activeTab === 0 || closing"  :class="{'show' : activeTab === 0 && !closing, 'closing': closing}" :actionPointShows="actionPointShows" :action-point="actionPointOwn" @addDays="addDays" @showAction="showAction" @finishAction="finishAction" @newComment="newComment"></action-tab-table>

            <action-tab-table type="Watch" title="Ingeschoten actiepunten" class="hidden" v-if="activeTab === 1 || closing"  :class="{'show' : activeTab === 1 && !closing, 'closing': closing}" :actionPointShows="actionPointShows" :action-point="actionPointOwn" @addDays="addDays" @showAction="showAction" @finishAction="finishAction" @newComment="newComment"></action-tab-table>

            <action-tab-table noControles="true" type="Shot" title="Toezicht actiepunten" class="hidden" v-if="activeTab === 2 || closing"   :class="{'show' : activeTab === 2 && !closing, 'closing': closing}" :actionPointShows="actionPointShows" :action-point="actionPointWatch" @addDays="addDays" @showAction="showAction" @finishAction="finishAction" @newComment="newComment"></action-tab-table>

            <action-tab-table noControles="true" type="Shot" title="Afgeronde actiepunten" class="hidden" v-if="activeTab === 3 || closing"   :class="{'show' : activeTab === 3 && !closing, 'closing': closing}" :actionPointShows="actionPointShows" :action-point="actionPointFinished" @addDays="addDays" @showAction="showAction" @finishAction="finishAction" @newComment="newComment"></action-tab-table>

            <action-tab-table noControles="true" type="Shot" title="Afgeronde actiepunten" class="hidden" v-if="activeTab === 4 || closing"   :class="{'show' : activeTab === 4 && !closing, 'closing': closing}" :actionPointShows="actionPointShows" :action-point="actionPointFinishedAll" @addDays="addDays" @showAction="showAction" @finishAction="finishAction" @newComment="newComment"></action-tab-table>
        </div>
    </div>
</template>

<script>
    import ActionPoint from '@/modules/action-point/classes/ActionPoint';
    import ActionTabTable from "@/modules/action-point/components/ActionTabTable";

    export default {
        name: 'ActionShow',
        components: {ActionTabTable},
        emits: ['update:actionPoint', 'closeAction'],
        data() {
            return {
                actionPoint: new ActionPoint(),
                actionPointOwn: new ActionPoint(),
                actionPointShot: new ActionPoint(),
                actionPointWatch: new ActionPoint(),
                actionPointFinished: new ActionPoint(),
                actionPointFinishedAll: new ActionPoint(),
                actionPointShows: undefined,
                activeTab: 0,
                closing: false
            }
        },
        created() {
            this.actionPointWatch.type = "watch";
            this.fetchAction();
        },
        methods: {
            addDays(actionPoint, index, days, type) {
                let date = new Date(actionPoint.expires_at);
                date = date.addDays(days);

                const month = String((Number(date.getMonth())+1)).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const year = date.getFullYear();
                const output = year  + '-'+ month  + '-' + day;
                this.actionPoint.alert.createAlert('warning', 'Streefdatum aanpassing verwerken');
                this.actionPoint.get('/' + actionPoint.id + '/postpone', [{'postpone_until': output}]).then((response) => {
                    // actionPoint = response.data;
                    // actionPoint.expires_at = actionPoint.postpone_until
                    this.actionPoint.alert.createAlert('success', 'Streefdatum verwerkt');
                    this['actionPoint'+type].actionPoints[index] = response.data;
                })
            },
            setActiveTab(tab) {
                this.$emit("closeAction");
                this.actionPointShows = undefined;
                this.closing = true;
                setTimeout(() => {
                    this.closing = false;
                }, 300);
                this.activeTab =  tab;
            },
            showAction(actionPoint, index) {
                if (index !== this.actionPointShows) {
                    this.actionPoint.id = actionPoint.id;
                    this.actionPointShows = index;
                    this.$emit("update:actionPoint", actionPoint);
                } else {
                    this.$emit("closeAction");
                    this.actionPointShows = undefined;
                }

            },
            finishAction(actionPoint, index) {
                let user = this.$store.state.generalStore.user;

                switch (this.activeTab) {
                    case 0:
                        if(user.id === actionPoint.owner.id || user.id === actionPoint.user.id || user.checkPermissions(['manage actionpoints'])){
                                this.actionPointOwn.all("/" + actionPoint.id + '/finish').then(() => {
                                    this.actionPointOwn.actionPoints.splice(index, 1);
                                    this.actionPointOwn.alert.createAlert('success', 'Actiepunt afgemaakt');
                                });
                        }
                        else {
                             this.actionPointOwn.alert.createAlert('error', 'No permissions');
                        }
                        break;
                    case 1:
                        if(user.id === actionPoint.owner.id || user.id === actionPoint.user.id || user.checkPermissions(['manage actionpoints'])){
                            this.actionPointShot.all("/" + actionPoint.id + '/finish').then(() => {
                                this.actionPointShot.actionPoints.splice(index, 1);
                                this.actionPointShot.alert.createAlert('success', 'Actiepunt afgemaakt');
                            });
                        }
                        else {
                             this.actionPointOwn.alert.createAlert('error', 'No permissions');
                        }
                        break;
                    case 2:
                        if(user.id === actionPoint.owner.id || user.id === actionPoint.user.id || user.checkPermissions(['manage actionpoints'])){
                            this.actionPointWatch.all("/" + actionPoint.id + '/finish').then(() => {
                                this.actionPointWatch.actionPoints.splice(index, 1);
                                this.actionPointWatch.alert.createAlert('success', 'Actiepunt afgemaakt');
                            });
                        }
                        else {
                             this.actionPointOwn.alert.createAlert('error', 'No permissions');
                        }
                    break;
                }
                this.$emit("closeAction");
                this.actionPointShows = undefined;

            },
            fetchAction() {
                this.actionPointFinished.get('/list/finished').then((response) => {
                    this.actionPointFinished.actionPoints = response.data;
                    this.actionPointFinished.filtered = response.data;
                    
                });
                this.actionPointFinished.get('/list/finished/all').then((response) => {
                    this.actionPointFinishedAll.actionPoints = response.data;
                    this.actionPointFinishedAll.filtered = response.data;
                });
                this.actionPointOwn.get("/list/owned").then((response) => {
                    this.actionPointOwn.actionPoints = response.data;
                    this.actionPointOwn.filtered = response.data;
                });
                this.actionPointShot.get("/list/owner/" + this.$store.state.generalStore.user.id).then((response) => {
                    this.actionPointShot.actionPoints = response.data;
                    this.actionPointShot.filtered = response.data;
                    if (this.actionPointShot.actionPoints !== undefined) {
                        this.actionPointShot.actionPoints.forEach((actionPoint) => { actionPoint.watch = true })
                    }
                });
                this.actionPointWatch.get("/list/watching").then((response) => {
                    this.actionPointWatch.actionPoints = response.data;
                    this.actionPointWatch.filtered = response.data;
                    if (this.actionPointWatch.actionPoints !== undefined) {
                        this.actionPointWatch.actionPoints.forEach((actionPoint) => { actionPoint.watch = true })
                    }
                });
            },
            newComment(comment) {
                this.$emit('newComment', comment);
            },
        }
    };
</script>

<style scoped>
.hidden {
    display: none;
    position: relative;
    opacity: 0;
    width: 0;
    height: 0;
    right: 0;
    left: 150px;
    transition: left 0.3s ease-in-out, right 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.closing {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    left: -150px;
    right: 0;

}
.show {
    display: block;
    opacity: 1;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    right: 150px;
}

.tab-line-height {
   position: relative;
    top: 8px;
}
.tab-inactive {
    background-color: #f0f0f0;
    transition: background-color 0.3s ease-in-out;
}
.tab-active {
    background-color: white;
}
.tab-inactive:hover {
    background-color: white;

}
</style>