<template>
    <div class="bordered p-2">
        <div class="row">
            <div class="col-11 ">
                
            </div>
            <div class="col-1">
                <div class="row float-right">
                    <div class="col-1" v-on:click="remove()"><i class="fas fa-trash delete__icon icon__show"></i></div>
                </div>
            </div>
        </div>
        <div class="row">
             <div class="col-md-6">
                <select-with-search-component
                    :separator="false"
                    label="Ontvanger"
                    v-if="users"
                    objectToShow="email"
                    objectValue="email"
                    :danger_text="actionPoint.emails_danger_text"
                    :danger="actionPoint.emails_danger"
                    v-model="actionPoint.emails"
                    class-name="reciever"
                    :items="users"
                    autocomplete="off"
                    >
                </select-with-search-component>
            </div>
            <div class="col-md-6">
                <select-with-search-component
                    :separator="true"
                    label="Toezichthouders"
                    v-if="users"
                    objectToShow="email"
                    objectValue="email"
                    :danger_text="actionPoint.watchers_danger_text"
                    :danger="actionPoint.watchers_danger"
                    v-model="actionPoint.watchers"
                    class-name="watchers"
                    :items="users"
                    autocomplete="off"
                    >
                </select-with-search-component>
            </div>
        </div>
        <div class="mt-4">
            <input-component v-model="this.actionPoint.subject" type="text" label="Onderwerp" name="onderwerp"  no-trans="true" :danger="actionPoint.subject_danger" :danger_text="actionPoint.subject_danger_text"></input-component>
        </div>
        <div class="mt-4">
            <input-component type="area" v-model="this.actionPoint.description" label="Beschrijving" name="Beschrijving"  no-trans="true" :danger="actionPoint.description_danger" :danger_text="actionPoint.description_danger_text"></input-component>
        </div>

         <div class="mt-4">
            <checkbox-component custom-id="openDatepickerCheckbox" v-model="withDate" label="Met verloopdatum"></checkbox-component>
            <date-picker-component id="datePicker" v-if="withDate" no-trans="true" v-model="actionPoint.expires_at" name="expires_at" label="Dag tot verlopen actiepunt"></date-picker-component>
        </div>

    </div>
</template>
<script> 
    import i18n from '@/i18n';
    import ActionPoint from '@/modules/action-point/classes/ActionPoint';
    import Company from "@/modules/debtor/classes/Company";
    import CheckboxComponent from "@/components/partials/checkboxComponent";
    import DatePickerComponent from '@/components/partials/DatePickerComponent';
    import InputComponent from '@/components/partials/InputComponent';
    import SelectWithSearchComponent from '@/components/partials/SelectWithSearchComponent';

    export default {
        components: {InputComponent, SelectWithSearchComponent, DatePickerComponent,CheckboxComponent},
        name: "ActionpointForm",
        props: ["index", "users"],
        emits: ["actionUpdate" , "actionRemove"],
        data() {
            return {
                actionPoint: new ActionPoint(),
                company: new Company(),
                companies: undefined,
                selectedCompany: undefined,
                withDate: false,
                errors: [],
            }
        },
        created() {
            this.fetchCompanyList()
        },
        watch: {
            actionPoint: {
                handler: function () {
                    if(!this.validateForm()){
                        return false;
                    }
                   
                    this.$emit('actionUpdate', {"index": this.index ,"data": JSON.stringify(this.actionPoint)});
                },
                deep: true
            }

        },
        methods: {
            fetchCompanyList(){
                 this.company.get("/list", []).then((response) => {
                    this.companies = response;
                });
            },
            changedCompany(company){
                this.selectedCompany = company[1];
            },
           
            remove(){
                this.$emit('actionRemove', this.$props.index);
            },
            validateForm()
            {
                let valid = true;
                let emailRegex = "^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+.[a-zA-Z0-9-_.]+$";
                //let emialValidation = this.actionPoint.emails.match(emailRegex);
                
                if(this.actionPoint.emails.length=== 0){
                    valid = false;
                    this.actionPoint['emails' + "_danger"] = true;
                    this.actionPoint['emails' + "_danger_text"] =   i18n.t("form.errors.invalid");
                }

                for(let email of this.actionPoint.emails){
                    if(email.match(emailRegex) === null){
                        valid = false;
                        this.actionPoint['emails' + "_danger"] = true;
                        this.actionPoint['emails' + "_danger_text"] =   i18n.t("form.errors.invalid");
                        break;
                    }
                    else {
                        this.actionPoint['emails' + "_danger"] = false;
                    }
                }
               
                if(this.actionPoint.subject === ''){
                    valid = false;
                    this.actionPoint['subject' + "_danger"] = true;
                    this.actionPoint['subject' + "_danger_text"] =  i18n.t("form.errors.required");
                }else {
                     this.actionPoint['subject' + "_danger"] = false;
                }

                if(this.actionPoint.description === ''){
                    valid = false;
                    this.actionPoint['description' + "_danger"] = true;
                    this.actionPoint['description' + "_danger_text"] =  i18n.t("form.errors.required");
                }else{
                    this.actionPoint['description' + "_danger"] = false;
                    
                }
                
                this.actionPoint.subject
                this.actionPoint.description
                
                return valid;
            }
        }
    }
</script>