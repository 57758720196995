<template>
  <div>
    <div class="section">
      <div class="section__container float-left">
        <list title="Lopende orders" :headers="['factuur', 'Debiteur', '', 'Orderbedrag', 'Opdracht']" :data="runningOrders"></list>
<!--        <h2 class="page__title pt-3">Lopende orders</h2>-->
<!--        <table class="table">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th scope="col">Factuur</th>-->
<!--            <th scope="col">Debiteur</th>-->
<!--            <th scope="col"></th>-->
<!--            <th scope="col">Orderbedrag</th>-->
<!--            <th scope="col">Opdracht</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="order in runningOrders" v-bind:key="order.factuur">-->
<!--            <th scope="row">{{ order.factuur }}</th>-->
<!--            <td>{{ order.debtor }}</td>-->
<!--            <td>{{ order.debit_number }}</td>-->
<!--            <td>{{  order.amount }}</td>-->
<!--            <td>{{  order.assignment }}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
      </div>
    </div>

  </div>
</template>

<script>
import List from "@/components/widgets/ListWidget";
export default {
  name: "OpenOrders",
  components: {List},
  data() {
    return {
      runningOrders: []
    }
  },
  created() {
    this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      this.runningOrders = [
        {
          factuur: "F1180491",
          debtor: "KONTANTE VERKOPEN",
          debit_number: "0101004",
          amount: '32,29',
        },
        {
          factuur: "F1180490",
          debtor: "BMW JONKHEER &amp; BAKKER VOF",
          debtor_number: "0102418",
          amount: "121,88"
        },
        {
          factuur: "F1180489",
          debtor: "HOEK HOVENIERS",
          debtor_number: "0101434",
          amount: "42,50",
          assignment: "WAGEN 5066"
        },
        {
          factuur: "F1180488",
          debtor: "GARAGE VAN LEEUWEN",
          debtor_number: "0101707",
          amount: "102,96",
          assignment: "86RPFV -"
        },
      ];
    }
  }
}

</script>

<style scoped>

</style>
