import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    route = undefined;
    color = "red";
    message = "";
    title = "";
    shown = false;
    constructor() {
        super();
    }
}