import Filter from '@/classes/helper/Filter';
import Api from '@/classes/helper/Api';
import Debtor from '@/modules/debtor/classes/Debtor';
export default class extends Debtor{
    filter = new Filter();
    invoices = [];
    headers = [];
    constructor(id = "") {
        super(id,'/invoice');
        this.filter = new Filter(true)
    }

    async fetchInvoices(number) {
        let api = new Api();
        let tmpForm = new FormData();
        tmpForm.append('debiteur', number);
        api.post("/crm/debtor/openinvoices", tmpForm).then((response) => {
            this.debtor = response.data;

            if (response.data.factuur !== undefined) {
                for(let factuur in response.data.factuur[0]) {
                    this.headers.push(factuur);
                }
                this.invoices = response.data.factuur;
                this.filter.setArrayFilter(this.invoices);
            }
        });
    }
}