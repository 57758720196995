<template>
    <div>
        <detail-component title="Crediteurnummer" :data="data.creditor_number"></detail-component>
        <detail-component title="Naam" :data="data.name"></detail-component>
        <detail-component title="Adres" :data="data.address"></detail-component>
        <detail-component title="Postcode" :data="data.zipcode"></detail-component>
        <detail-component title="Plaats" :data="data.city"></detail-component>
        <detail-component title="Land" data=""></detail-component>
        <detail-component title="Telefoon" :data="data.phone"></detail-component>
        <detail-component title="Telefoon 2" :data="data.phone2"></detail-component>
        <detail-component title="Fax" :data="data.telefax"></detail-component>
        <detail-component title="E-mail" :data="data.email"></detail-component>
        <detail-component title="Groep" :data="data.group"></detail-component>
        <detail-component title="Kortinscode" :data="data.discount_code"></detail-component>
        <detail-component title="Bonsoort" :data="data.receipt_type"></detail-component>
        <detail-component title="Verzendcode" :data="data.send_code"></detail-component>
        <detail-component title="Betaalcode" :data="data.payment_code"></detail-component>
        <detail-component title="Alfacode" :data="data.alfacode"></detail-component>
        <detail-component title="Alfacode 2" :data="data.alfacode2"></detail-component>
        <detail-component title="BTW-nummer" :data="data.vat_number"></detail-component>
        <detail-component title="Rekeningnummer" :data="data.iban"></detail-component>
    </div>
</template>

<script>
    import DetailComponent from "@/components/partials/DetailComponent";
    export default {
        name: "CreditDetails",
        components: {DetailComponent},
        props: ['data']
    }
</script>

<style scoped>

</style>