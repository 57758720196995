import { createStore } from "vuex";
// import dashboardStore from "@/store/dashboardStore";
import generalStore from "@/store/generalStore";
import debitStore from "@/modules/debtor/debitStore";
import helperStore from "@/store/helperStore";
import orderStore from '@/modules/order/orderStore';
import hotkeyStore from '@/store/hotkeyStore';
import dashboardStore from '@/modules/dashboard/dashboardStore';
import dragDropStore from '@/plugins/DragDrop/dragDropStore';
import productStore from '@/modules/product/productStore';
import creditStore from '@/modules/creditor/creditStore';
import reportStore from '@/modules/report/reportStore';
import settingsStore from '@/modules/settings/settingsStore';

const store = createStore({
    modules: {
        debitStore: debitStore,
        dashboardStore: dashboardStore,
        generalStore: generalStore,
        helperStore: helperStore,
        orderStore: orderStore,
        hotkeyStore: hotkeyStore,
        dragDropStore: dragDropStore,
        productStore: productStore,
        creditStore: creditStore,
        reportStore: reportStore,
        settingsStore: settingsStore
    }
});

export default store;
