import { createI18n } from "vue3-i18n";

let languages = ['NL', 'EN', 'DE', 'FR'];
const messages = {
};

languages.forEach((lang) => {
    messages[lang] = Object.assign({},
        require(`./locales/python/${lang}/actionpoint.json`),
        require(`./locales/python/${lang}/general.json`),
        require(`./locales/python/${lang}/form.json`),
        require(`./locales/python/${lang}/filter.json`),
        require(`./locales/python/${lang}/order.json`),
        require(`./locales/python/${lang}/creditor.json`),
        require(`./locales/python/${lang}/debtor.json`),
        require(`./locales/python/${lang}/rayon.json`),
        )

});


const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'NL',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'NL',
    messages: messages,
});

export default i18n;
