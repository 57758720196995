<template>
    <div>
        <div v-if="filter.arrayFilter.length > 0">
            <div class="row">
                <div class="col-md-4">
                    <p class="display-inline" v-if="filter.searchQuery">{{ filter.arrayFilter.length }} {{ $i18n.t('filter.results_for') }} </p>
                    <p class="display-inline" v-else>{{ filter.arrayFilter.length }} {{ $i18n.t('filter.results') }}</p>
                    <strong class="display-inline ml-1">{{ filter.searchQuery }}</strong>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="display-inline">{{ $i18n.t('filter.sort_on') }}: </p>
                        </div>
                        <div class="col-md-8">
                            <div class="row" style="position: relative; top: -12px">
                                <div class="col-md">
                                    <div v-if="options.includes(1)">
                                        <button class="button button-full button-grey-pill button-grey-pill-active" v-if="filter.filterOption === 1"
                                                v-on:click="filter.changeFilterDirection">{{ $i18n.t('filter.number') }} <i
                                            :class="'fas fa-chevron-'+ filter.filterDirection + ' ml-1'"></i>
                                        </button>
                                        <button class="button button-grey-pill button-full" v-else
                                                v-on:click="filter.filterOptions(1)">{{ $i18n.t('filter.number') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div v-if="options.includes(2)">
                                        <button class="button button-full button-grey-pill button-grey-pill-active" v-if="filter.filterOption === 2"
                                                v-on:click="filter.changeFilterDirection">{{ $i18n.t('form.name') }} <i
                                            :class="'fas fa-chevron-'+ filter.filterDirection + ' ml-1'"></i>
                                        </button>
                                        <button class="button button-grey-pill button-full" v-else
                                                v-on:click="filter.filterOptions(2)">{{ $i18n.t('form.name') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md" v-if="options.includes(3)">
                                    <div>
                                        <button class="button button-full button-grey-pill button-grey-pill-active" v-if="filter.filterOption === 3"
                                                v-on:click="filter.changeFilterDirection">{{ $i18n.t('filter.relevance') }} <i
                                            :class="'fas fa-chevron-'+ filter.filterDirection + ' ml-1'"></i>
                                        </button>
                                        <button class="button button-grey-pill button-full" v-else
                                                v-on:click="filter.filterOptions(3)">{{ $i18n.t('filter.relevance') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md" v-if="options.includes(4)">
                                    <div>
                                        <button class="button button-full button-grey-pill button-grey-pill-active" v-if="filter.filterOption === 4"
                                                v-on:click="filter.changeFilterDirection">{{ $i18n.t('general.revenue') }} <i
                                            :class="'fas fa-chevron-'+ filter.filterDirection + ' ml-1'"></i>
                                        </button>
                                        <button class="button button-grey-pill button-full" v-else
                                                v-on:click="filter.filterOptions(4)">{{ $i18n.t('general.revenue') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md" v-if="options.includes(5)">
                                    <div>
                                        <button class="button button-full button-grey-pill button-grey-pill-active" v-if="filter.filterOption === 5"
                                                v-on:click="filter.changeFilterDirection">{{ $i18n.t('general.city') }} <i
                                            :class="'fas fa-chevron-'+ filter.filterDirection + ' ml-1'"></i>
                                        </button>
                                        <button class="button button-grey-pill button-full" v-else
                                                v-on:click="filter.filterOptions(5)">{{ $i18n.t('general.city') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="revenue !== undefined">
                <div class="col-md-12">
                    <p class="font-weight-bold float-right">Totale {{ $i18n.t('general.revenue') }}: € {{ formatDecimal }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FilterComponent',
        props: ['filter', 'options', 'revenue'],
        computed: {
            formatDecimal(){
                return this.$props.revenue.toFixed(2);
            }
        },
    };
</script>

<style scoped>

</style>