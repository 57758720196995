const generalStore = {
    state: {
        user: {},
        permissions: [],
        roles: [],
        enableEdit: false,
        alertBar: [],
        settings: null,
        apiUrl: process.env.VUE_APP_API_URL
    },
    mutations: {
        removeAlert(state, alert) {
            state.alertBar.forEach((al) => {
                if (alert === al) {
                    al.shown = true;
                }
            });
        },
        addAlert(state, value) {
            state.alertBar.push(value);
        },
        setEnableEdit(state, value) {
            state.enableEdit = value;
        },
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        updateToken(state, token) {
            state.user.api_key = token;
        },
        setPermissions(state, permissions) {
            state.permissions = permissions;
            localStorage.setItem('permissions', JSON.stringify(permissions));
        },
        setRoles(state, roles) {
            state.roles = roles;
            localStorage.setItem('roles', JSON.stringify(roles));
        },
        setSettings(state, value) {
            state.settings = value;
        },
        setApiUrl(state, value) {
            state.apiUrl = value;
            localStorage.setItem('wholesaler', value );
        }
    },
    actions: {},
    getters: {}
};

export default generalStore;
