<template>
    <div>
        <dashboard-create-builder :layout="dashboard.layout"></dashboard-create-builder>

    </div>
</template>

<script>
    import DashboardCreateBuilder from '@/modules/dashboard/components/DashboardCreateBuilder';
    export default {
        name: 'DashboardGeneral',
        components: { DashboardCreateBuilder },
        data() {
            return {
                dashboard: {}
            }
        },
        created() {
        },
        watch: {
            '$store.state.dashboardStore.dashboardLayouts': function () {
                let routerIndex = this.$route.params.index;
                this.dashboard = this.$store.state.dashboardStore.dashboardLayouts[routerIndex]
            }
        }
    };
</script>

<style scoped>

</style>