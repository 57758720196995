<template>
    <div class="row m0">
        <div class="col-5">
            <p class="pt-3">{{ label }}</p>
        </div>
        <div class="col-7">
            <div v-if="data === 'N'">Nee
            </div>
            <div v-else-if="data === 'J'">
                Ja
            </div>
            <div v-else>
                <label class="ml-1 pt-3">{{data}}</label>
            </div>
        </div>
        <span class="list__separator"></span>
    </div>
</template>
<script>
    export default {
        name: "DebtorInfoRow",
        props: ["label","name","data", 'edit'],
        data() {
            return {
                value: this.data,
                oldData: this.data,
                disabled: true
            }
        },
        methods: {}
    }
</script>

<style scoped>
.icon__show {
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    top: 8px;
    color: rgba(24, 24, 24, 0.3);
}
.icon__hidden {
    z-index: 1;
    opacity: 0;
}
.label {
    width: 100%;
    font-weight: 300;
}
.input {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px
}
.input__editable {
    border: 1px solid black;
}
.input:disabled {
    background-color: transparent;
}
</style>