import GeneralModel from '@/classes/helper/GeneralModel';

export default class ActionPoint extends GeneralModel{
    use_errors = true;
    subject = "";
    description = "";
    emails = [];
    watchers = '';
    expires_at = "";
    debtor_numbers = "";
    watch = false;
    fillable = ['subject', 'description', 'emails', 'expires_at', 'watchers', 'debtor_numbers', 'watch'];
    actionPoints = [];
    filtered = [];

    constructor() {
        super("action-point")
    }

    searchFilter(value){    
        if(value != ''){
            let test = this.actionPoints.filter(function(item) {
                if(item.company != null){
                    let reg = new RegExp(".*" + value + ".*", 'i');
                    let name  = item.company.name;

                    let nameCondition = name.match(reg);

                    if(nameCondition != null){
                        return nameCondition['length'] > 0;
                    }        

                    let debtorId  = String(item.company.debtor_id);
                    let debotrIdCondition = debtorId.match(reg);

                    if(debotrIdCondition != null){
                        return debotrIdCondition['length'] > 0
                    }    

                    let subject  = String(item.subject);
                    let subjectCondition = subject.match(reg);

                    if(subjectCondition != null){
                        return subjectCondition['length'] > 0
                    }      

                    let description  = item.description;
                    let descriptionCondition = description.match(reg);

                    if(descriptionCondition != null){
                        return descriptionCondition['length'] > 0
                    }  
                }
                return false;
            });

            this.filtered = test;
        }else {
            this.filtered = this.actionPoints;
        }
    }
}