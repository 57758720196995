<template>
    <div v-if="!spinning">
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <debit-report-list :data="report.reportsParsed"></debit-report-list>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <loading-spinner-manual :spinner="true"></loading-spinner-manual>
    </div>  
</template>

<script>
    import Report from "@/modules/report/classes/Report";
    import ReportLayout from '@/modules/report/classes/ReportLayout';
    import DebitReportList from '@/modules/report/components/DebitReportList';
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";
    
    export default {
        name: "DebitRapport",
        components: { DebitReportList, LoadingSpinnerManual},
        data() {
            return {
                report: new Report(this.$route.params.number),
                reportLayout: new ReportLayout(),
                spinning: true
            }
        },
        mounted() {
            this.fetchRapportForm();
        },
        methods: {
            fetchRapportForm() {
                this.report.all().then((response) => {
                    this.report.reports = response.data;
                    this.report.parseReports();
                    this.spinning = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>