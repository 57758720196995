import Standard from '@/pages/layout/Standard';
import AgendaIndex from '@/modules/agenda/pages/AgendaIndex';
import AgendaHeader from '@/modules/agenda/components/layout/header/AgendaHeader';

export default [
    {
        component: Standard,
        name: "agenda",
        meta: {
            routes: "agenda/agendaRoutes",
        },
        children: [
            {
                path: '/agenda',
                components: {
                    header: AgendaHeader,
                    default: AgendaIndex,
                },
                name: 'agendaIndex',
                label: "Agenda"
            },
        ]

    },
]