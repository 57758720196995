<template>
	<div v-if="info.actionPoints" clas="row">
        <h3 class='col-12 list__title'>Actiepunten</h3>
        <span class="list__separator"></span>
        <div  v-for="actionpoint in info.actionPoints" v-bind:key="actionpoint">
            <div class="row m-1">
                <div class="col-2">{{actionpoint.subject}}</div>
                <div class="col-2">{{actionpoint.user.first_name}} {{actionpoint.user.last_name}}</div>
                <div class="col-7">{{actionpoint.description}} {{actionpoint.user.last_name}}</div>

                <div class="col-1">
                    <button v-on:click="showAction(actionpoint)" class="">show</button>
                </div>
            </div>
            <hr/>
        </div>
        <action-info-show v-if="showDetail" :actionPoint="actionPoint" id="showActionPoint"></action-info-show>
    </div>
</template>

<script>
	import ActionInfoShow from "@/modules/action-point/components/ActionInfoShow";

	export default {
        name: "ActionPointIndex",
        components: {ActionInfoShow},
        data() {
            return {
                info: this.$store.state.debitStore.activeDebtor,
                showDetail: false,
                actionpoint: {}
            }
        },
        mounted() {
            
        },
        methods: {
            showAction(actionpoint){
                if( this.showDetail === true && actionpoint === this.actionPoint){
                     this.showDetail = !this.showDetail
                }
                else {
                    this.showDetail = true;
                }

                this.actionPoint = actionpoint;
            }
        }
    }
</script>