<template>
    <div class="mt-3">
        <div class="section section__container">
            <h2 class="page__title">Nieuw artikel aanmaken</h2>
            <div class="row">
                <div class="col-md-4">
                    <input-component></input-component>
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputComponent from "@/components/partials/InputComponent";
    export default {
        name: "SettingsProductCreate",
        components: {InputComponent},
        data() {
            return {
                product: {
                    fabriekscode: "",
                    omschrijving: "",
                    eancode: "",
                    soort: "",
                    merk: "",
                    groep: "",
                    alfacode: "",
                    crediteur: "",
                    brutoprijs: "",
                    inkoopprijs: "",
                    coutantheid: ""
                }
            }
        }
    }
</script>

<style scoped>

</style>