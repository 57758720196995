import Standard from '@/pages/layout/Standard';
import i18n from '@/i18n';
import ReportIndex from '@/modules/report/pages/ReportIndex';
import ReportCreate from '@/modules/report/pages/ReportCreate';
import StandardBar from '@/pages/layout/StandardBar';
import ReportHeader from '@/modules/report/components/ReportHeader';
import ReportLayoutCreate from '@/modules/report/pages/ReportLayoutCreate';
import store from '@/store';
import ReportPrint from '@/modules/report/pages/ReportPrint';

export default [
    {
        component: Standard,
        meta: {
            routes: "report/reportRoutes",
        },
        name: "report",
        path: "/report",
        beforeEnter: (to, from, next) => {
            if(store.state.settingsStore.reportSettings !== null) {
                if(store.state.settingsStore.reportSettings.defaultDebit !== null) {
                    next();
                    return;
                }
            }
            
            next({name: "dashboardGeneral", params: {index: 1}});
        },
        children: [
            {
                path: "",
                components: {
                    header: ReportHeader,
                    default: ReportIndex,
                    bar: StandardBar
                },
                name: "reportIndex",
                label: i18n.t("general.report")
            },
            {
                path: "create",
                components: {
                    header: ReportHeader,
                    default: ReportCreate,
                    bar: StandardBar
                },
                name: "reportCreate",
                label: i18n.t("general.report_create")
            },
            {
                path: "layout/create",
                components: {
                    header: ReportHeader,
                    default: ReportLayoutCreate,
                    bar: StandardBar,
                },
                name: "reportLayoutCreate",
                label: i18n.t("general.report_layout_create_edit"),
                beforeEnter: (to, from, next) => {
                    if(store.state.generalStore.user.checkPermissionTo(['manage reports'])) {
                        next();
                        return;
                    }
                    
                    next({name: "report", params: {index: 1}});
                },
            },
            {
                path: "print",
                components: {
                    header: ReportHeader,
                    default: ReportPrint,
                    bar: StandardBar
                },
                name: "reportPrint",
                label: i18n.t("general.report_print")
            },
        ]
    }
]