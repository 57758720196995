<template>
    <div>
        <div class="row">
            <div class="col-md-12 search__form" :class="{'search__form-area': type === 'area'}">
                <label for="search" class="search__form__label"
                       :class="{'search__form__label-active' : focus || value.length > 0, 'label-disabled' :  disabled }"
                       ref="search_label" v-if="noTrans">{{ label }}</label>
                <label for="search" class="search__form__label"
                       :class="{'search__form__label-active' : focus || value.length > 0, 'label-disabled' :  disabled }"
                       ref="search_label" v-else>{{ $t(label) }}</label>
                <textarea id="area" :disabled="disabled" class="search__form__input search__form__input-area" :class="[disabled ? 'select-disabled' : '' ]" v-on:focus="focusEventIn" v-on:focusout="focusEventOut"
                          v-model="value" @keyup.enter="search" v-if="type ==='area'"></textarea>
                <input id="search" :disabled="disabled" class="search__form__input" :class="[disabled ? 'select-disabled' : '' ]" v-on:focus="focusEventIn" v-on:focusout="focusEventOut"
                       v-model="value" @keyup.enter="search" v-else  :autocomplete="autocomplete"  >
                <small class="danger__text" :class="{'danger__text-active': danger}">{{ danger_text }}</small>
                <slot name="buttonEnd"></slot>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "InputComponent",
        props: ['label', 'name', 'danger', 'danger_text', 'type', 'noTrans', 'initialValue' , 'disabled', 'modelValue', 'autocomplete', 'label_class'],
        emits: ['valueSearched', 'focusEventIn', 'focusEventOut', 'changeEvent', 'changedValue', 'update:modelValue', 'modelValue'],
        data() {
            return {
                focus: false,
                value: ""
            }
        },
        created() {
            if (this.initialValue !== undefined) {
                this.value = this.initialValue
            }
        },
        watch: {
            modelValue(val) {
                this.value = val;
            },
            value(val) {
                this.$emit('changeEvent', [this.name, val]);
                this.$emit('update:modelValue', val);
            }
        },
        methods: {
            focusEventIn() {
                this.focus = true;
                this.$emit("focusEventIn");
            },
            focusEventOut() {
                this.focus = false;
                this.$emit("focusEventOut");
            },
            search() {
                if (this.$refs.search_button === undefined) {
                    this.$emit('changeEvent', [this.name, this.value]);
                }else {
                    this.$emit('valueSearched', this.value);
                    this.$refs.search_button.focus();
                }
            }
        }
    }
</script>

<style scoped>
    .select-disabled {
        background-color: lightgray;
    }
    .label-disabled {
        background-color: lightgray;
        z-index:9999;
    }
</style>
