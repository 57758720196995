<template>
    <div class="row mt-4">
        <div class="col-11" v-if="preview"></div>
        <div class="col-11" v-else>
            <div>
                <div class="drop">
                    <div>
                        <dashboard-create-builder :layout="placeholder"
                                                  @removeColumn="removeColumnPlaceholder"
                                                  @columnClicked="addSelectedTypeToColumn"
                                                  @saveSettings="addSettingsToLayout"
                        ></dashboard-create-builder>
                    </div>

                </div>
            </div>
        </div>
        <dashboard-select-bar></dashboard-select-bar>
    </div>
</template>

<script>

    import DashboardCreateBuilder from '@/modules/dashboard/components/DashboardCreateBuilder';
    import DashboardSelectBar from '@/modules/dashboard/components/DashboardSelectBar';

    let dashboardImages = require('./dashboardImages.json');
    export default {
        name: 'DashboardCreateComponents',
        components: {
            DashboardSelectBar,
            DashboardCreateBuilder
        },
        data() {
            return {
                layout: [],
                placeholder: [],
                rowHovered: false,
                dashboardImages: dashboardImages.dashboardWidgets,
                preview: false
            };
        },
        methods: {
            addSettingsToLayout(settings){

                this.placeholder[settings[0]][settings[1]].settings = settings[2];
                this.selectWidgetChange();
            },
            addSelectedTypeToColumn(indexes) {

                // this is necessary to remove reactivity from the store which is unwanted behaviour
                let widgetStore = JSON.parse(JSON.stringify(this.$store.state.dashboardStore.selectedWidget.widget));
                let widgetOptions = JSON.parse(JSON.stringify(this.$store.state.dashboardStore.selectedWidget.option.width));
                let widget = widgetStore;
                widget.width = widgetOptions;
                this.placeholder[indexes[0]][indexes[1]] = widget;
                this.selectWidgetChange();
            },
            removeColumnPlaceholder(indexes) {
                this.placeholder[indexes[0]].splice(indexes[1], 1);

                if (this.placeholder[indexes[0]].length === 0) {
                    this.placeholder.splice(indexes[0], 1);
                }

            },
            addRowPlaceholder() {
                this.placeholder.push(
                    []
                );
            },
            selectWidgetChange() {
                let widthWidget = this.$store.state.dashboardStore.selectedWidget.option.width;
                let newRowNecessary = true;

                this.placeholder.forEach((placeholderRow, index) => {
                    let countWidth = 0;

                    let tmpRow = [];
                    placeholderRow.forEach((placeholderCol, indexCol) => {
                        if (placeholderCol.type !== 'placeholder') {
                            countWidth += placeholderCol.width;
                            tmpRow.push(placeholderCol);
                        }else {
                            this.placeholder[index].splice(indexCol, 1);
                        }
                    });
                    this.placeholder[index] = tmpRow;

                    if (countWidth + widthWidget <= 12) {
                        newRowNecessary = false;

                        for (let i = 0; i < Math.floor((12 - countWidth) / widthWidget); i++) {
                            this.placeholder[index].push({ type: 'placeholder', width: widthWidget });
                        }
                    }
                });
                if (newRowNecessary) {
                    this.addRowPlaceholder();
                    for (let i = 1; i <= Math.floor(12 / widthWidget); i++) {
                        this.placeholder[this.placeholder.length-1].push({ type: 'placeholder', width: widthWidget });
                    }
                }
                this.$store.commit('setLayout', this.placeholder);
            }
        },
        watch: {
            '$store.state.dashboardStore.selectedWidget': function () {
                this.selectWidgetChange();
            }
        }
    };
</script>

<style scoped>


</style>