<template>
    <div>
        <div v-for="field in data" v-bind:key="field">
            <label>
                <input name="field" type="radio">
                {{ field }}
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RadioComponent",
        props: ['label', 'data']
    }
</script>

<style scoped>

</style>