<template>
    <div class="section section__container">
        <h2 class="page__title">Orders</h2>
        <p class="text-center order__total">{{ totalOpenOrders }}</p>
    </div>
</template>

<script>
    export default {
        name: "OrderTotals",
        data() {
            return {
                totalOpenOrders: 0
            }
        },
        watch: {
            '$store.state.orderStore.totalOpenOrders': function() {
                this.totalOpenOrders = this.$store.state.orderStore.totalOpenOrders;
            }
        }
    }
</script>

<style scoped>

</style>