<template>
    <div v-if="actionPoints.length > 0" class="rounded warning-bg mb-3">
        <div class="row px-1 m0 border-bottom">
            <h3 class='list__title'>Actiepunten</h3>
        </div>
        <div v-for="actionpoint in actionPoints" v-bind:key="actionpoint">
            <div class="row m0 py-2 border-bottom">
                <div class="col-md-5 col-sm-5">{{actionpoint.subject}}</div>
                <div class="col-md-5 col-sm-5">{{actionpoint.user.first_name}} {{actionpoint.user.last_name}}</div>
                <div class="col-md-2 col-sm-2">
                    <i v-on:click="showAction(actionpoint)" class="fas fa-search"></i>
                </div>
            </div>
            <div v-if="showDetail && actionPoint === actionpoint" class="row m0">
                <div class="col-md-12 p-2 text-dark">
                    <action-info-show @newComment="newComment" :actionPoint="actionpoint" id="showActionPoint"></action-info-show>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ActionInfoShow from "@/modules/action-point/components/ActionInfoShow";

	export default {
        name: "ActionPointDebtorIndex",
        components: {ActionInfoShow},
        props: ['actionPoints'],
        data() {
            return {
                actionPoint: {},
                showDetail: false,
            }
        },
        created() {
        },
        methods: {
            showAction(actionpoint){
                if( this.showDetail === true && actionpoint === this.actionPoint){
                     this.showDetail = !this.showDetail
                }
                else {
                    this.showDetail = true;
                }

                this.actionPoint = actionpoint;
            },
            newComment(comment){
                this.actionPoint.comments.push(comment);
            }
        }
    }
</script>
<style scoped>
    .warning-bg {
        background-color: #6e6e6e;
        color: white;
    }
</style>