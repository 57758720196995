<template>
    <div>
        <div class="row m0">
            <div class="col-5">
                <p class="pt-3">{{ title }}</p>
            </div>
            <div class="col-7">
               <p class="pt-3">{{symbol}} {{  data }}</p>
            </div>
            <span class="list__separator"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DetailComponent",
        props: ['title', 'data', 'symbol']
    }
</script>

<style scoped>

</style>