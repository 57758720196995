<template>
    <div>
        <dashboard-builder :layout="layout"></dashboard-builder>
    </div>
</template>

<script>
    import DashboardBuilder from "@/components/widgets/DashboardBuilder";

    export default {
        name: "DashboardOrders",
        components: {DashboardBuilder},
        data() {
            return {
                layout: [
                    [
                        {
                            type: "history",
                            width: 12
                        }
                    ],
                    [
                        {
                            type: "openOrders",
                            width: 6
                        }
                    ],
                    [
                        {
                            type: "column",
                            width: 4
                        }
                    ]
                ]
            }
        },
    }
</script>

<style scoped>

</style>
