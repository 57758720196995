<template>
    <div v-for="(field, name) of form" v-bind:key="field" class="mt-3">
        <div v-if="field.type === 'checkbox'">
            <checkbox-component :name="name" @emitCheckboxEvent="fieldHelper"></checkbox-component>
            <p class="display-inline ml-2">{{ $t('form.' + name) }}</p>
        </div>
        <div v-else-if="field.type === 'select'">
            <select-component :name="name" :data="field.data" :label="'form.'+ name" @selectedEvent="fieldHelper"></select-component>
        </div>
        <div v-else-if="field.type === 'area'">
            <input-component type="area" :name="name" @changeEvent="fieldHelper" :danger="field.danger" :danger_text="field.danger_text" :label="'form.'+ name"></input-component>
        </div>
        <div v-else-if="field.type === 'datepicker'">
            <date-picker-component :name="name" :label="'form.' + name" @selectedEvent="fieldHelper"></date-picker-component>
        </div>
        <div v-else-if="field.type === 'radio'">
            <radio-component :data="field.data" :label="'form.' + name"></radio-component>
        </div>
        <input-component  v-else :name="name" @changeEvent="fieldHelper" :danger="field.danger" :danger_text="field.danger_text" :label="'form.'+ name"></input-component>

    </div>
</template>

<script>
    import InputComponent from "@/components/partials/InputComponent";
    import Validator from "@/classes/helper/Validator";
    import CheckboxComponent from "@/components/partials/checkboxComponent";
    import SelectComponent from "@/components/partials/SelectComponent";
    import DatePickerComponent from "@/components/partials/DatePickerComponent";
    import RadioComponent from "@/components/partials/RadioComponent";

    export default {
        name: "FormBuilder",
        components: {RadioComponent, DatePickerComponent, SelectComponent, CheckboxComponent, InputComponent},
        props: ['form'],
        data() {
            return {
                validator: new Validator()
            }
        },
        created() {
            this.validator.init(this.form);
        },
        methods: {
            checkBoxFieldHelper(field) {
                console.log(field);
            },
            fieldHelper(field) {
                this.validator.editValue(field);
            }
        },
        watch: {
            '$store.state.helperStore.validateForms': function () {
                if (this.$store.state.helperStore.validateForms) {
                    this.validator.validate();
                    this.$store.commit('addValidatorForms', this.validator);
                }
            }
        }
    }
</script>

<style scoped>

</style>