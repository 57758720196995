<template>
    <div>
        <h2 class="search__result__title" v-on:click="setActiveDebtor">{{ debtor.name }}</h2>
        <div v-if="selected !== null" class="display-inline float-right d-none d-md-block hotkeys__container">
            <!--<debit-button :number="8" :selected="selected" :active="active" link="debitStats" :debtor="debtor"
                          :tooltip="$i18n.t('order.create')" shortcut="8" code="OR"></debit-button>-->
            <debit-button :number="7" :selected="selected" :active="active" link="debitStats" :debtor="debtor"
                          :tooltip="$i18n.t('general.stats')" shortcut="7" code="ST" v-on:click="setActiveDebtorNull"></debit-button>

            <!--<debit-button :number="6" :selected="selected" :active="active" link="debitProductHistory" :debtor="debtor"
                          :tooltip="$i18n.t('general.product_history')" shortcut="6" code="AH" v-on:click="setActiveDebtorNull"></debit-button>-->

            <!--<debit-button :number="5" :selected="selected" :active="active" link="debitHistory" :debtor="debtor"
                          :tooltip="$i18n.t('general.history')" shortcut="5" code="HI" v-on:click="setActiveDebtorNull"></debit-button>-->

            <!--<debit-button :number="4" :selected="selected" :active="active" link="debitCheckout" :debtor="debtor"
                          :tooltip="$i18n.t('general.checkout')" shortcut="4" code="KA" v-on:click="setActiveDebtorNull"></debit-button>-->

            <!--<debit-button :number="3" :selected="selected" :active="active" link="debitOpen" :debtor="debtor"
                          :tooltip="$i18n.t('general.open_posts')" shortcut="3" code="OP" v-on:click="setActiveDebtorNull"></debit-button>-->

            <!--<debit-button :number="2" :selected="selected" :active="active" link="debitOrders" :debtor="debtor"
                          :tooltip="$i18n.t('order.current_orders')" shortcut="2" code="LO" v-on:click="setActiveDebtorNull"></debit-button>-->

            <debit-button :number="1" :selected="selected" :active="active" link="debitInfo" :debtor="debtor"
                          :tooltip="$i18n.t('debtor.info')" shortcut="1" code="IN" v-on:click="setActiveDebtorNull"></debit-button>
        </div>
        <div class="row">
            <div class="search__result__headers col-md-2">
                <p class="search__result-small">{{  $i18n.t('debtor.number') }}</p>
                <p class="search__result-small">{{  $i18n.t('general.address') }}</p>
                <p class="search__result-small">{{  $i18n.t('general.city') }}</p>
                <p class="search__result-small">{{  $i18n.t('general.phone') }}</p>
                <p class="search__result-small">{{  $i18n.t('general.lastyear') }}</p>
                <p class="search__result-small">{{  $i18n.t('general.currentyear') }}</p>
                <p class="search__result-small">{{  $i18n.t('general.forecast') }}</p>
            </div>
            <div class="search__result__answers col-md-8">
                <p class="search__result-small">{{ debtor.debtor_number }}</p>
                <p class="search__result-small">{{ debtor.address }}</p>
                <p class="search__result-small">{{ debtor.city }}</p>
                <p class="search__result-small">{{ debtor.phone }}</p>
                <p class="search__result-small">&euro; {{revenue(currentYear-1)}}</p>
                <p class="search__result-small">&euro; {{revenue(currentYear)}}</p>
                <p class="search__result-small">&euro; {{debtor.statistics.prognosis.total}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import DebitButton from "@/modules/debtor/components/DebitButton";

    export default {
        name: "DebitSearchResult",
        props: ['debtor', 'active', 'selected'],
        components: {DebitButton},
        data() {
            return {
                currentYear: new Date().getFullYear(),
            }
        },
        computed: {
          
        },
        methods: {
            setActiveDebtor() {
                this.$store.commit('setActiveSelectedDebtor', this.debtor);
                this.$router.push({name: 'debitInfo', params: {number: this.debtor.debtor_number}});
            },
            setActiveDebtorNull(){
                 this.$store.commit('setActiveDebtor', 'null');
            },
            revenue(year) {
                if(this.$props.debtor.statistics != null){
                    let revenueYears = JSON.parse(this.$props.debtor.statistics.revenue);
                    return revenueYears.[year].total;
                }

                return '-';
            }
        }
    }
</script>

<style scoped>

</style>
