<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Toevoegen', url: 'dashboardCreate'}]"></breadcrumbs-bar>
        <h2 class="page__title">Dashboard toevoegen</h2>
        <div class="row section section__container">
            <div class="col-12">
                <div class="display-inline">
                    <input-component :danger_text="name_danger_text" :danger="name_danger" @changeEvent="nameChanged" name="name"  label="form.name"></input-component>
                </div>
                <button class="float-right button button-green-inverse display-inline" v-on:click="saveDashboard">Toevoegen</button>
            </div>

        </div>
        <div class="row">
            <div class="col-12">
                <dashboard-create-components></dashboard-create-components>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    import InputComponent from "@/components/partials/InputComponent";
    import DashboardCreateComponents from "@/modules/dashboard/components/DashboardCreateComponents";
    import Dashboard from '@/modules/dashboard/classes/Dashboard';
    import Alert from '@/classes/helper/Alert';
    export default {
        name: "DashboardCreate",
        components: {DashboardCreateComponents, InputComponent, BreadcrumbsBar},
        data() {
            return {
                name: "",
                name_danger: false,
                dashboard: new Dashboard,
                name_danger_text: ""
            }
        },
        methods: {
            saveDashboard() {
                this.name_danger = false;
                this.dashboard.name = this.name;
                this.dashboard.layout = JSON.stringify(this.$store.state.dashboardStore.layout);
                this.dashboard.store(this.dashboard).then(() => {

                    Alert.createAlert('success', 'Dashboard is succesvol toegevoegd');
                }).catch((err) => {
                    if (err.data !== undefined) {
                        if (err.data.errors.name) {
                            this.name_danger = true
                            this.name_danger_text = err.data.errors.name[0]
                        }
                    }

                });
            },
            nameChanged(value) {
                this.name = value[1]
            }
        }
    }
</script>

<style scoped>

</style>