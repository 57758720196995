<template>
    <div>

        <div class="dashboard__content" :class="{'dashboard__content-large': sidebar}">
            <div class="container-fluid ">
                <breadcrumbs-bar :links="[{name: 'order', url: 'orderCreate'}]"></breadcrumbs-bar>

                <h2 class="page__title">Order</h2>
                <order-bar></order-bar>
                <router-view v-slot="slotProps">
                    <transition name="route" mode="out-in">
                        <component :is="slotProps.Component"></component>
                    </transition>
                </router-view>
            </div>
        </div>

    </div>
</template>

<script>
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    import OrderBar from "@/components/html/OrderBar";
    export default {
        name: "Order.vue",
        components: {OrderBar, BreadcrumbsBar},
        data() {
            return {
                sidebar: false
            }
        },
        watch: {
            '$store.state.helperStore.sidebar': function() {
                this.sidebar = this.$store.state.helperStore.sidebar;
            }
        }
    }
</script>

<style scoped>

</style>