<template>
    <div>

        <div class="dashboard__content" :class="{'dashboard__content-large': sidebar}">
            <div class="container-fluid ">
                <breadcrumbs-bar :links="[{name: 'manager', url: 'managerManage'}]"></breadcrumbs-bar>

                <h2 class="page__title">Rayon</h2>
                <manager-bar></manager-bar>
                <router-view v-slot="slotProps">
                    <transition name="route" mode="out-in">
                        <component :is="slotProps.Component"></component>
                    </transition>
                </router-view>
            </div>
        </div>

    </div>
</template>

<script>
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    import ManagerBar from '@/components/html/ManagerBar';
    export default {
        name: "Manager",
        components: { ManagerBar, BreadcrumbsBar},
        data() {
            return {
                sidebar: false
            }
        },
        watch: {
            '$store.state.helperStore.sidebar': function() {
                this.sidebar = this.$store.state.helperStore.sidebar;
            }
        }
    }
</script>

<style scoped>

</style>