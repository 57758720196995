<template>
    <div class="d-flex justify-content-space-between">
        <p class="display-inline flex-1">
            Vorig jaar: €{{ last }}
        </p>
        <p class="display-inline flex-1">
            dit jaar: €{{ now }}
        </p>
         <p class="display-inline flex-1">
            prognose: €{{ prognosis }}
        </p>
    </div>
</template>

<script>
    export default {
        name: "DragInfoStatsComponent",
        props: ['last', 'now' ,'prognosis']
    }
</script>

<style scoped>

</style>