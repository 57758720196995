const debitStore = {
    state: {
        activeDebtor: undefined,
        selectedDebtor: undefined,
        searchResults: undefined,
        searchResultsQuery: undefined,
        searchQuery: undefined,
    },
    mutations: {

        setSearchQuery(state, value){
            state.searchQuery = value;
        },
        setActiveDebtor(state, value) {
            state.activeDebtor = value;
        },
        setActiveSelectedDebtor(state, value) {
            state.selectedDebtor = value;
        },
        setSearchResults(state, value) {
            state.searchResults = value;
        }
    },
    actions: {},
    getters: {}
};

export default debitStore;
