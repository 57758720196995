<template>
    <div class="row mt-4">
        <div class="col-md-4">
                <div class="debit__search-container mb-3">
                    <search-input title="Debiteuren" @valueSearched="fetchDebit" @focusEventOut="activeShortcuts = true"
                                  @focusEventIn="activeShortcuts = false"></search-input>
                </div>
        </div>
    </div>
</template>

<script>
    import SearchInput from "@/components/partials/SearchInput";
    export default {
        name: "SettingsSearch",
        components: {SearchInput},
        methods: {
            fetchDebit(value) {
                this.$store.commit('setSearchQuery', value);
                this.$router.push({name: 'debitSearch'});
            }
        }
    }
</script>

<style scoped>

</style>