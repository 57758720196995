<template>
    <div class="h-100" >
        <div class="section section__container sticky">
            <div class="d-flex justify-content-between">
                <drag-sidebar-categories @activateLayout="activateLayout" :activeLayout="active" :heading="'layout'" class="display-inline"></drag-sidebar-categories>
                <drag-sidebar-categories @activateLayout="activateLayout" :activeLayout="active" :heading="'content'" class="display-inline"></drag-sidebar-categories>
            </div>
            <div class="row">
                <div class="col-md-6" v-for="option in options" v-bind:key="option">
                    <drag-sidebar-choice
                        @dragstart='startDrag($event, option)'
                        @dragend="stopDrag($event, option)"
                        draggable="true"
                        type="heading"
                        :icon="option.icon"
                        :label="option.label"
                        class="mt-4"
                    ></drag-sidebar-choice>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import DragSidebarChoice from "@/plugins/DragDrop/DragSidebarChoice";
    import DragSidebarCategories from "@/plugins/DragDrop/DragSidebarCategories";
    import layouts from "./layouts.json"
    export default {
        name: "DragSidebar",
        components: {DragSidebarCategories, DragSidebarChoice},
        props: ['creator'],
        data() {
            return {
                options: [],
                active: "layout",
                layouts: layouts.layout
            }
        },
        methods: {
            activateLayout(activatedLayout) {
                if (this.active === "layout") {
                    this.active = activatedLayout;
                    this.options = this.creator[this.active];
                } else {
                    this.active = activatedLayout;
                    this.options = this.layouts;
                }


            },
            stopDrag() {
                this.$store.commit("dragSetItemDragging", undefined)
                this.$store.commit("dragSetDragging", false);

            },
            startDrag(evt, item) {
                item.type = this.active;
                evt.dataTransfer.setData('itemID', JSON.stringify(item))
                this.$store.commit("dragSetItemDragging", item)
                this.$store.commit("dragSetDragging", true);
            }
        },
        created() {
            this.options = this.layouts;

        }
    }
</script>

<style scoped>
    .sticky {
        width:auto; 
        position: sticky;
        top:10px;
    }
</style>