import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    comment = "";
    date = undefined;

    fillable = ['comment', 'date'];
    constructor(actionPointId = undefined) {
        if (actionPointId) {
            super('action-point/' + actionPointId);
        }else {
            super('');
        }
    }
}