<template>
    <div>
        <span class="list__separator"></span>
        <detail-component :title="$i18n.t('creditor.creditor')" :data="data.creditor.cred.crediteur"></detail-component>
        <detail-component :title="$i18n.t('creditor.factory_code')" :data="data.creditor.cred.crediteurfabriekskode"></detail-component>
        <detail-component :title="$i18n.t('creditor.group')" :data="data.creditor.cred.crediteurgroep"></detail-component>
        <detail-component :title="$i18n.t('creditor.priority')" :data="data.creditor.cred.crediteurprio"></detail-component>
    </div>
</template>

<script>
    import DetailComponent from "@/components/partials/DetailComponent";
    export default {
        name: 'ProductCreditorList',
        components: {DetailComponent},
        props: ['data']
    };
</script>

<style scoped>

</style>