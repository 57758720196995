<template>
    <div>
        <div>
            <information-bar :filter="filter" :object="product">
                <template v-slot:title>
                    <h2 class="search__result__title">{{ product.factorycode }}</h2>
                </template>
                <template v-slot:shortcuts>
                    <product-button :number="6" :selected="selected" :active="active" link="productStats" :product="product"
                                    :tooltip="$i18n.t('general.sell_stats')" shortcut="6" code="ST"></product-button>
                    <product-button :number="3" :selected="selected" :active="active" link="productEquivalent" :product="product"
                                    :tooltip="$i18n.t('general.equivalents')" shortcut="3" code="EQ"></product-button>
                    <product-button :number="2" :selected="selected" :active="active" link="productStock" :product="product"
                                    :tooltip="$i18n.t('general.stock')" shortcut="2" code="VI"></product-button>
                    <product-button :number="1" :selected="selected" :active="active" link="productInfo" :product="product"
                                    :tooltip="$i18n.t('general.product_info')" shortcut="1" code="IN"></product-button>
                </template>
                <template v-slot:content>
                    <div class="row">
                        <div class="col-md-6 px-3 py-1">
                          <span>{{product.factory_code + ' | ' +  product.description }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.price_gross')" :text=" product.grossprice "></information-bar-content>
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.stock')" :text=" Number(product.stockdepot) + Number(product.stockdepot2) "></information-bar-content>
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.stock_min')" :text="product.amountminstock"></information-bar-content>
                        </div>
                    </div>
                </template>
                <template v-slot:foldout>
                    <div class="row">
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.price_net')" :text=" product.nettoprice "></information-bar-content>
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.stock_local')" :text="product.stockdepot"></information-bar-content>
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.stock_max')" :text="product.amountmaxstock"></information-bar-content>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.marketability')" :text=" product.marketability "></information-bar-content>
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.stock_depot') + '2'" :text="product.stockdepot2"></information-bar-content>
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('order.amount')" :text="product.amountordered"></information-bar-content>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.product_number')" :text=" product.articlenumber "></information-bar-content>
                        </div>
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('order.backorder')" :text="product.backorder"></information-bar-content>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <information-bar-content :header="$i18n.t('general.brand')" :text=" product.brand "></information-bar-content>
                        </div>
                    </div>
                </template>
            </information-bar>
        </div>
    </div>
</template>

<script>
    import ProductButton from "@/modules/product/components/ProductButton";
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    export default {
        name: "ProductSearchResult",
        components: {InformationBarContent, InformationBar, ProductButton},
        props: ['active', 'product', 'selected', 'filter'],
    }
</script>

<style scoped>

</style>