<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <order-history-bar></order-history-bar>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <open-orders></open-orders>
      </div>
      <div class="col-md-6">
        <pie-widget title="Maandomzet in percentage artikelgroepen" :data="[44, 55, 13, 43, 22]" :headers="['DIV', 'AUTOMATERIALEN', 'BANDEN EN WIELEN', 'EQUIPMENT', 'KENTEKENPLATEN']"></pie-widget>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <column-widget :data="[12600, 2200, 15000]" :headers="['OFFLINE', 'MIJNGROSSIER', 'VROOAM SHOP']"></column-widget>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
</template>

<script>
import OpenOrders from "@/components/widgets/OpenOrders";
import PieWidget from "@/components/widgets/PieWidget";
import ColumnWidget from "@/components/widgets/ColumnWidget";
import OrderHistoryBar from "@/components/widgets/OrderHistoryBar";
export default {
  name: "DashboardMonthly",
  components: {OrderHistoryBar, ColumnWidget, PieWidget, OpenOrders}
}
</script>

<style scoped>

</style>
