<template>
    <div>
        <div class="dashboard-create__bar">
            <div class="dashboard-create__bar-container">
                <div v-for="(widget, index) in widgets" v-bind:key="widget">
                    <dashboard-select-widget :index="index" @selectOption="selectOption" @selectWidget="selectWidget" :widget="widget"></dashboard-select-widget>
                    <span class="list__separator" v-if="index < widgets.length-1"></span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import DashboardSelectWidget from "@/modules/dashboard/components/DashboardSelectWidget";

    export default {
        name: "DashboardSelectBar",
        components: {DashboardSelectWidget},
        data() {
            return {
                widgets: [],
                selectedWidget: undefined,
                selectedWidth: undefined
            }
        },
        created() {
            this.init();
        },
        methods: {
            init(){
                this.widgets = [
                    {
                        type: 'pie',
                        icon: 'fas fa-chart-pie',
                        selected: false,
                        data: [
                            'day_stats_percent_article',
                            'week_stats_percent_article',
                            'month_stats_percent_article',
                            'year_stats_percent_article'
                        ],
                        options: [
                            {
                                name: '4/12',
                                width: 4,
                            },
                            {
                                name: '6/12',
                                width: 6
                            },
                        ]
                    },
                    {
                        type: 'column',
                        icon: 'fas fa-chart-bar',
                        selected: false,
                        data: [
                            'day_revenue',
                            'week_revenue',
                            'year_revenue'
                        ],
                        options: [
                            {
                                name: '3/12',
                                width: 3
                            },
                            {
                                name: '4/12',
                                width: 4,
                            },
                            {
                                name: '6/12',
                                width: 6
                            },
                            {
                                name: '12/12',
                                width: 12
                            }
                        ]
                    }
                ]
            },
            selectWidget(index) {
                if (this.selectedWidget !== undefined) {
                    this.widgets[this.selectedWidget].selected = false;
                }
                if (index === this.selectedWidget) {
                    this.widgets[index].selected = false;
                    this.selectedWidget = undefined;
                }else {
                    this.widgets[index].selected = true;
                    this.selectedWidget = index;
                }
            },
            selectOption(indexes) {
                //there was a better method but it became way too messy so the performance is not influenced by this.
                this.widgets.forEach((widget) => {
                    widget.options.forEach((option) => {
                        option.selected = false;
                    });
                });
                this.widgets[indexes[0]].options[indexes[1]].selected = true;
                let selectedWidgetOption = {
                    widget: this.widgets[indexes[0]],
                    option: this.widgets[indexes[0]].options[indexes[1]]
                };
                this.$store.commit('setWidget', selectedWidgetOption);

            }
        }
    }
</script>

<style scoped>

</style>