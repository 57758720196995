const orderStore = {
    state: {
        totalOpenOrders: 0
    },
    mutations: {},
    actions: {},
    getters: {}
};

export default orderStore;
