<template>
    <div>

        <div class="dashboard__content" :class="{'dashboard__content-large': sidebar}">
            <div class="container-fluid ">
                <breadcrumbs-bar :links="[{name: 'Zoeken', url: 'settingSearch'}]"></breadcrumbs-bar>

                <h2 class="page__title">Retour</h2>
                <router-view v-slot="slotProps">
                    <transition name="route" mode="out-in">
                        <component :is="slotProps.Component"></component>
                    </transition>
                </router-view>
            </div>
        </div>

    </div>
</template>

<script>

    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";

    export default {
        name: "reversion",
        components: {BreadcrumbsBar},
        data() {
            return {
                sidebar: false
            }
        },
        created() {
        },
        methods: {
        },
        watch: {
            '$store.state.helperStore.sidebar': function () {
                this.sidebar = this.$store.state.helperStore.sidebar;
            }
        }
    }
</script>

<style scoped>
</style>
