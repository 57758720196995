<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-12 mb-12">
                <action-show @closeAction="removeActionPoint" @newComment="newComment"  v-model:actionPoint="actionPoint"></action-show>
            </div>
            <div class="col-md-12 show-action-hidden ">
               <!-- <action-info-show @newComment="newComment" :actionPoint="shownActionPoint" id="showActionPoint"></action-info-show> -->
            </div>
        </div>
    </div>
</template>
<script>
    import ActionShow from '@/modules/action-point/components/ActionShow';
    //import ActionInfoShow from "@/modules/action-point/components/ActionInfoShow";
    export default {
        name: 'ActionIndex',
        components: { ActionShow },
        data() {
            return {
                actionPoint: undefined,
                showAction: false,
                oldAction: undefined,
                newAction: undefined,
                shownActionPoint: undefined
            }
        },
        methods: {
            removeActionPoint() {
                this.toggleActionPoint();
                setTimeout(() => {
                    this.actionPoint = undefined;
                }, 400);
            },
            newComment(comment) {
                this.actionPoint.comments.push(comment);
            },
            toggleActionPoint() {
                this.showAction = true;
                setTimeout(()=> {
                    this.shownActionPoint = this.newAction
                    this.showAction = false;
                }, 400);

            }
        },
        watch: {
            actionPoint(val) {
                this.newAction = val;
                this.toggleActionPoint();
            }
        }

    };
</script>

<style scoped>
#showActionPoint {
    height:100%;
}

.col-md-0 {
    flex: 0 0 0%;
    max-width: 0%;
}
.show-action-show {
    position: relative !important;
    opacity: 1 !important;
    height: inherit;
}
.show-action-transitioning {
    display: block;
    flex: 0 !important;
}
.show-action-hidden {
    opacity: 0;

}
.transition__width {
    /*transition: max-width 0.3s ease-in-out;*/

    transition: flex 0.4s ease-in-out, max-width .4s ease-in-out, opacity .4s ease-in-out;
}

</style>