<template>
    <div class="section__container mt-2 pt-2" v-if="!spinning">
        <div v-if="reports">
            <div class="row m0" v-for="report in reports" v-bind:key="report">
                <div class="col-md-12">
                    <h4>{{report.date}}</h4>
                    <p><strong>{{report.user}}</strong></p>
                    <div class="row" v-for="info in report.reports" v-bind:key="info">
                        <div class="col-md-3" v-html="info.description"></div>
                        <div class="col-md-9" v-html="info.text"></div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12 text-center">
                <div class="section section__container">
                    <h2 class="page__title">{{ $i18n.t('debtor.irel.no_result') }}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
         <loading-spinner-manual :spinner="spinning"></loading-spinner-manual>
    </div>
</template>

<script>
    import IrelReport from "@/modules/debtor/classes/IrelReport";
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

    export default {
        name: "DebitIrelReport",
        components: {LoadingSpinnerManual },
        data() {
            return {
                reports: [],
                irelRapport: new IrelReport(this.$route.params.number),
                spinning: 'true',
            }
        },
        mounted() {
            this.irelRapport.get().then((response) => {
                this.reports = response;
                this.spinning = false;
            });
            
        },
        methods: {
           
        }
    }
</script>

<style scoped>

</style>