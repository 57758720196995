<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Orders', url: 'dashboardOrders'}]"></breadcrumbs-bar>
        <h2 class="page__title">{{ $i18n.t('general.dashboard') }}</h2>
        <router-link :to="{name: 'dashboardCreate'}">
            <button class="button button-green float-right" style="position: relative; top: -60px">{{ $i18n.t('general.add') }}</button>
        </router-link>
<!--        <dashboard-bar :dashboards="dashboards"></dashboard-bar>-->
    </div>
</template>

<script>
    import BreadcrumbsBar from '@/components/html/BreadcrumbsBar';
    import Dashboard from '@/modules/dashboard/classes/Dashboard';
    export default {
        name: 'DashboardHeader',
        components: { BreadcrumbsBar },
        data() {
            return {
                sidebar: false,
                dashboards: [],
                dashboard: new Dashboard()
            }
        },
        created() {
            this.getDashboards();
        },
        methods: {
            getDashboards() {
                this.dashboard.all().then((response) => {

                    response.data.forEach((dash) => {
                        this.dashboards.push({
                            name: dash.name,
                            layout: JSON.parse(dash.layout)
                        })
                    });
                    this.$store.commit('setDashboardLayouts', this.dashboards);
                });
            }
        },
    };
</script>

<style scoped>

</style>