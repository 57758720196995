const hotkeyStore = {
    state: {
        keyPressed: false,
        key1: false,
        key2: false,
        key3: false,
        key4: false,
        key5: false,
        key6: false,
        key7: false,
        key8: false,
        key9: false,
        keyTab: false,
        keyEnter: false,
        keyArrowUp: false,
        keyArrowDown: false,
        keyArrowLeft: false,
        keyArrowRight: false,
    },
    mutations: {
        setKeyPressed(state, key) {
            if (state[key] !== undefined) {
                state[key] = true;
                state.keyPressed = true;
                setTimeout(() => {
                    state[key] = false;
                    state.keyPressed = false;
                }, 50);
            }
        },
    },
    actions: {},
    getters: {}
};
export default hotkeyStore;
