const creditStore = {
    state: {
        activeCreditor: undefined,
    },
    mutations: {
        setActiveCreditor(state, value) {
            state.activeCreditor = value;
        },
    },
    actions: {},
    getters: {}
};

export default creditStore;
