<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="section section__container">
                <h2 class="page__title">Lopende orders</h2>
                <filter-component :filter="history.filter" :options="[1, 3]"></filter-component>
                <div v-for="(inv, index) in history.filter.arrayFilter" v-bind:key="inv">
                    <information-bar :filter="history.filter" :index="index" :object="inv">
                        <template v-slot:title>
                            <h2 class="debit__result__title">{{ inv.invoice_number }}</h2>
                        </template>
                        <template v-slot:content>
                            <div class="row">
                                <information-bar-content header="Factuurdatum" :text="inv.invoice_date"></information-bar-content>
                                <information-bar-content header="Order bedrag" :text="inv.amount"></information-bar-content>
                                <information-bar-content header="Opdracht" :text="inv.order_number"></information-bar-content>
                            </div>
                        </template>
                        <template v-slot:foldout>
                            <div class="row">
                                <information-bar-content header="Leverdatum" :text="inv.delivery_date"></information-bar-content>
                                <information-bar-content header="Routetijd" :text="inv.route_time"></information-bar-content>
                                <information-bar-content header="Routecode" :text="inv.route_code"></information-bar-content>
                            </div>
                            <div class="row">
                                <information-bar-content header="Status" :text="inv.status"></information-bar-content>
                                <information-bar-content header="Verkoper" :text="inv.seller"></information-bar-content>
                                <information-bar-content header="Verkoper 2" :text="inv.seller_second"></information-bar-content>
                            </div>
                        </template>
                    </information-bar>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FilterComponent from '@/components/partials/FilterComponent';
    import InformationBar from '@/components/partials/information/InformationBar';
    import InformationBarContent from '@/components/partials/information/InformationBarContent';
    import ProductHistory from '@/modules/product/classes/ProductHistory';
    import Product from '@/modules/product/classes/Product';
    export default {
        name: 'ProductOrders',
        components: { InformationBarContent, InformationBar, FilterComponent },
        data() {
            return {
                history: new ProductHistory(),
                product: new Product()
            }
        },
        created() {
        }
    };
</script>

<style scoped>

</style>