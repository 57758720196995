<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="section section__container">
                    <h2 class="page__title">Actiepunt toevoegen</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <select-with-search-component
                                :separator="false"
                                label="Ontvanger"
                                v-if="users"
                                objectToShow="email"
                                objectValue="email"
                                :danger_text="actionPoint.emails_danger_text"
                                :danger="actionPoint.emails_danger"
                                v-model="actionPoint.emails"
                                class-name="reciever"
                                :items="users"
                                autocomplete="off"
                                :disabled="disabled">
                            </select-with-search-component>
                        </div>
                        <div class="col-md-6">
                            <select-with-search-component
                                :separator="true"
                                label="Toezichthouders"
                                v-if="users"
                                objectToShow="email"
                                objectValue="email"
                                :danger_text="actionPoint.user_id_danger_text"
                                :danger="actionPoint.watchers_danger"
                                v-model="actionPoint.watchers"
                                class-name="watchers"
                                :items="users"
                                autocomplete="off"
                                :disabled="disabled">
                            </select-with-search-component>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="row">
                            <div class="col-md-6">
                                <input-component id="subject" v-model="actionPoint.subject" name="subject" no-trans="true" label="Onderwerp" class="" :danger="actionPoint.subject_danger" :danger_text="actionPoint.subject_danger_text"></input-component>
                            </div>
                            <div class="col-md-6">
                                <select-with-search-component
                                    :separator="true"
                                    label="Bedrijf"
                                    v-if="companies"
                                    objectToShow="label"
                                    objectValue="value"
                                    :danger_text="actionPoint.debtor_number_danger_text"
                                    :danger="actionPoint.debtor_number_danger_text"
                                    v-model="actionPoint.debtor_numbers"
                                    class-name="companies"
                                    :items="companies"
                                    autocomplete="off"
                                    :disabled="disabled"
                                    importData="true">
                                </select-with-search-component>

                            </div>
                        </div>

                        <input-component id="description" v-model="actionPoint.description" name="description" no-trans="true" label="Beschrijving" type="area" class="mt-5"></input-component>

                        <div class="mt-4">
                            <checkbox-component custom-id="openDatepickerCheckbox" v-model="withDate" label="Met verloopdatum"></checkbox-component>
                            <date-picker-component id="datePicker" v-if="withDate" no-trans="true" v-model="actionPoint.expires_at" name="expires_at" label="Dag tot verlopen actiepunt"></date-picker-component>
                        </div>
                        <div class="d-flex mt-4 justify-content-end">
                            <button class="button button-green-inverse" v-on:click="sendActionPoint">Verstuur actiepunt</button>
                        </div>

                        <!--<multi-select-search></multi-select-search>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectWithSearchComponent from '@/components/partials/SelectWithSearchComponent';
    //import MultiSelectSearch from '@/components/partials/MultiSelectSearch';
    import InputComponent from '@/components/partials/InputComponent';
    import ActionPoint from '@/modules/action-point/classes/ActionPoint';
    import User from "@/classes/User";
    import Company from "@/modules/debtor/classes/Company";
    import DatePickerComponent from '@/components/partials/DatePickerComponent';
    import CheckboxComponent from "@/components/partials/checkboxComponent";

    export default {
        name: 'SidebarModalActionPoint',
        components: {CheckboxComponent, DatePickerComponent, InputComponent, SelectWithSearchComponent },
        data() {
            return {
                actionPoint: new ActionPoint(),
                user: new User(),
                company: new Company(),
                watcher_danger: false,
                withDate: false,
                users: undefined,
                companies: undefined,
                selectedCompany: undefined,
                userlist: undefined,
                showDebtorField: true,
                disabled: false,
            }
        },
        created() {
            this.fetchUsers();
            this.actionPoint.debtor_numbers = this.$route.params.number;
            this.fetchCompanyList()
            if(this.actionPoint.debtor_number != undefined){
                this.showDebtorField = false;
            }
        },
        methods: {
            fetchUsers() {
                this.user.get("/permissions", [{'permission': "vrooam online" }]).then((response) => {
                    this.users = response.data;
                });
            },
            fetchCompanyList(){
                 this.company.get("/list", []).then((response) => {
                    this.companies = response;
                });
                
            },
            sendActionPoint() {
                this.actionPoint.user_id_danger = false;
                if (this.withDate) {
                    this.actionPoint.expires_at = this.actionPoint.expires_at.replaceAll('/', '-')
                    this.actionPoint.fillable = ['subject', 'description', 'emails', 'expires_at', 'debtor_numbers', 'watcher', 'watchers']
                }else {
                    this.actionPoint.fillable = ['subject', 'description', 'emails', 'debtor_numbers', 'watcher','watchers']
                }

                this.actionPoint.store(this.actionPoint).then(() => {
                    this.actionPoint.alert.createAlert('success', "Actiepunt is toegevoegd")
                    this.actionPoint.subject = "";
                    this.actionPoint.description = "";
                    this.$router.push({name:'actionIndex'})
                }).catch((err) => {
                    if (err !== undefined ) {
                        for(let error in err.data.errors) {
                            this.actionPoint[error + "_danger"] = true;
                            this.actionPoint[error + "_danger_text"] = err.data.errors[error][0];
                        }
                        this.actionPoint.user_id_danger = true;
                        this.actionPoint.user_id_danger_text = "is een verplicht veld";
                    }
                });
            },
        }
    };
</script>

<style scoped>

</style>