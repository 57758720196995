<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <order-history-bar></order-history-bar>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <open-orders></open-orders>
      </div>
      <div class="col-md-6">
        <pie-widget title="Jaaromzet in percentage artikelgroepen" :data="[33, 22, 80, 48, 38]" :headers="['DIV', 'AUTOMATERIALEN', 'BANDEN EN WIELEN', 'EQUIPMENT', 'KENTEKENPLATEN']"></pie-widget>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <column-widget :data="[24000, 8000, 17500]" :headers="['OFFLINE', 'MIJNGROSSIER', 'VROOAM SHOP']"></column-widget>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
</template>

<script>
import PieWidget from "@/components/widgets/PieWidget";
import ColumnWidget from "@/components/widgets/ColumnWidget";
import OpenOrders from "@/components/widgets/OpenOrders";
import OrderHistoryBar from "@/components/widgets/OrderHistoryBar";
export default {
  name: "DashboardYearly",
  components: {OrderHistoryBar, OpenOrders, ColumnWidget, PieWidget}
}
</script>

<style scoped>

</style>
