<template>
    <div v-if="!spinning" class="section__container my-4 py-4">   
        <div v-if="users.length > 0">  
            <div class="row m0 mb-3 border-bottom">
                <div class="col-md-3">
                    <p><strong>{{$i18n.t('general.email')}}</strong></p>
                </div>
                <div class="col-md-7">
                    <p><strong>{{$i18n.t('general.name')}}</strong></p>
                </div>
            </div>
            <div v-for="user in users" v-bind:key="user" class="row m0 py-1">
                <div class="col-md-3">
                    {{user.email}}
                </div>
                <div class="col-md-6">
                    {{user.person.first_name}}  {{user.person.last_name}}
                </div>
                <div class="col-md-3">
                    <button class="button button-green-inverse " v-on:click="loginAs(user.email)">Login als debiteur</button>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12 text-center">
                <div class="section section__container">
                    <h2 class="page__title">{{$i18n.t('general.no_results')}}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
         <loading-spinner-manual :spinner="spinning"></loading-spinner-manual>
    </div>
</template>
<script>
    import Debtor from "@/modules/debtor/classes/Debtor";
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

    export default {
        name: "DebitWebsite",
        components: {LoadingSpinnerManual},
        data() {
            return {
                debtor: new Debtor(),
                users: {},
                spinning: true,
            }
        },
        mounted() {
            this.websiteUsers();
        },
        methods: {
            websiteUsers: function(){
                this.debtor.all("/" + this.$route.params.number + "/" + 'users').then((response) => {
                    this.users = response.data;
                    this.spinning = false;
                });
            },
            loginAs: function(email){
                window.open(this.debtor.apiUrl + `/crm/user/login/as/${email}?access_token=${this.$store.state.generalStore.user.api_key}`, "_blank")
            },
        },
  
    }
</script>

<style scoped>

</style>
