<template>
    <div class="mb-4">
        <div class="row mt-4">
            <div class="col-12">
                <div class="section section__container">
                    <h2 class="page__title">Rapportage invoeren</h2>
                    <select-component name="layout" @selectedEvent="selectLayout" label="Type rapportage"  no-trans="true" :data="reportLayout.layoutsNamedList"></select-component>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <input-component no-trans="true" label="Doel van het bezoek" @changeEvent="goalChanged" ></input-component>
                            <date-picker-component no-trans="true" @selectedEvent="selectDate" label="Dag dat je bent langsgeweest" class="mt-4"></date-picker-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4" v-if="activeLayout.length > 0">
            <div class="col-12">
                <div class="section section__container">
                    <debit-report-layout-builder @saveReport="saveReport" :layouts="activeLayout"></debit-report-layout-builder>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import ReportLayout from '@/modules/report/classes/ReportLayout';
    import SelectComponent from '@/components/partials/SelectComponent';
    import DebitReportLayoutBuilder from '@/modules/report/components/DebitReportLayoutBuilder';
    import Report from '@/modules/report/classes/Report';
    import InputComponent from '@/components/partials/InputComponent';
    import DatePickerComponent from '@/components/partials/DatePickerComponent';

    export default {
        name: 'DebitReportStore',
        components: { DatePickerComponent, InputComponent, DebitReportLayoutBuilder, SelectComponent },
        data() {
            return {
                reportLayout: new ReportLayout(),
                report: new Report(this.$route.params.number),
                activeLayout: [],
                activeLayoutId: 0
            }
        },
        created() {
            this.fetchLayouts();
        },
        methods: {
            selectDate(date) {
                this.report.visited = date[1].format();
            },
            goalChanged(item) {
                this.report.goal = item[1];
            },
            saveReport(report) {
                this.report.report = JSON.stringify(report);
                this.report.store(this.report).then(() => {
                    this.$router.push({name: 'debitReport'});
                });
            },
            selectLayout(name) {
                this.reportLayout.layouts.forEach((layout) => {
                    if (layout.name === name[1]) {
                        this.activeLayout = layout.layout;
                        this.report.report_layout_id = layout.id;
                        this.report.report_layout = JSON.stringify(layout.layout);
                    }
                });
            },
            fetchLayouts() {
                this.reportLayout.all().then((response) => {
                    this.reportLayout.layouts = response.data;
                    this.reportLayout.layoutsParsed();
                });
            }
        }
    };
</script>

<style scoped>

</style>