<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-12">
                <sidebar-modal-action-point></sidebar-modal-action-point>
            </div>
        </div>
    </div>
</template>

<script>
    import SidebarModalActionPoint from '@/components/html/sidebarComponents/SidebarModalActionPoint';
    export default {
        name: 'ActionCreate',
        components: { SidebarModalActionPoint }
    };
</script>

<style scoped>

</style>