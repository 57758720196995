<template>
    <div v-if="!spinning && info" class="page__contents">     
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="section_bar">
                    <div class="section__container" v-if="info">
                        <div class="list m5">
                            <div class="row">
                                <div class="col-8">
                                    <h2 class="list__title">
                                        {{ $i18n.t('general.data') }}
                                    </h2>
                                </div>
                                <div class="col-4 text-right">
                                    <button class="button button-small button-green-inverse mt-2 mr-2"
                                            :class="{'button-green-inverse-active': $store.state.generalStore.enableEdit}"
                                            v-on:click="toggleEdit">{{ $i18n.t('general.mutate') }}
                                    </button>
                                </div>
                            </div>
                                <debit-details :debit="debit" :data="$store.state.debitStore.activeDebtor"></debit-details>
<!--                            <span class="list__separator"></span>-->
<!--                            <div v-for="(data, name, index) in $store.state.debitStore.activeDebtor" v-bind:key="name">-->
<!--                                <div class="list__item-container" v-if="name !== 'route'">-->
<!--                                    <div class="list__item row" v-if="name === 'debtor_number'">-->
<!--                                        <div class="list__item-header col-4 display-inline">{{ name }}</div>-->
<!--                                        <div class="list__item-answer col-8 display-inline">-->
<!--                                            <p class="ml-1">{{ data }}</p>-->

<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <debit-edit-component id="editComponent" :debit="debit" :name="name" :data="data"-->
<!--                                                          v-else></debit-edit-component>-->
<!--                                    <span class="list__separator"-->
<!--                                          :class="{'list__separator-deactive' : index === debtor.length-1}"></span>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mt-4">
                <div class="section__container">
                    <div class="my-0">
                        <action-point-debtor-index v-if="debtor && debtor.action_points" :actionPoints="debtor.action_points"></action-point-debtor-index>
                    </div>
                </div>
                <div class="section_bar">
                    <div class="section__container">
                        <div class="list m5">
                            <div class="row  px-1 m0">
                                <h2 class="list__title">
                                    Open posten
                                </h2>
                            </div>
                            <span class="list__separator"></span>
                            <debit-open-posts-component></debit-open-posts-component>
                        </div>
                    </div>
                    <div class="section__container">
                        <div class="my-3">
                            <rayon-manager-info v-if="debtor && debtor.rayon_manager" :data="debtor.rayon_manager"></rayon-manager-info>
                        </div>
                    </div>
                  
                    <div class="section__container">
                        <div class="my-3">
                            <eduction-info v-if="debtor && debtor.educations" :data="debtor.educations.training"></eduction-info>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
         <loading-spinner-manual :spinner="spinning"></loading-spinner-manual>
    </div>
</template>

<script>
    import Debtor from "@/modules/debtor/classes/Debtor";
    import Manager from '@/modules/manager/classes/Manager';
    import DebitDetails from "@/modules/debtor/components/DebitDetails";
    import RayonManagerInfo from "@/modules/debtor/components/RayonManagerInfo";
    import EductionInfo from "@/modules/debtor/components/EductionInfo";
    import DebitOpenPostsComponent from "@/modules/debtor/components/DebitOpenPostsComponent";
    import ActionPointDebtorIndex from "@/modules/debtor/components/actionpoint/ActionPointDebtorIndex";
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

    export default {
        name: "DebitInfo",
        components: {DebitOpenPostsComponent, DebitDetails, RayonManagerInfo, EductionInfo, ActionPointDebtorIndex, LoadingSpinnerManual},
        data() {
            return {
                debtor: this.$store.state.debitStore.activeDebtor,
                info: '',
                debit: new Debtor(),
                manager: new Manager(),
                paymentInfo: [],
                spinning: true,
            }
        },
        created() {
            this.info = [];
        },
        mounted() {
            if(this.$store.state.debitStore.activeDebtor){
                if(this.$store.state.debitStore.activeDebtor.debtor_number === this.$route.params.number){
                    this.setInfo();
                }
            }    
        },
        methods: {
            toggleEdit() {
                if (this.$store.state.generalStore.enableEdit) {
                    this.$store.commit("setEnableEdit", false);
                } else {
                    this.$store.commit("setEnableEdit", true);
                }
            },
            setInfo() {
                this.debtor = this.$store.state.debitStore.activeDebtor;
                // let dataParser = new DebtorDataParser(this.debtor);
                this.info = this.$store.state.debitStore.activeDebtor;
                this.spinning = false;
            }
        },
        watch: {
            '$store.state.debitStore.activeDebtor': function () {
                this.info = null;
                
                // Was aan het voordringen in de list om te updaten
                setTimeout(() => {
                    this.setInfo();
                }, 1)
               
            },

        }
    }
</script>

<style scoped>

</style>
