<template>
    <div>
        <modal-container :open-modal="openForm" @closeModalEvent="closeModal" type="small">
            <div class="row">
                <div class="col-md-12 pl-5 pr-5">
                    <h2 class="page__title">Informatie</h2>
                    <input-component class="mt-4" label="Functie"></input-component>
                    <input-component class="mt-4" label="Aanhef"></input-component>
                    <input-component class="mt-4" label="Voornaam"></input-component>
                    <input-component class="mt-4" label="Achternaam"></input-component>
                    <input-component class="mt-4" label="E-mailadres"></input-component>
                    <input-component class="mt-4" label="06-nummer"></input-component>
                    <div class="mt-4">
                        <checkbox-component></checkbox-component>
                        <p class="display-inline ml-2">Actief</p>
                    </div>
                    <span class="list__separator"></span>

                </div>

            </div>
            <div class="row">
                <div class="col-md-12 pl-5 pr-5">
                    <h2 class="page__title">Inschrijving nieuwsbrieven</h2>
                    <div>
                        <checkbox-component></checkbox-component>
                        <p class="display-inline ml-2">Koskamp voordeel</p>
                    </div>
                    <div>
                        <checkbox-component></checkbox-component>
                        <p class="display-inline ml-2">Koskamp opleidingen</p>
                    </div>
                    <div>
                        <checkbox-component></checkbox-component>
                        <p class="display-inline ml-2">Koskamp Informatie en Service</p>
                    </div>
                    <span class="list__separator"></span>
                    <button class="button button-green-inverse" v-on:click="openForm = !openForm">Opslaan</button>
                    <button class="button button-danger-inverse float-right mb-4" v-on:click="openForm = !openForm">Sluiten</button>

                </div>
            </div>
        </modal-container>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <button class="button button-green float-right mt-3" v-on:click="openModal">Contact toevoegen
                    </button>
                    <list-component title="Contacten" class="mt-2"></list-component>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <h2 class="page__title">Treffers</h2>
                    <div v-if="!treffers">
                        <p>Deze debiteur ontvangt nog geen treffers, middels onderstaande knop wordt er automatisch een
                            mail naar Marketing gestuurd met de aanvraag deze toe te voegen in SilliKos.</p>
                        <button class="button button-green-inverse">Verstuur aanvraag</button>
                    </div>
                    <div v-else>Klant ontvangt treffers</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ListComponent from "@/components/partials/Listcomponent";
    import ModalContainer from "@/components/html/ModalContainer";
    import InputComponent from "@/components/partials/InputComponent";
    import CheckboxComponent from "@/components/partials/checkboxComponent";

    export default {
        name: "DebitNewsLetter",
        components: {CheckboxComponent, InputComponent, ModalContainer, ListComponent},
        data() {
            return {
                treffers: false,
                contacts: [],
                openForm: false,
            }
        },
        methods: {
            openModal() {
                this.openForm = true
            },
            closeModal() {
                this.openForm = false
            }
        }
    }
</script>

<style scoped>

</style>