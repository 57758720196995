<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="section section__container">
                <list-component title="Opleidingen" :headers="['Naam', 'Neemt deel']"></list-component>
            </div>
        </div>

    </div>
</template>

<script>
    import ListComponent from "@/components/partials/Listcomponent";
    export default {
        name: "DebitEducation",
        components: {ListComponent}
    }
</script>

<style scoped>

</style>