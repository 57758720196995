import Filter from '@/classes/helper/Filter';
import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    filter = new Filter();
    id = undefined;
    fillable = [];
    constructor(id = undefined) {
        super('article');
        this.id = id;
    }
}