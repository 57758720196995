<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 60vh">
        <h2 class="page__title">
            {{  $i18n.t('general.construction') }}
        </h2>
    </div>
</template>

<script>
    export default {
        name: 'UnderConstruction'
    };
</script>

<style scoped>

</style>