<template>
    <div class="row mx-1 py-4 px-2 bordered-light" >
            <template v-for="(lay, index) in layout.layout" v-bind:key="lay">
                <div v-if="lay.type === 'heading'" :class="{'col-md-6' : lay.width === 6, 'col-md-12' : lay.width === undefined}" class="mb-2">
                    <placeholder-square :column="lay" :index-row="indexRow" :index-col="index" size="small"></placeholder-square>
                </div>
                <div v-if="lay.type === 'column'" class="col-md">
                    <placeholder-square :column="lay" :index-row="indexRow" :index-col="index"></placeholder-square>
                </div>
            </template>
            
    </div>
    <i class="fas fa-trash delete__icon" v-on:click="removeLayout"></i>
</template>

<script>
    import PlaceholderSquare from '@/plugins/DragDrop/layouts/PlaceholderSquare';
    export default {
        name: 'ColumnLayout',
        components: { PlaceholderSquare },
        props: ['layout', 'indexRow'],
        methods: {
            removeLayout() {
                this.$store.commit("removeLayout", this.indexRow);
            }
        },
        created() {
        },
    };
</script>

<style scoped>
.delete__icon {
    position: absolute;
    width: 0;
    right: 25px;
    top: 10px;
    z-index: 10;
}

</style>