<template>
    <div class="hidden" :class="{'show': show}">
        <div class="section section__container color-white alert__type-warning warning mt-4 mb-4" v-if="activeAlert">
            <i class="fas fa-times alert__remove-icon mr-2" v-on:click="removeAlert"></i>
            <p class="text-white font-large font-weight-bold" style="position: relative; top: 8px">
                {{ activeAlert.title }}
            </p>
            <p class="text-white font-large font-weight-bold" style="position: relative; top: 8px">
                {{ activeAlert.message }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AlertBarComponent",
        data() {
            return {
                alerts: [],
                usefulAlerts: [],
                activeAlert: undefined,
                show: false
            }
        },
        created() {
            setInterval(() => {
                this.fetchAlerts();
            }, 300);
        },
        methods: {
            removeAlert() {
                this.$store.commit('removeAlert', this.activeAlert);
                this.show = false;
                setTimeout(() => {
                    this.fetchAlerts();
                    this.setNewAlert();
                }, 300);
            },
            fetchAlerts() {
                this.alerts = this.$store.state.generalStore.alertBar;
                this.isAlert();
                this.setNewAlert();
            },
            setNewAlert() {
                let newAlertSet = false;
                this.show = false;
                this.usefulAlerts.forEach((alert) => {
                    if (!newAlertSet) {
                        if (!alert.shown) {
                            this.show = true;
                            this.activeAlert = alert;
                            newAlertSet = true;
                        }
                    }
                });
            },
            isAlert() {
                this.activeAlert = undefined;
                this.usefulAlerts = [];
                this.alerts.forEach((alert) => {
                    if (this.$route.path.indexOf(alert.route) !== -1) {
                        this.usefulAlerts.push(alert);
                    }
                });
                this.show = true;
            }
        },
    }
</script>

<style scoped>
.warning {
    width: 100%;
}

.hidden {
    display: block;
    height: 0;
    position: relative;
    opacity: 0;
    top: -110px;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.show {
    height: 110px;
    opacity: 1;
    top: 0;
}

.alert__remove-icon {
    float: right;
    color: white;
    font-size: 20px;
    cursor: pointer;
}
</style>