<template>
    <div class="">
        <div class="table__list">
            <div class="row">
                <div class="col-md-8">
                    <h2 class="page__title">{{ title }}</h2>
                </div>
                <div class="col-md-4">
                    <button v-if="button" class="button button-green button-half float-right mt-4" v-on:click="buttonClicked">{{ button }}</button>
                </div>
            </div>
            <table class="table" :class="{'text-right': align_right}">
                <thead>
                <tr>
                    <th scope="col" v-for="(header, index) in headers" v-bind:key="index" :class="{'list__money' : type==='money'}">
                        <p v-if="noTrans">
                            {{  header }}
                        </p>
                        <p v-else class="font-weight-bold mb-0">
                            {{ header }}
                        </p>
                    </th>
                </tr>
                </thead>
                <tbody v-if="data && data.length > 0" >
                <tr v-for="(order, index) in data" v-bind:key="index">
                    <th scope="col" v-for="(data, indexCol) in order" v-bind:key="indexCol" :class="{'list__money' : type==='money' && index !== 0, 'text-left': skip_first_align && indexCol === 0}">{{ data }}</th>
                    <th v-if="controls"  :class="data.id">
                        <slot name="controls"></slot>
                    </th>
                </tr>
                <tr  v-if="totals" class="">
                    <th scope="col" v-for="(data, index) in totals" v-bind:key="index" class="font-weight-bold" :class="{'list__money' : type==='money' && index !== 0}">{{ data }}</th>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <th>Er zijn nog geen {{ title }}</th>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ListComponent",
        props: ['title', 'headers', 'data', 'button', 'totals', 'type', 'align_right', 'skip_first_align', 'noTrans', 'controls'],
        emits: ['buttonClickEvent'],
        methods: {
            buttonClicked() {
                this.$emit('buttonClickEvent');
            },
            formatPricing(value) {
                console.log(value);
                return '';
                 //return Number(value).toLocaleString();
                //return Number(value).toLocaleString();
            }
           
        },
        created() {
        },
    }
</script>

<style scoped>
</style>
