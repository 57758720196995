<template>
    <div class="mb-5 section section__container mt-4">
        <div
             style="min-height: 300px; border-radius: 5px"
             @drop='onDrop($event)'
             @dragover.prevent
             @dragenter.prevent>
            <div v-for="(layout, index) in $store.state.dragDropStore.layout" v-bind:key="layout" class="mt-4" style="position:relative">
                <column-layout :index-row="index" :layout="layout"></column-layout>
            </div>
            <div class="drag__hidden" :class="{'drag__show': showEmptyLayout}">
                <div v-if="showEmptyLayout">
                    <div class="mt-4 hidden">
                        <div style="position:relative;">
                            <column-layout :layout="emptyLayout"></column-layout>
                            <p class="drag__placeholder-text">Drop hier om toe te voegen</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import ColumnLayout from '@/plugins/DragDrop/layouts/ColumnLayout';

    export default {
        name: 'DragDropArea',
        components: { ColumnLayout },
        data() {
            return {
                showEmptyLayout: false,
                emptyLayout: {}
            };
        },
        methods: {
            onDrop(evt) {
                const item = JSON.parse(evt.dataTransfer.getData('itemID'));
                if (item.type === 'layout') {
                    this.$store.commit('dragSetDragging', false);
                    this.$store.commit('dragAddLayout', item);
                    this.hideNewDropArea();
                }


            },
            hideNewDropArea() {
                this.emptyLayout = {};
                this.showEmptyLayout = false;
            },
            showNewDropArea(item) {
                this.emptyLayout = item;
                this.showEmptyLayout = true;
            }
        },
        watch: {
            '$store.state.dragDropStore.isDragging': function () {
                let item = this.$store.state.dragDropStore.itemDragging;
                if (item !== undefined && this.$store.state.dragDropStore.isDragging) {
                    if (item.type === 'layout') {
                        this.showNewDropArea(item);
                    }
                } else {
                    this.hideNewDropArea();

                }
            }
        }
    };
</script>

<style scoped>
.drag__hidden {
    opacity: 0;
    top: -10px;
    transition: opacity 0.2s ease-in-out, top 0.2s ease-in-out;
}

.drag__show {
    top: 0;
    opacity: 1;
}

.drag__placeholder-text {
    position: absolute;
    top: 35%;
    left: 38%;
    font-size: 28px;
    z-index: 20;
}
</style>