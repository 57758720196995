<template>
    <div>
        <span class="list__separator"></span>
        <detail-component :title="$i18n.t('general.product_number')" :data="data.article_number"></detail-component>
        <detail-component :title="$i18n.t('general.factory_code')" :data="data.factory_code"></detail-component>
        <detail-component :title="$i18n.t('general.alfa_code')" :data="data.alfacode"></detail-component>
        <detail-component :title="$i18n.t('general.description')" :data="data.description"></detail-component>
        <detail-component :title="$i18n.t('general.location')" :data="data.location"></detail-component>
        <detail-component :title="$i18n.t('general.ean_code')" :data="data.artean"></detail-component>
        <detail-component :title="$i18n.t('general.kind')" :data="data.kind"></detail-component>
        <detail-component :title="$i18n.t('general.brand')" :data="data.brand"></detail-component>
        <detail-component :title="$i18n.t('general.group')" :data="data.group"></detail-component>
        <detail-component :title="$i18n.t('general.product_text')" :data="data.article_text"></detail-component>
        <detail-component :title="$i18n.t('general.packing_unit')" :data="data.packing_unit"></detail-component>
        <detail-component :title="$i18n.t('order.unit')" :data="data.orderunit"></detail-component>
        <detail-component :title="$i18n.t('general.blockade')"></detail-component>
        <detail-component :title="$i18n.t('order.buy_price')" :data="'€ ' + data.purchase_price"></detail-component>
        <detail-component :title="$i18n.t('order.vvp_price')" :data="'€ ' + data.vvp_price"></detail-component>
        <detail-component :title="$i18n.t('general.price_gross')" :data="'€ ' + data.gross_price"></detail-component>
        <detail-component :title="$i18n.t('general.discount')" :data="data.discount + ' %'"></detail-component>
        <detail-component :title="$i18n.t('general.price_net')" :data="'€ ' + data.net_price"></detail-component>
        <detail-component :title="$i18n.t('general.price_garage')" :data="'€ ' + data.garagep_rice"></detail-component>
        <detail-component :title="$i18n.t('general.marketability')" :data="data.marketability"></detail-component>
        <detail-component :title="$i18n.t('general.deposit')"></detail-component>
        <detail-component :title="$i18n.t('general.group') + ' ' + $i18n.t('general.supplier')" :data="data.groupsupploer"></detail-component>
    </div>
</template>

<script>
    import DetailComponent from "@/components/partials/DetailComponent";

    export default {
        name: 'ProductDetails',
        components: {DetailComponent },
        props: ['data'],
        mounted() {
        }
    };
</script>

<style scoped>

</style>