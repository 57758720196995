<template>
    <div class="row mt-4">
        <div class="col-12">
            <h2 class="page__title">Open posten</h2>
            <loading-spinner></loading-spinner>
            <div>
                <filter-component :filter="credit.filter" :options="[1, 3]"></filter-component>
                <div v-for="(inv, index) in credit.filter.arrayFilter" v-bind:key="inv">
                    <information-bar :filter="credit.filter" :index="index" :object="inv" :no-fold="true">
                        <template v-slot:title>
                            <h2 class="debit__result__title">Factuur #{{ inv.invoicenumber }}</h2>
                        </template>
                        <template v-slot:shortcuts>
                        </template>
                        <template v-slot:content>
                            <div class="row">
                                <information-bar-content header="Factuurdatum" :text="inv.invoicedate"></information-bar-content>
                                <information-bar-content header="Order bedrag" :text="inv.invoiceamount"></information-bar-content>
                            </div>
                        </template>
                    </information-bar>
                </div>
                <div v-if="credit.filter.arrayFilter.length === 0">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="section section__container">
                                <h2 class="page__title">Er zijn geen open posten</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Credit from "@/modules/creditor/classes/Credit";
    import FilterComponent from "@/components/partials/FilterComponent";
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";

    export default {
        name: "CreditOpenPosts",
        components: {LoadingSpinner, InformationBarContent, InformationBar, FilterComponent},
        data() {
            return {
                credit: new Credit(),
                orders: undefined
            }
        },
        mounted() {
            this.credit.get("/"+this.$route.params.creditor + '/openposts').then((response) => {
                this.orders = response.data;
                this.credit.filter.setArrayFilter(this.orders);
            });
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>