<template>
    <div v-if="multiple">
        <div v-for="option in options" v-bind:key="option">
            <input type="checkbox" id="scales" :name="name" @change="checkedOption($event)" :checked="option.selected">
            <label>{{option.description}}</label>
        </div>
    </div>
    <div v-else>
        <label class="search__form__label search__form__label-active">{{name}} </label>
        <select class="search__form__input ml-0" @change="selectOption($event)">
            <option v-for="option in options" v-bind:key="option"  :selected="option.description == value" class="select__option" >
                {{option.description}}
            </option>
        </select>
    </div>
</template>
<script>
  import Api from "@/classes/helper/Api";
  export default {
        name: "SelectOptOptions",
        props: ['file_id', 'debtor', 'name', 'selectedValue'],
        components: {},
        data() {
            return {
                api: new Api(),
                options: [],
                value: undefined,
                selecteValue: undefined,
                multiple: false,
            }
        },
        created() {


           this.api.get("debtor/"+this.$props.debtor+"/extra", [{"file_id": this.$props.file_id}]).then((response) => {
                this.options = response.data[0].optie;
            });

            if(this.$props.selectedValue !== undefined){
                this.value = this.$props.selectedValue ;
                this.selected = this.$props.selectedValue ;
            }
        },
        watch: {
            modelValue(val) {
                this.value = val;
            },
            value(val) {
                this.$emit('changeEvent', [this.name, val]);
                this.$emit('update:modelValue', val);
            }
        },
        methods: {
            selectOption(event) {
                this.value = event.target.value;
                this.$emit('selectedEvent', [this.name, this.value]);
            },

            checkedOption(event) {
                this.value = event;
                //this.$emit('selectedEvent', [this.name, this.value]);
            },
        }
    }
</script>