<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <h2 class="page__title">Historie</h2>
            <loading-spinner></loading-spinner>
            <div>
                <filter-component :filter="credit.filter" :options="[1, 3]"></filter-component>
                <div v-for="(inv, index) in credit.filter.arrayFilter" v-bind:key="inv">
                    <information-bar :filter="credit.filter" :index="index" :object="inv">
                        <template v-slot:title>
                            <h2 class="debit__result__title">{{ inv.receptiondate }}</h2>
                        </template>
                        <template v-slot:content>
                            <div class="row">

                                <information-bar-content header="Ontvangstdatum" :text="inv.receptiondate"></information-bar-content>
                                <information-bar-content header="Order bedrag" :text="'€ ' + inv.orderprice"></information-bar-content>
                            </div>
                        </template>
                        <template v-slot:foldout>
                            <div class="row">

                                <information-bar-content header="Opdracht" :text="inv.ordernumber"></information-bar-content>
                                <information-bar-content header="Branch" :text="inv.branch"></information-bar-content>


                                <div class="col-md-12">
                                    <span class="list__separator"></span>
                                    <order-packing :data="inv.packing"></order-packing>
                                </div>
                            </div>
                        </template>
                    </information-bar>

                </div>
                <div v-if="credit.filter.arrayFilter.length === 0">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="section section__container">
                                <h2 class="page__title">Er is geen geschiedenis</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import FilterComponent from "@/components/partials/FilterComponent";
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    import Credit from "@/modules/creditor/classes/Credit";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    import OrderPacking from "@/modules/order/components/OrderPacking";

    export default {
        name: "CreditHistory",
        components: {OrderPacking, LoadingSpinner, InformationBarContent, InformationBar, FilterComponent},
        data() {
            return {
                headers: [],
                tableData: [],
                credit: new Credit(),
                orders: undefined
            }
        },
        mounted() {
            this.credit.filter.useScroll = false;
            this.getOrders();
        },
        methods: {
            getOrders() {
                this.credit.get("/"+this.$route.params.creditor + '/history').then((response) => {
                    this.credit.filter.setArrayFilter(response.data);
                    this.orders = response.data;
                });
            }
        },
        watch: {
            '$store.state.hotkeyStore.keyArrowDown': function () {
                if (this.$store.state.hotkeyStore.keyArrowDown) {
                    this.credit.filter.increaseSelected()
                }
            },
            '$store.state.hotkeyStore.keyArrowUp': function () {
                if (this.$store.state.hotkeyStore.keyArrowUp) {
                    this.credit.filter.decreaseSelected()
                }
            },
        }
    }
</script>

<style scoped>

</style>
