import Standard from '@/pages/layout/Standard';
import i18n from '@/i18n';
import ActionCreate from '@/modules/action-point/pages/ActionCreate';
import ActionPointHeader from '@/modules/action-point/components/ActionPointHeader';
import ActionIndex from '@/modules/action-point/pages/ActionIndex';

export default [
    {
        component: Standard,
        meta: {
            routes: "action-point/actionPointRoutes",
        },
        children: [
            {
                path: "/action-point",
                components: {
                    header: ActionPointHeader,
                    default: ActionIndex
                },
                name: "actionIndex",
                label: i18n.t("general.action-point_info")
            },
            {
                path: "/action-point/create",
                components: {
                    header: ActionPointHeader,
                    default: ActionCreate
                },
                name: "actionCreate",
                label: i18n.t("general.action-point_create")
            },
        ]
    }
];