<template>
    <div>
        <span class="list__separator"></span>
        <detail-component :title="$i18n.t('general.stock')" :data="parseInt(data.stockdepot) + parseInt(data.stockdepot2)"></detail-component>
        <detail-component :title="$i18n.t('general.stock_min')" :data="data.amountminstock"></detail-component>
        <detail-component :title="$i18n.t('general.stock_max')" :data="data.amountmaxstock"></detail-component>
        <detail-component :title="$i18n.t('general.stock_depot')" :data="data.stockdepot"></detail-component>
        <detail-component :title="$i18n.t('general.stock_depot') + '2'" :data="data.stockdepot2"></detail-component>
        <detail-component :title="$i18n.t('order.amount')" :data="data.amountordered"></detail-component>
        <detail-component :title="$i18n.t('order.backorder')" :data="data.backorder"></detail-component>
        <detail-component :title="$i18n.t('order.reserved')" :data="data.reserved"></detail-component>
        <detail-component :title="$i18n.t('order.amount_sold')" :data="data.omz12"></detail-component>

    </div>
</template>

<script>
    import DetailComponent from "@/components/partials/DetailComponent";
    export default {
        name: 'ProductStocks',
        components: {DetailComponent },
        props: ['data']
    };
</script>

<style scoped>

</style>