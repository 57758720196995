<template>
  <div>
    <router-view v-slot="slotProps">
      <sidebar v-if="$route.name !== 'login'"></sidebar>
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>

</template>

<script>
import Sidebar from "@/components/html/Sidebar";
export default {
  name: 'App',
  components: {Sidebar},
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

$image-path: '~@/../mdb/mdbvue/img';
@import '~@/../mdb/mdbvue/scss/mdb-free.scss';

</style>
