<template>
    <div v-if="$store.state.creditStore.activeCreditor">
        <breadcrumbs-bar
            :links="[{name: 'Zoeken', url:'creditSearch'},{name: 'Info', url: 'creditInfo', params: {'creditor': $store.state.creditStore.activeCreditor.creditornumber }}]"></breadcrumbs-bar>

        <div>
            <h2 class="page__title debit__result-small__title display-inline">{{ $store.state.creditStore.activeCreditor.naam
                }}</h2>
            <p class="debit__result-small display-inline">- Crediteur
                #{{ $store.state.creditStore.activeCreditor.creditornumber }}</p>
        </div>

    </div>
</template>

<script>
    import Credit from "@/modules/creditor/classes/Credit";
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";

    export default {
        name: "CreditHeader",
        components: {BreadcrumbsBar},
        data() {
            return {
                credit: new Credit()
            }
        },
        created() {
            this.credit.id = this.$route.params.creditor;
            this.credit.show(this.credit).then((response) => {
                this.$store.commit("setActiveCreditor", response.data);
            });
        }
    }
</script>

<style scoped>

</style>