<template>
    <div>
        <div class="section section__container row mt-4">
            <div class="col-6">
                <h2 class="page__title">Rapport sjabloon aanmaken</h2>
            </div>
            <div class="col-6">
                <select-component no-trans="true" label="Layout selecteren om bij te werken"
                                  @selectedEvent="selectLayout" :data="reportLayout.layoutsNamedList"></select-component>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <div class="row">
                        <div class="col-md-8">
                            <div>
                                <input-component :danger_text="name_danger_text" :danger="name_danger"
                                                 v-model="name" name="name" no-trans="true"
                                                 label="Rapport naam"></input-component>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button class="button button-danger-inverse" v-on:click="emptyLayout">Leegmaken</button>
                            <button class="float-right button button-green-inverse" v-on:click="storeReportLayout" v-if="edit">Bijwerken</button>
                            <button class="float-right button button-green-inverse" v-on:click="storeReportLayout" v-else>Toevoegen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <drag-drop-area></drag-drop-area>
            </div>
            <div class="col-2 mt-4">
                <drag-sidebar :creator="debitReportCreator"></drag-sidebar>
            </div>
        </div>
    </div>
</template>

<script>
    import DragSidebar from "@/plugins/DragDrop/DragSidebar";
    import debitReportCreator from "@/modules/report/DebitReportCreator.json";
    import DragDropArea from '@/plugins/DragDrop/DragDropArea';
    import ReportLayout from '@/modules/report/classes/ReportLayout';
    import InputComponent from '@/components/partials/InputComponent';
    import Stats from "@/modules/debtor/classes/Stats";
    import Debtor from '@/modules/debtor/classes/Debtor';
    import SelectComponent from "@/components/partials/SelectComponent";

    export default {
        name: "ReportLayoutCreate",
        components: {SelectComponent, InputComponent, DragDropArea, DragSidebar},
        data() {
            return {
                debitReportCreator: debitReportCreator,
                options: [],
                reportLayout: new ReportLayout(),
                name: "",
                name_danger: false,
                name_danger_text: "",
                stats: new Stats("0102134"),
                debtor: new Debtor(),
                edit: false,
                editId: undefined
            }
        },
        mounted() {
            this.fetchLayouts();
            this.options = this.debitReportCreator.layout;

            this.debtor.id = "0102134";
            this.debtor.show(this.debtor).then((response) => {
                this.$store.commit("setActiveDebtor", response.data.debtor);
            });

            this.fetchStats();
        },
        methods: {
            fetchLayouts(){
                this.reportLayout.all().then((response) => {
                    this.reportLayout.layouts = response.data;
                    this.reportLayout.layoutsParsed();
                });
            },
            selectLayout(name) {
                this.edit = true;
                this.reportLayout.layouts.forEach((layout) => {
                    if (layout.name === name[1]) {
                        this.name = layout.name;
                        this.editId = layout.id;
                        this.$store.commit('setDragLayout', layout.layout);
                    }
                });

            },
            fetchStats() {
                if (this.stats.id !== "") {
                    this.stats.all().then((response) => {
                        this.stats.parseStats(response.data);
                        response.data['elements'] = this.stats.elements;
                        this.$store.commit("setStats", response.data);
                    });
                }

            },
            emptyLayout() {
                this.name = "";
                this.edit = false;
                this.reportLayout.id = undefined;
                this.$store.commit("resetLayout");
                this.reportLayout.layoutsNamedList = [];
                this.reportLayout.alert.createAlert('success', 'Rapport leeggemaakt');
            },
            storeReportLayout() {
                this.name_danger = false;
                this.reportLayout.name = this.name;
                
                this.reportLayout.layout = JSON.stringify(this.$store.state.dragDropStore.layout);
                this.reportLayout.type = 0;

                if (this.edit) {
                    this.reportLayout.id = this.editId;
                    this.reportLayout.update(this.reportLayout).then(() => {
                        this.reportLayout.alert.createAlert('success', 'Rapport layout toegevoegd');
                        let name = this.name; 
                        this.emptyLayout();
                        this.fetchLayouts();
                        this.selectLayout(['undefined',name]);
                    }).catch(() => {
                        this.name_danger = true;
                        this.name_danger_text = "De naam is verplicht";
                    }); 
               }
               else {
                     this.reportLayout.store(this.reportLayout).then(() => {
                        this.reportLayout.alert.createAlert('success', 'Rapport layout toegevoegd');
                        this.emptyLayout();
                        this.fetchLayouts();
                    }).catch(() => {
                        this.name_danger = true;
                        this.name_danger_text = "De naam is verplicht";
                    });
                
               }
            }
        }
    }
</script>

<style scoped>

</style>