<template>
    <div>

        <div class="dashboard__content-min" :class="{'dashboard__content-large': sidebar}">
            <div class="container-fluid dashboard__temp">

                <router-view v-slot="slotProps">
                    <transition name="route" mode="out-in">
                        <component :is="slotProps.Component"></component>
                    </transition>
                </router-view>

            </div>
        </div>

    </div>
</template>

<script>
    import Sidebar from "@/components/html/Sidebar";
    import Hotkey from "@/classes/helper/Hotkey";

    export default {
        name: "Page",
        components: {Sidebar},
        data() {
            return {
                sidebar: false
            }
        },
        mounted() {
            new Hotkey();
        },
        watch: {
            '$store.state.helperStore.sidebar': function () {
                this.sidebar = this.$store.state.helperStore.sidebar;
            }
        }
    }
</script>

<style scoped>
.content {
    display: inline-block;
    float: right;
    background-color: #f0f0f0;
    min-width: calc(100% - 250px);
    min-height: 100vh;
}
</style>
