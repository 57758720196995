import CreditSearch from '@/modules/creditor/pages/CreditSearch';
import Standard from '@/pages/layout/Standard';
import CreditInfo from '@/modules/creditor/pages/CreditInfo';
import StandardBar from '@/pages/layout/StandardBar';
import CreditHeader from '@/modules/creditor/components/CreditHeader';
import CreditOpenPosts from '@/modules/creditor/components/CreditOpenPosts';
import CreditHistory from '@/modules/creditor/pages/CreditHistory';

export default [
    {
        component: Standard,
        name: "credit",
        meta: {
            routes: "creditor/creditRoutes",
        },
        children: [
            {
                path: '/credit/:creditor/info',
                components: {
                    bar: StandardBar,
                    header: CreditHeader,
                    default: CreditInfo,
                },
                name: 'creditInfo',
                label: "Crediteur info"
            },
            // {
            //     path: '/credit/:creditor/orders',
            //     components: {
            //         bar: StandardBar,
            //         header: CreditHeader,
            //         default: CreditOrders,
            //     },
            //     name: 'creditOrders',
            //     label: "Lopende orders"
            // },
            {
                path: '/credit/:creditor/open-posts',
                components: {
                    bar: StandardBar,
                    header: CreditHeader,
                    default: CreditOpenPosts,
                },
                name: 'creditPosts',
                label: "Open posten"
            },
            {
                path: '/credit/:creditor/history',
                components: {
                    bar: StandardBar,
                    header: CreditHeader,
                    default: CreditHistory,
                },
                name: 'creditHistory',
                label: "Historie"
            }
        ]

    },
    {
        component: Standard,
        name: "creditSearchPage",
        meta: {
            routes: "creditor/creditRoutes",
        },
        children: [
            {
                path: '/credit',
                components: {
                    default: CreditSearch
                },
                name: 'creditSearch'
            }
        ]
    },

]