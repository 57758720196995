<template>
    <div class="mb-4 page__contents">
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="section_bar">
                    <div class="section__container" v-if="info">
                        <div class="list m5">
                            <h2 class="list__title">
                                {{  $i18n.t('general.data') }}
                            </h2>
                            <product-details :data="info"></product-details>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="section_bar">
                    <div class="section__container" v-if="info">
                        <div class="list m5">
                            <h2 class="list__title">
                                {{ $i18n.t('general.stock') }}
                            </h2>
                            <product-stocks :data="stock"></product-stocks>
                        </div>
                    </div>
                </div>
                <div class="section_bar mt-4">
                    <div class="section__container" v-if="info">
                        <div class="list m5">
                            <h2 class="list__title">
                                {{ $i18n.t('creditor.creditor') }}
                            </h2>
                            <product-creditor-list :data="info"></product-creditor-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductDetails from '@/modules/product/components/ProductDetails';
    import ProductStocks from '@/modules/product/components/ProductStocks';
    import ProductCreditorList from '@/modules/product/components/ProductCreditorList';

    export default {
        name: 'ProductInfo',
        components: { ProductCreditorList, ProductStocks, ProductDetails },
        data() {
            return {
                info: undefined,
                stock: [],
                creditor: []

            };
        },
        mounted() {
            this.info = this.$store.state.productStore.activeProduct
        },
        watch: {
            '$store.state.productStore.activeProduct': function() {
                this.info = this.$store.state.productStore.activeProduct

            },
        }
    };
</script>

<style scoped>

</style>