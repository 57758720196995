import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import router from "@/router";
import "./theme/main.scss";
import i18n from "@/i18n";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueApexCharts from "vue3-apexcharts";
createApp(App)
    .use(VueApexCharts)
    .use(VueAxios, axios)
    .use(i18n)
    .use(store)
    .use(router)
    .mount('#app')
