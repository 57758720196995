<template>
    <div>
        <div class="section section__container">
            <h2 class="page__title">Kansen</h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ChancesWidget"
    }
</script>

<style scoped>

</style>