<template>
    <div class="">
        <div class="table__list">
            <div class="row">
                <div class="col-md-8">
                    <slot name="title"></slot>
                </div>
                <div class="col-md-4">
                    <div class="float-right mt-1 mr-1">
                        <slot name="button"></slot>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <slot name="header">

                        </slot>
                    </tr>
                </thead>
                
                <slot name="data">

                </slot>
            </table>
        </div>
    </div>
</template>

<script>
export default {
name: "TableComponent",
    props: ['data']
}
</script>

<style scoped>

</style>