<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <debit-report-list :data="report.reportsParsed"></debit-report-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DebitReportList from '@/modules/report/components/DebitReportList';
    import Report from '@/modules/report/classes/Report';
    export default {
        name: 'ReportIndex',
        components: { DebitReportList },
        data() {
            return {
                report: new Report(this.$store.state.settingsStore.reportSettings.defaultDebit)
            }
        },
        created() {
            this.fetchReports();
            this.$store.commit('setActiveReportDebtor', undefined);
        },
        methods: {
            fetchReports() {
                this.report.all().then((response) => {
                    this.report.reports = response.data;
                    this.report.parseReports();
                });
            }
        }
    };
</script>

<style scoped>

</style>