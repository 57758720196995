<template>
  <div class="row mt-4">
    <div class="col-12">
      <list :headers="headers" :data="tableData" title="History orders" button="Verversen" @buttonEvent="getOrders"></list>
    </div>
  </div>
</template>

<script>
import List from "@/components/widgets/ListWidget";
export default {
  name: "ProductHistory",
  components: {List},
  data () {
    return {
      headers: [],
      tableData: []
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {

    }
  }
}
</script>

<style scoped>

</style>
