<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="section section__container">
                <h1 class="page__title">User Logs</h1>
            </div>
        </div>
    </div>
</template>

<script>
    import User from "@/classes/User";

    export default {
        name: "SettingsUserLog",
        data() {
            return {
                user: new User()
            }
        },
        created() {
            this.fetchUserLogs();
        },
        methods: {
            fetchUserLogs() {

            }
        }
    }
</script>

<style scoped>

</style>