<template>
    <div class="placeholder" :class="{ 'placeholder__small': size === 'small', 'placeholder__filled': column.layout, 'placeholder__none' : column.layout}"
         @drop='onDrop($event)'
         @dragover.prevent
         @dragenter.prevent
         style="width: 100%"
    >
        <drag-elements-builder :indexCol="indexCol" :indexRow="indexRow" :elements="column"></drag-elements-builder>
        <p class="drag__placeholder-text" :class="{ 'drag__placeholder-text__small': size === 'small'}" v-if="showText && !column.layout">Drop hier om toe te voegen</p>
    </div>
</template>

<script>
    import DragElementsBuilder from '@/plugins/DragDrop/elements/DragElementsBuilder';
    export default {
        name: 'PlaceholderSquare',
        components: { DragElementsBuilder },
        props: ['size', 'column', 'indexCol', 'indexRow'],
        data() {
            return {
                showText: false
            }
        },
        methods: {
            onDrop(evt) {
                const item = JSON.parse(evt.dataTransfer.getData('itemID'));
                if (item.type === 'content' && !this.column.layout) {
                    this.$store.commit('dragAddContent', [this.indexRow, this.indexCol, item]);

                }
            },
            showNewDropArea() {
                this.showText = true;
            },
            hideNewDropArea() {
                this.showText = false;
            }
        },
        watch: {
            '$store.state.dragDropStore.itemDragging': function() {
                let item = this.$store.state.dragDropStore.itemDragging;
                if (item !== undefined && this.$store.state.dragDropStore.isDragging) {
                    if (item.type === 'content') {
                        this.showNewDropArea();
                    }
                }else {
                    this.hideNewDropArea();
                }
            }
        }
    };
</script>

<style scoped>
.placeholder {
    width: 100%;
    min-height: 300px;
    border-radius: 5px;
    border: 1px solid grey;
}
.placeholder__filled {
    border: none;
}
.placeholder__small {
    min-height: 100px;
}

.placeholder__none {
    min-height: 0;
}

.drag__placeholder-text {
    text-align: center;
    font-size: 24px;
    z-index: 20;
    position: relative;
    line-height: 300px;
    width: 100%;
    margin: 0;
}

.drag__placeholder-text__small {
    line-height: 100px;
}
</style>