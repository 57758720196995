<template>
    <div>
        <div v-if="!spinning">
            <div v-for="(layout, indexRow) in storedLayoutCheck()" v-bind:key="layout" :class="{'row': layout.label !== 'Kolom met heading'}">
                <div class="mt-3" v-for="(column, indexCol) in layout.layout" v-bind:key="column"
                     :class="{'row': layout.label=== 'Kolom met heading', 'col-md': layout.label !== 'Kolom met heading'}">
                    <div :class="{ 'col-md': layout.label=== 'Kolom met heading'}">
                        <div v-if="column.layout[0].component === 'label'">
                            <h2 class="">{{ column.layout[0].value }}</h2>
                        </div>
                        <div v-if="column.layout[0].component === 'input'">
                            <div v-if="activeReport && activeReport.fields && getFieldValue(column.layout[0].value)">
                                <input-component :initial-value="getFieldValue(column.layout[0].value).value" v-if="column.layout[0].capital === 'large'" type="area" @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value" no-trans="true"></input-component>
                                <input-component :initial-value="getFieldValue(column.layout[0].value).value" v-else @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value"  no-trans="true"></input-component>
                            </div>
                            <div v-else>
                                <input-component v-if="column.layout[0].capital === 'large'" type="area" @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value"  no-trans="true"></input-component>
                                <input-component v-else @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value"  no-trans="true"></input-component>
                            </div>
                        </div>
                        <div v-else-if="column.layout[0].component === 'info-stats' && Object.keys(statistics).length > 0">
                            <drag-info-stats-component v-if="statistics['omzet'][date.getFullYear()]['total'][column.layout[0].value] != undefined" :now="statistics['omzet'][date.getFullYear()]['total'][column.layout[0].value]" :last="statistics['omzet'][date.getFullYear()-1]['total'][column.layout[0].value]"
                            :prognosis="statistics['prognose'][date.getFullYear()]['total'][column.layout[0].value]">
                            </drag-info-stats-component>
                            
                        </div>
                        <div v-else-if="column.layout[0].component === 'info-debit'">
                            <drag-info-component v-if="debtor" :label="column.layout[0].label" :value="debtor[column.layout[0].value]"></drag-info-component>
                        </div>

                        <div v-else-if="column.layout[0].component === 'info-actionpoint' && debtor != undefined">
                            <div v-if="activeReport">
                                <h3>Actionpoints</h3>
                                <div class="row mt-3" v-for="(actionpoint) in activeReport.actionpoints" v-bind:key="actionpoint">
                                    <div class="col-3">
                                        <p>Onderwerp:</p>
                                        <p>Omschrijving:</p>
                                        <p>Toegewezen:</p>
                                    </div>
                                    <div class="col-9">
                                        <p>{{actionpoint.subject}}</p>
                                        <p>{{actionpoint.description}}</p>
                                        <p>{{actionpoint.user.first_name}} {{actionpoint.user.last_name}}</p>
                                    </div>
                                    <hr>
                                </div>
                            </div>      
                            <div v-else>
                                <h2>{{$i18n.t('actionpoint.title')}}</h2>
                                <div v-for="(actionpoint) in actionpoints" v-bind:key="actionpoint" class="mt-2">
                                    <action-point-form :users="users" :index="actionpoint.index" @actionUpdate="updateAction($event,actionpoint.index)" @actionRemove="removeField($event)"></action-point-form>
                                </div>
                                <div class="button button-green float-right mt-1" v-on:click="addField()"><i class="fas fa-plus-circle"/> {{$i18n.t('actionpoint.add')}}</div>
                            </div>
                        </div>

                        <div v-else-if="column.layout[0].component === 'info-educations' && debtor != undefined && debtor.educations != undefined">
                            <div v-for="education in debtor.educations.training" v-bind:key='education'>
                                <div class="row" v-if="education.description">
                                    <p class="col-3">{{education.description}}:</p>
                                    <p class="col-9">{{education.takes_part}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="column.layout[0].component === 'info-part-concept' && debtor != undefined">
                            <h2>{{$i18n.t('debtor.sub-concept')}}</h2>
                            <div v-for='subconcept in debtor.subconcept.optie' v-bind:key='subconcept'>
                                <div v-if="subconcept.selected">
                                    <p>{{subconcept.description}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="column.layout[0].component === 'opt-selection' && debtor != undefined">
                           
                            <select-opt-options :name="column.layout[0].value" :selectedValue='getFieldValue(column.layout[0].value).value' :file_id="column.layout[0].file_id" :debtor="debtor.debtor_number" @selectedEvent="changeValue($event, indexRow, indexCol)"></select-opt-options>
                            
                        </div>
                         <div v-else-if="column.layout[0].component === 'input-debtor' && debtor != undefined">
                             <div v-if="activeReport && activeReport.fields && getFieldValue(column.layout[0].value)">
                                <input-component :initial-value="getFieldValue(column.layout[0].value).value" v-if="column.layout[0].capital === 'large'" type="area" @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value" no-trans="true"></input-component>
                                <input-component :initial-value="getFieldValue(column.layout[0].value).value" v-else @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value"  no-trans="true"></input-component>
                            </div>
                            <div v-else>
                                <input-component v-if="column.layout[0].capital === 'large'" type="area" @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].value" :name="column.layout[0].value"  no-trans="true"></input-component>
                                <input-component v-else @changeEvent="changeValue($event, indexRow, indexCol)" :label="column.layout[0].label" :name="column.layout[0].value" no-trans="true" :initialValue="getDebtorPropery(column.layout[0].value)" ></input-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div v-if="edit != '' && edit !== 'false'" class="col-md-12">
                    <button class="button button-green-inverse float-right" v-on:click="saveReport">Opslaan</button>
                </div>
            </div>
        </div>
        <div v-else>
            <loading-spinner-manual :spinner="spinning"></loading-spinner-manual>
        </div>  
    </div>
</template>
<script>
    import ActionPointForm from "@/modules/report/components/ActionpointForm";
    import Debtor from "@/modules/debtor/classes/Debtor";
    //import Stats from "@/modules/debtor/classes/Stats";
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";
    import InputComponent from "@/components/partials/InputComponent";
    import SelectOptOptions from "@/components/partials/SelectOptOptions";
    import User from "@/classes/User";
    // import SelectWithSearchComponent from "@/components/partials/SelectWithSearchComponent";
    import DragInfoComponent from "@/plugins/DragDrop/components/DragInfoComponent";
    import DragInfoStatsComponent from "@/plugins/DragDrop/components/DragInfoStatsComponent";

    export default {
        name: 'DebitReportLayoutBuilder',
        components: {DragInfoStatsComponent,DragInfoComponent,  InputComponent, ActionPointForm, LoadingSpinnerManual, SelectOptOptions},
        props: ['layouts', 'activeReport', 'debtor', 'edit'],
        emits: ['saveReport'],
        data() {
            return {
                report: {},
                user: new User(),
                users: [],
                debtorObject: new Debtor(),
                statistics: {},
                date: new Date(),
                actionpoints: [],
                actionIndex: 0,
                spinning: true,
            }
        },
        mounted() {
            this.fetchUsers();
            if(this.debtor !== undefined && this.debtor !== null)
            {
                this.statistics = this.debtor.stats;
                this.spinning = false;
            }
        },
        watch: {
            debtor(value) {
                if(value != undefined){
                    this.spinning = false;
                }
            }
        },
        methods: {
            getDebtorPropery(property)
            {  
                if(this.debtor !== undefined){
                    if(this.debtor[property] != null && this.debtor[property] != undefined){
                        return this.debtor[property];
                    }
                }

                return '';
            },
            fetchUsers() {
                this.user.get("/permissions", [{'permission': "vrooam online" }]).then((response) => {
                    this.users = response.data;
                });
            },
            addField()
            {
                this.actionpoints.push({'actionpoint': '','index': this.actionIndex });
                this.actionIndex++;
            },
            isJson(value)
            {
                if(Object.keys(value).length > 0 ){
                    return true;
                }

                return false;
            },
            removeField(index)
            {
                let actionpointIndex = null;
                for(let key in this.actionpoints){
                    if(this.actionpoints[key].index === index)
                    {
                        actionpointIndex = key;
                    }
                }

                if(actionpointIndex != null){
                    this.actionpoints.splice(actionpointIndex, 1);
                }
                
            },
            changeValue(value, indexRow, indexCol) {
                if (this.report[value[0]] === undefined) {
                    this.report[value[0]] = {}
                }
            
                this.report[value[0]].value = value[1];
                this.report[value[0]].row = indexRow;
                this.report[value[0]].col = indexCol;
            },
            saveReport() {
                if(this.actionpoints){
                    for(let key in this.actionpoints){
                        if( this.report['actionpoints'] === 'undefined' || this.report['actionpoints'] === undefined ){
                             this.report.actionpoints = [];
                        }
                        this.report.actionpoints.push(this.actionpoints[key].actionpoint);
                    }
                }
                this.$emit('saveReport', {'report':this.report, 'debtor': this.$props.debtor.debtor_number});
                this.report.actionpoints = [];
            },
            storedLayoutCheck(){
                if(this.$props.activeReport){
                    return this.$props.activeReport.report_layout;
                }
                return this.$props.layouts
            },
            getFieldValue(name){
                let matchfield = {};
                if(this.$props.activeReport){
                    if(this.$props.activeReport.fields){
                        this.$props.activeReport.fields.forEach((field) => {
                            if(field.name === name){
                                matchfield =  field;
                            }
                        });
                    }
                }
                return matchfield;         
            },
            updateAction(data){  
                let property = '';
                for(let key in this.actionpoints){
                    if(this.actionpoints[key].index == data.index){
                         property = key;
                    }
                }
                if(property != null){
                    this.actionpoints[property].actionpoint = data;
                }
            },
        }
    };
</script>

<style scoped>

</style>