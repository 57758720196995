import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    constructor(debtorNumber = "", route_affix = "") {
        route_affix = "/irel-report" + route_affix;
        if (debtorNumber !== "") {
            super( "debtor" + "/" + debtorNumber + route_affix)
        } 
        else {
            super("debtor" + "/" + debtorNumber + route_affix);
        }
    }
}