import Api from '@/classes/helper/Api';
import Alert from '@/classes/helper/Alert';
import Filter from '@/modules/debtor/classes/helper/Filter';
import Hotkey from '@/classes/helper/Hotkey';
import Validator from '@/classes/helper/Validator';
import LocalSave from '@/classes/helper/LocalSave';

export default class extends Api {
    fields = [];
    alert = new Alert();
    filter = new Filter();
    hotkey = new Hotkey();
    Validator = new Validator();
    localSave = new LocalSave();
    constructor(route_prefix = "") {
        super(route_prefix);
    }

    setData(data) {
        this.fields.forEach((field) => {
            if (data[field] !== undefined) {
                this[field] = data[field];
            }
        });
    }

}