import i18n from '@/i18n';
import Page from '@/pages/layout/Page';
import ProductSearch from '@/modules/product/pages/ProductSearch';
import ProductInfo from '@/modules/product/pages/ProductInfo';
import Standard from '@/pages/layout/Standard';
import StandardBar from '@/pages/layout/StandardBar';
import ProductHeader from '@/modules/product/components/ProductHeader';
import ProductStock from '@/modules/product/pages/ProductStock';
import ProductEquivalent from '@/modules/product/pages/ProductEquivalent';
import ProductStats from '@/modules/product/pages/ProductStats';

export default [
    {
        component: Standard,
        meta: {
            routes: "product/productRoutes",
        },
        children: [
            {
                path: "/product/:product/info",
                components: {
                    header: ProductHeader,
                    bar: StandardBar,
                    default: ProductInfo
                },
                name: "productInfo",
                label: i18n.t("general.product_info")
            },
            {
                path: "/product/:product/stock",
                components: {
                    header: ProductHeader,
                    bar: StandardBar,
                    default: ProductStock
                },
                name: "productStock",
                label: i18n.t("general.stock")
            },
            {
                path: "/product/:product/equivalent",
                components: {
                    header: ProductHeader,
                    bar: StandardBar,
                    default: ProductEquivalent
                },
                name: "productEquivalent",
                label: i18n.t("general.equivalents")
            },
            // {
            //     path: "/product/:product/orders",
            //     components: {
            //         header: ProductHeader,
            //         bar: StandardBar,
            //         default: ProductOrders
            //     },
            //     name: "productOrders",
            //     label: i18n.t("product_bar.orders")
            // },
            {
                path: "/product/:product/stats",
                components: {
                    header: ProductHeader,
                    bar: StandardBar,
                    default: ProductStats
                },
                name: "productStats",
                label: i18n.t("general.sell_stats")
            },
        ]
    },
    {
        name: 'productPage',
        component: Page,
        children: [
            {
                path: "/product/search",
                component: ProductSearch,
                name: "productSearch",
                label: i18n.t("product_bar.order_search")
            }
        ],
    }
    // {
    //     name: 'product',
    //     component: Product,
    //     path: '/product/:product',
    //     children: [
    //         {
    //             path: "stock",
    //             component: ProductStock,
    //             name: "productStock",
    //             label: i18n.t("product_bar.stock")
    //         },
    //         {
    //             path: "statistics",
    //             component: ProductStats,
    //             name: "productStats",
    //             label: i18n.t("product_bar.stats")
    //         },
    //         {
    //             path: "orders",
    //             component: ProductOrders,
    //             name: "productOrders",
    //             label: i18n.t("product_bar.orders")
    //         },
    //         {
    //             path: "history",
    //             component: ProductHistorie,
    //             name: "productHistory",
    //             label: i18n.t("product_bar.history")
    //         }
    //     ]
    // }
];