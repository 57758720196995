<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <div class="row">
                        <div v-if="users" class="col-md-6">
                            <select-component
                                    :separator="false"
                                    label="form.rayon"
                                    name= "rayon"
                                    v-if="users"
                                    objectToShow="label"
                                    v-model="selectedUser"
                                    class_name="reciever col-6 select__input"
                                    :data="users"
                                    @selectedEvent="selectUser">
                            </select-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="stats && !spinning">
            <div class="row m0 mb-3">
                <div class="section section__container">
                     <div v-if="stats != '' && stats.groups">
                        <h3>Omzet</h3>
                        <div class="row m0 border-bottom">
                            <div class="col-md-3 p-1 border-right"><strong>{{$i18n.t('rayon.group')}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{currentYear-2}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{$i18n.t('rayon.index')}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{$i18n.t('rayon.margin')}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{currentYear-1}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{$i18n.t('rayon.index')}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{$i18n.t('rayon.margin')}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{$i18n.t('rayon.prognosis')}}</strong></div>
                            <div class="col-md-1 p-1 border-right"><strong>{{$i18n.t('rayon.index')}}</strong></div>
                            <div class="col-md-1 p-1"><strong>{{$i18n.t('rayon.margin')}}</strong></div>
                        </div>
                        
                        <div v-for="group in stats.groups" v-bind:key="group" class="head row m0">
                            <div class="col-3 p-1 border-right">{{group}}</div>
                            <div class="col-1 p-1 ">{{stats.[currentYear-2].groups.[group].revenue}}</div>
                            <div class="col-1 p-1">{{stats.[currentYear-2].groups.[group].index}}</div>
                            <div class="col-1 p-1 border-right">{{stats.[currentYear-2].groups.[group].margin}}</div>
                            <div class="col-1 p-1">{{stats.[currentYear-1].groups.[group].revenue}}</div>
                            <div class="col-1 p-1">{{stats.[currentYear-1].groups.[group].index}}</div>
                            <div class="col-1 p-1 border-right">{{stats.[currentYear-2].groups.[group].margin}}</div>
                            <div class="col-1 p-1">{{stats.Prognose.groups.[group].revenue}}</div>
                            <div class="col-1 p-1">{{stats.Prognose.groups.[group].index}}</div>
                            <div class="col-1 p-1">{{stats.Prognose.groups.[group].margin}}</div>
                      </div>   
                    </div>
                </div>
            </div>
            <div v-if="stats != ''" class="row  mb-3">
                <div v-if="stats" class="col-md-4">
                    <div class="section section__container">
                        <h3>{{currentYear-2}}</h3>
                        <column-stats-component v-if="stats.[currentYear-2].stats.donut" :data="stats.[currentYear-2].stats.donut.values" :headers="stats.Prognose.stats.donut.headers"></column-stats-component>
                       
                        
                    </div>
                </div>
                <div v-if="stats" class="col-md-4">
                    <div class="section section__container">
                        <h3>{{currentYear-1}}</h3>
                        <column-stats-component v-if="stats.[currentYear-1].stats.donut" :data="stats.[currentYear-1].stats.donut.values" :headers="stats.Prognose.stats.donut.headers"></column-stats-component>
                    </div>
                </div>
                <div v-if="stats" class="col-md-4">
                    <div class="section section__container">
                        <h3>{{$i18n.t('rayon.prognosis')}}</h3>
                        <column-stats-component v-if="stats.Prognose.stats.donut" :data="stats.Prognose.stats.donut.values" :headers="stats.Prognose.stats.donut.headers"></column-stats-component>
                    </div>
                </div>
            </div>

            <!--------------------------------------------->

            <div class="" v-on:click="toggler('showDebtors')">
                <i :class="(showDebtors)?'fas fa-minus-square':'fas fa-plus-square'"></i>
                {{$i18n.t('rayon.debtors')}}
            </div>
            <div class="row m0 mb-3">
                <div v-if="showDebtors" class="col-12">
                    <stats-list name="debtors" :data="stats.debtors" :currentYear="currentYear"></stats-list>
                </div>
            </div>

            <div v-on:click="toggler('showRoutes')">
                <i :class="(showRoutes)?'fas fa-minus-square':'fas fa-plus-square'"></i>
                {{$i18n.t('rayon.routes')}}
            </div>
            <div class="row m0 mb-3">
                <div  v-if="showRoutes" class="col-12">
                    <stats-list name="routes" :data="stats.routes" :currentYear="currentYear"></stats-list>
                </div>
            </div>

            <div v-on:click="toggler('showBranches')">
                <i :class="(showBranches)?'fas fa-minus-square':'fas fa-plus-square'"></i>
                {{$i18n.t('rayon.branches')}}
            </div>
            <div class="row m0 mb-3">
                <div  v-if="showBranches" class="col-12">
                    <stats-list name="branches" :data="stats.vestigingen" :currentYear="currentYear"></stats-list>
                </div>
            </div>

            <div v-on:click="toggler('showConcepts')">
                <i :class="(showConcepts)?'fas fa-minus-square':'fas fa-plus-square'"></i>
                {{$i18n.t('rayon.concepts')}}
            </div>
             <div class="row m0 mb-3">
                <div class="col-12">
                    <stats-list v-if="showConcepts" name="concepts" :data="stats.concepts" :currentYear="currentYear"></stats-list>
                </div>
            </div>
        </div>
        <div v-else>
            <loading-spinner-manual :spinner="spinning"></loading-spinner-manual>
        </div>
    </div>
</template>
<script>
    import Api from "@/classes/helper/Api";
    import StatsList from "@/modules/rayon/components/StatsList";
    import SelectComponent from "@/components/partials/SelectComponent";
    import ColumnStatsComponent from "@/components/widgets/stats/ColumnStatsComponent"
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

    export default {
        name: 'RayonOverview',
        components: {SelectComponent, StatsList, LoadingSpinnerManual, ColumnStatsComponent},
        data() {
            return {
                statistics: [],
                api: new Api(),
                users: [],
                selectedUser: '',
                listHeaders:['year', 'marge', 'index'],
                response: [],
                stats: '',
                showDebtors: false,
                showConcepts: false,
                showRoutes: false,
                showBranches: false,
                currentYear: new Date().getFullYear(),
                spinning: true,
            }
        },
        mounted() {
            this.fetchUsers();
        },
        methods: {
            iconToggler: function(name){
                let icon = '<i class="fas fa-plus-square"></i>';

                if(this.[name] === true){
                    icon = '<i class="fas fa-minus-square"></i>';
                }

                return icon;
            },
            fetchUsers(){
                this.api.get("manager-rayon", []).then((response) => {
                    response.data.forEach(user => {
                        this.users.push({label: user.fullname + ' ' + user.rayon, value: user.rayon});
                    });
                });

                this.fetchRayonStatistics();
            },
            selectUser(user){
                this.selectedUser = user[1];
                this.spinning = true;
                this.fetchRayonStatistics();
                
            },
            fetchRayonStatistics() {
                this.api.get("rayonstasticics", [{'rayon': this.selectedUser }]).then((response) => {
                    this.stats = response.data;
                    this.spinning = false;
                });
            },
            toggler(name){
                this.[name] = !this.[name];
            },
        }

    };
</script>
<style scoped>
    .align-middle{
        text-align:center;
    }
</style>