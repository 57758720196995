<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <h2 class="page__title">{{ $t('form.title.rapport') }}</h2>
      </div>
      <div class="col-md-6 text-right pt-4">
        <button class="button button-green" v-if="!short" v-on:click="short = true">Beknopte rapportage invoeren
        </button>
        <button class="button button-green" v-else v-on:click="short = false">Uitgebreide rapportage invoeren</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <form-builder :form="form_debtor_1"></form-builder>
      </div>
      <div class="col-md-6">
        <form-builder :form="form_debtor_2"></form-builder>
      </div>
      <div class="col-md-12">
        <form-builder :form="form_debtor_3"></form-builder>
      </div>
    </div>
    <div v-if="!short">
      <span class="list__separator mt-4"></span>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>Automaterialen</h2>
            </div>
            <div class="col-md-6">
              <p class="pt-1 text-right">
                {{ $t('form.rapport_question_title') }}
              </p>
            </div>
          </div>
          <form-builder :form="form.section_car"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>Banden</h2>
            </div>
            <div class="col-md-6">
              <p class="pt-1 text-right">
                {{ $t('form.rapport_question_title') }}
              </p>
            </div>
          </div>
          <form-builder :form="form.section_tyre"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>Gereedschappen</h2>
            </div>
            <div class="col-md-6">
              <p class="pt-1 text-right">
                {{ $t('form.rapport_question_title') }}
              </p>
            </div>
          </div>
          <form-builder :form="form.section_tools"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>Kentekenplaten</h2>
            </div>
            <div class="col-md-6">
              <p class="pt-1 text-right">
                {{ $t('form.rapport_question_title') }}
              </p>
            </div>
          </div>
          <form-builder :form="form.section_plates"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>Vloeistoffen</h2>
            </div>
            <div class="col-md-6">
              <p class="pt-1 text-right">
                {{ $t('form.rapport_question_title') }}
              </p>
            </div>
          </div>
          <form-builder :form="form.section_liquids"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>Concepten</h2>
            </div>
            <div class="col-md-6">
              <p class="pt-1 text-right">
                {{ $t('form.rapport_question_title') }}
              </p>
            </div>
          </div>
          <form-builder :form="form.section_concepts"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2>Opleidingen en Kostech Specialist</h2>
            </div>
          </div>
          <form-builder :form="form.section_education"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2>Automatisering</h2>
            </div>
          </div>
          <form-builder :form="form.section_automation"></form-builder>
        </div>
      </div>
      <span class="list__separator mt-4"></span>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2>Actiepunten volgende bezoek</h2>
            </div>
          </div>
          <form-builder :form="form.section_action_points"></form-builder>
        </div>
      </div>
    </div>
    <span class="list__separator mt-4"></span>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <h2>Algmene opmerkingen</h2>
          </div>
        </div>
        <form-builder :form="form.section_remarks"></form-builder>
      </div>
    </div>
  </div>
  <span class="list__separator mt-4"></span>
  <div class="row mb-4 mt-2">
    <div class="col-md-12">
      <div class="float-right">
        <button class="button button-green-inverse" v-on:click="submitForm">{{ $t("form.save") }}</button>
        <button class="button button-danger-inverse ml-4">{{ $t("form.empty") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import FormBuilder from "@/components/partials/FormBuilder";

let form = require("@/forms/createRapportForm.json");
export default {
  name: "RapportCreate",
  components: {FormBuilder},
  data() {
    return {
      short: false,
      form: form,
      form_debtor_1: form.debtor_1,
      form_debtor_2: form.debtor_2,
      form_debtor_3: form.debtor_3
    }
  },
}
</script>

<style scoped>

</style>