<template>
    <div class="loader__container" :class="{'loader__container-hidden' : !spinner}">
        <div class="loader"></div>
    </div>
</template>

<script>
    export default {
        name: "LoadingSpinner",
        props: ['id'],
        data() {
            return {
                spinner: false
            }
        },
        watch: {
            '$store.state.helperStore.spinner': function () {
                this.spinner = this.$store.state.helperStore.spinner;
            }
        }
    }
</script>

<style scoped>
.loader__container-hidden {
    opacity: 0 !important;
    display: none !important;
}

.loader__container {
    width: 100%;
    height: 100%;
    display: block;
    /*background-color: ;*/
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid rgba(7, 109, 25, 0.3);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

</style>
