<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="section section__container">
                <h1 class="page__title">{{ $i18n.t('general.report_print') }}</h1>
                <loading-spinner-manual :spinner="sendingEmail"></loading-spinner-manual>
                <div class="row">
                    <div class="col-md-6">
                        <input-component v-model="from_debtor" label="debtor.from"></input-component>
                         <date-picker-component v-model="from_date" label="form.from_date" class="mt-4" style="height: 40px"></date-picker-component>
                    </div>
                    <div class="col-md-6">
                        <input-component v-model="to_debtor" label="debtor.to" ></input-component>
                        <date-picker-component v-model="to_date" label="form.to_date" style="height: 40px" class="mt-4"></date-picker-component>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <time-picker-component v-model="from_time" label="form.from_time"></time-picker-component>
                    </div>
                    <div class="col-md-6">
                        <time-picker-component v-model="from_time" label="form.to_time"></time-picker-component>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <select-component
                            :separator="false"
                            label="form.rayon"
                            name= "rayon"
                            v-if="users"
                            objectToShow="email"
                            v-model="rayon"
                            class_name="search__form__input"
                            :data="users"
                            label_class="search__form__label search__form__label-active"
                            @selectedEvent="selectedRayon">
                        </select-component>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <span class="list__separator mb-2 mt-3"></span>
                    </div>
                    <div class="col-md-6">
                        <input-component v-model="sending_to" :initial-value="sending_to" label="form.email"></input-component>
                    </div>
                    <div class="col-md-6">
                        <button class="button button-green-inverse mr-2 mt-2 float-right" v-on:click="printMailRapport">
                            {{ $i18n.t('general.report_print-mail') }}
                        </button>
                        <button class="button button-green-inverse mr-2 mt-2 float-right" v-on:click="printableRapport">
                            {{ $i18n.t('general.report_print') }}
                        </button>
                        <button class="button button-green-inverse mr-2 mt-2 float-right" v-on:click="mailOnlyRapport">
                            {{ $i18n.t('general.report_mail') }}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputComponent from "@/components/partials/InputComponent";
    import DatePickerComponent from "@/components/partials/DatePickerComponent";
    import TimePickerComponent from "@/components/partials/TimePickerComponent";
    import SelectComponent from "@/components/partials/SelectComponent";
    import User from "@/classes/User";
    import Report from "@/modules/report/classes/Report";
    import Api from "@/classes/helper/Api";
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

    export default {
        name: "ReportPrint",
        components: {
            LoadingSpinnerManual,
             TimePickerComponent, DatePickerComponent, InputComponent, SelectComponent},
        data() {
            return {
                timeConfig: {
                    type: 'string',
                    mask: 'iso',
                },
                sendingEmail: false,
                user: new User(),
                report: new Report(),
                users: [],
                rayon: "",
                queryParams: [],
                api: new Api(),
                from_time: "",
                to_time: "",
                from_debtor: "",
                to_debtor: "",
                from_date: "",
                to_date: "",
                sending_to: this.$store.state.generalStore.user.email

            }
        },
        created() {
            this.fetchUsers();
        },
        methods: {
            selectedRayon(rayon){
                this.rayon = rayon[1];
            },
            printMailRapport() {
                window.open(this.api.apiUrl + `/crm/report/download?debtor=${this.from_debtor}&to_debtor=${this.to_debtor}&date=${this.from_date}&to_date=${this.to_date}&time=${this.from_time}&to_time=${this.to_time}&rayon=${this.rayon}&mail=${this.sending_to}&access_token=${this.$store.state.generalStore.user.api_key}`, "_blank")
            },
            mailOnlyRapport() {
                this.sendingEmail = true;
                this.report.route_prefix = 'report'
                let route = `/download?debtor=${this.from_debtor}&to_debtor=${this.to_debtor}&date=${this.from_date}&to_date=${this.to_date}&time=${this.from_time}&to_time=${this.to_time}&mail=${this.sending_to}&mail-only=true&rayon=${this.rayon}&access_token=${this.$store.state.generalStore.user.api_key}`
                this.report.get(route, [], true).then(() => {
                    this.report.alert.createAlert('success', "Rapportages zijn verstuurd");
                    this.sendingEmail = false;
                }).catch(() => {
                    this.sendingEmail = false;
                });
            },
            printableRapport() {
                window.open(this.api.apiUrl + `/crm/report/download?debtor=${this.from_debtor}&to_debtor=${this.to_debtor}&date=${this.from_date}&to_date=${this.to_date}&time=${this.from_time}&to_time=${this.to_time}&rayon=${this.rayon}&access_token=${this.$store.state.generalStore.user.api_key}`, "_blank")
            },
            fetchUsers() {
                this.api.get("rayonmanager", []).then((response) => {
                    response.data.forEach(user => {
                        this.users.push({label: user.fullname  + ' ' + user.rayon , value: user.rayon});
                    });
                    
                   
                });
            }
               
        }
    }
</script>

<style scoped>


</style>