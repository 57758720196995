<template>
    <div>
        <div class="irel__address text-right">
            <p class="text-noSpace">{{ data.contact_details.name }}</p>
            <p class="text-noSpace">{{ data.contact_details.address }}</p>
            <p class="text-noSpace">{{ data.contact_details.zipcode }} {{ data.contact_details.city }}</p>
        </div>
        <div class="irel__data">
            <p>
                {{ data.texts.dear }} {{ data.contact_details.contact }},
            </p>
            <p>{{ data.texts.intro_text }}</p>
            <div class="col-md-6">
                <div id="chart">
                    <apexchart type="bar" height="480" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
            <list-component title="Totalen" :headers="headersList" :data="dataList" :totals="totalsParsed" type="money"></list-component>
            <list-component title="Op basis van deze prognose heeft u recht op:"></list-component>
            <list-component title="Overzicht volgende staffel:"></list-component>
            <p>{{ $t('bonus.questions')}}</p>
            <p>{{ $t('bonus.regards')}}</p>
            <p></p>
            <p>{{ $t('koskamp')}}</p>
            <p></p>
            <p class="text-noSpace">{{ data.contact_details.rm_name }}</p>
            <p class="text-noSpace">{{ data.contact_details.rm_phone }}</p>
            <p class="text-noSpace">{{ data.contact_details.rm_email }}</p>
        </div>
    </div>
</template>

<script>
    import ListComponent from "@/components/partials/Listcomponent";
    import MoneyParser from "@/repositories/MoneyParser";

    export default {
        name: "iRelLetter",
        components: {ListComponent},
        props: ['data'],
        watch: {
            data(val) {
                this.parseDataCharts(val)
            }
        },
        methods: {
            parseDataCharts(val) {
                this.series = [];
                this.headersList = [];
                for (let data in val.chart_data) {
                    let tmpData = [
                        this.$t(data),
                        '€ ' + MoneyParser.parseNumberToIncludeDots(val.chart_data[data][0]).toString(),
                        '€ ' + MoneyParser.parseNumberToIncludeDots(val.chart_data[data][1]).toString(),
                        '€ ' + MoneyParser.parseNumberToIncludeDots(val.chart_data[data][2]).toString()];
                    this.dataList.push(tmpData);
                    this.series.push({
                        name: data,
                        data: val.chart_data[data]
                    })

                }
                let tmpData = [this.$t("total"), this.data.totals[0], this.data.totals[1], this.data.totals[2]];
                this.totalsParsed = tmpData;

                this.headersList.push(' ');
                this.headersList.push(this.data.searchable_years[this.data.searchable_years.length-3]);
                this.headersList.push(this.data.searchable_years[this.data.searchable_years.length-2]);
                this.headersList.push(this.data.searchable_years[this.data.searchable_years.length-1]);
            }
        },
        data() {
            return {
                headersList: [],
                dataList: [],
                totalsParsed: [],
                series: [{
                    name: 'PRODUCT A',
                    data: [44, 55, 41, 67, 22]
                }, {
                    name: 'PRODUCT B',
                    data: [13, 23, 20, 8, 13]
                }, {
                    name: 'PRODUCT C',
                    data: [11, 17, 15, 15, 21]
                }, {
                    name: 'PRODUCT D',
                    data: [21, 7, 25, 13, 22]
                }],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            // return val + "%";
                            return val;
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },

                    xaxis: {
                        categories: [
                            this.data.searchable_years[this.data.searchable_years.length-3],
                            this.data.searchable_years[this.data.searchable_years.length-2],
                            this.data.searchable_years[this.data.searchable_years.length-1]
                        ],
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: false,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: true
                        },
                        axisTicks: {
                            show: true,
                        },
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return val + "";
                            }
                        }
                    },
                },
            }
        }
    }
</script>

<style scoped>

</style>