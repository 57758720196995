const settingsStore = {
    state: {
        activeReportDebtor: undefined,
        reportSettings: ''
    },
    mutations: {
        setActiveReportDebtor(state, value){
            state.activeReportDebtor = value;
        },
        saveReportSettings(state, reportSettings) {
            localStorage.removeItem('rapport');
            localStorage.setItem('rapport', JSON.stringify(reportSettings));
            state.reportSettings = reportSettings
        },
        initSettings(state) {
            state.reportSettings = JSON.parse(localStorage.getItem('rapport'));
        }
    },
    actions: {

    },
    getters: {}
};

export default settingsStore;
