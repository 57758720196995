<template>
   <div class="row">
        <div class="section section__container">
            <h3>{{$i18n.t('rayon.' + name)}}</h3>
            <div class="row m0 border-bottom">
                <div class="col-3"><strong>{{$i18n.t('rayon.debtor')}}</strong></div>
                <div class="col-1"><strong>{{currentYear-2}}</strong></div>
                <div class="col-1"><strong>{{$i18n.t('rayon.index')}}</strong></div>
                <div class="col-1 border-right"><strong>{{$i18n.t('rayon.margin')}}</strong></div>
                <div class="col-1"><strong>{{currentYear-1}}</strong></div>
                <div class="col-1"><strong>{{$i18n.t('rayon.index')}}</strong></div>
                <div class="col-1 border-right"><strong>{{$i18n.t('rayon.margin')}}</strong></div>
                <div class="col-1"><strong>{{$i18n.t('rayon.prognosis')}}</strong></div>
                <div class="col-1"><strong>{{$i18n.t('rayon.index')}}</strong></div>
                <div class="col-1"><strong>{{$i18n.t('rayon.margin')}}</strong></div>
            </div>
            <div v-for="(item, key) in data" v-bind:key="item">
              <div class="head row m0 border-bottom">
                  <div class="col-3 py-3">{{ key + ' ' + item.[currentYear-2].description}}</div>
                  <div class="col-1 py-3">{{item.[currentYear-2].revenue}}</div>
                  <div class="col-1 py-3">{{item.[currentYear-2].index}}</div>
                  <div class="col-1 py-3 border-right">{{item.[currentYear-2].margin}}%</div>
                  <div class="col-1 py-3">{{item.[currentYear-1].revenue}}</div>
                  <div class="col-1 py-3">{{item.[currentYear-1].index}}</div>
                  <div class="col-1 py-3 border-right">{{item.[currentYear-2].margin}}%</div>
                  <div class="col-1 py-3">{{item.Prognose.revenue}}</div>
                  <div class="col-1 py-3">{{item.Prognose.index}}</div>
                  <div class="col-1 py-3">{{item.Prognose.margin}}%</div>
              </div>
             
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "StatsList",
  props: ["data" , "currentYear" , "name"],
  data() {
    return {
      showTooltip: false
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>

</style>
