<template>
    <div>
        <breadcrumbs-bar></breadcrumbs-bar>
        <div>
            <h2 class="page__title debit__result-small__title display-inline">{{ $i18n.t('general.rayon') }}</h2>
        </div>
    </div>
</template>

<script>
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";

    export default {
        name: "RayonHeader",
        components: {BreadcrumbsBar},
    }
</script>

<style scoped>

</style>