<template>
    <div class="page__contents">
        <div class="row mt-4">
            <div class="col-md-8">
                <div class="section section__container" v-if="info">
                    <div class="table__list">
                        <div class="d-flex justify-content-between">
                            <h2 class="page__title">{{ $i18n.t('general.stock_info') }}</h2>
                            <div class="mt-4">
                                <button class="button button-small button-green-inverse pl-4 pr-4"
                                        :class="{'button-green-inverse-active': $store.state.generalStore.enableEdit}"
                                        v-on:click="toggleEdit">
                                    {{ $i18n.t('general.mutate') }}
                                </button>
                            </div>
                        </div>

                        <table class="table">
                            <thead>
                            <tr>
                                <th>{{ $i18n.t('general.stock_depot') }}</th>
                                <th>{{ $i18n.t('general.stock') }}</th>
                                <th>{{ $i18n.t('order.ordered') }}</th>
                                <th>{{ $i18n.t('general.stock_min') }}</th>
                                <th>{{ $i18n.t('general.stock_max') }}</th>
                                <th>{{ $i18n.t('order.amount_sold') }}</th>
                                <th>{{ $i18n.t('general.mutation_latest') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(stock, index) in articleStock" v-bind:key="index">
                                <td>{{ stock.company }}</td>
                                <td>{{ stock.stock }}</td>
                                <td>{{ stock.ordered }}</td>
                                <td>
                                    <edit-component id="min" @dataChanged="mutateStock" name="min"
                                                    :data="stock.minimum"></edit-component>
                                </td>
                                <td>
                                    <edit-component id="max" @dataChanged="mutateStock" name="max"
                                                    :data="stock.maximum"></edit-component>
                                </td>
                                <td>{{ stock.omz12 }}</td>
                                <td>{{ stock.lastmutation }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="section section__container" v-if="info">
                    <h2 class="list__title">
                        {{ $i18n.t('creditor.creditor') }}
                    </h2>
                    <product-creditor-list :data="info"></product-creditor-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductCreditorList from '@/modules/product/components/ProductCreditorList';
    import Product from '@/modules/product/classes/Product';
    import EditComponent from '@/components/partials/form/EditComponent';

    export default {
        name: 'ProductStock',
        components: {EditComponent, ProductCreditorList},
        data() {
            return {
                articleStock: [],
                info: this.$store.state.productStore.activeProduct,
                product: new Product(this.$route.params.product)
            }
        },
        methods: {
            toggleEdit() {
                this.$store.commit("setEnableEdit", !this.$store.state.generalStore.enableEdit);
            },
            mutateStock(value, name) {
                this.product.fillable.push(name);
                this.product[name] = value;
                this.product.update(this.product).then(() => {
                    this.product.alert.createAlert('success', 'Vooraad is gemuteerd');
                });
            }
        },
        created() {
            this.product.get("/" + this.product.id + "/stock").then((response) => {
                this.articleStock = response.data;
            });
        },
        mounted() {
            this.info = this.$store.state.productStore.activeProduct
        },
        watch: {
            '$store.state.productStore.activeProduct': function () {
                this.info = this.$store.state.productStore.activeProduct

            },
        }
    };
</script>

<style scoped>

</style>