<template>

    <div class="breadcrumbBar" :class="{'breadcrumbBar-large': sidebar}">
        <router-link class="breadcrumbBar__button" :to="{name: 'dashboardGeneral', params: {index: 0}}">
            <i class="fas fa-home breadcrumbBar__icon"></i>
        </router-link>
        <div v-for="link in pieces" v-bind:key="link" class="display-inline">
            <p class="display-inline ml-1 mr-1">/</p>
            <router-link class="breadcrumbBar__button" :to="link.url">
                {{ link.name }}
            </router-link>

        </div>

    </div>
</template>

<script>
    export default {
        name: "BreadcrumbsBar",
        props: ['links'],
        data() {
            return {
                sidebar: false,
                pieces: []
            }
        },
        created() {
            this.calcRoute();
        },
        methods: {
            calcRoute() {
                this.pieces = [];
                let tmpPieces = this.$route.path.split('/')
                tmpPieces.splice(0, 1);
                let tmpUrl = "";
                tmpPieces.forEach((piece) => {
                    tmpUrl = tmpUrl + "/" + piece;
                    let tmp = {
                        name: piece,
                        url: tmpUrl
                    }
                    this.pieces.push(tmp)
                })
            }
        },
        watch: {
            $route() {
                this.calcRoute();
            },
            '$store.state.helperStore.sidebar': function () {
                this.sidebar = this.$store.state.helperStore.sidebar;
            }
        }
    }
</script>

<style scoped>

</style>
