import { createWebHistory, createRouter } from "vue-router";
import dashboardRoutes from "@/modules/dashboard/dashboardRoutes";
import Login from "@/pages/Login";
import User from "@/classes/User";
import debitRoutes from "@/modules/debtor/debitRoutes";
import settingsRoutes from "@/modules/settings/settingsRoutes";
import orderRoutes from '@/modules/order/orderRoutes';
import creditRoutes from '@/modules/creditor/creditRoutes';
import productRoutes from '@/modules/product/productRoutes';
import reversionRoutes from '@/router/reversionRoutes';
import managerRoutes from '@/modules/manager/managerRoutes';
import actionPointRoutes from '@/modules/action-point/actionPointRoutes';
import Standard from '@/pages/layout/Standard';
import UnderConstruction from '@/pages/error/UnderConstruction';
import agendaRoutes from '@/modules/agenda/agendaRoutes';
import reportRoutes from '@/modules/report/reportRoutes';
import rayonRoutes from '@/modules/rayon/rayonRoutes';

let routes = [
    {
        path: '/login',
        component: Login,
        name: "login"
    },
];

routes = routes.concat(dashboardRoutes);
routes = routes.concat(debitRoutes);
routes = routes.concat(settingsRoutes);
routes = routes.concat(orderRoutes);
routes = routes.concat(creditRoutes);
routes = routes.concat(productRoutes);
routes = routes.concat(reversionRoutes);
routes = routes.concat(managerRoutes);
routes = routes.concat(actionPointRoutes);
routes = routes.concat(agendaRoutes);
routes = routes.concat(reportRoutes);
routes = routes.concat(rayonRoutes);

routes.push({
    path: "/:pathMatch(.*)",
    component: Standard,
    components: {
        default: UnderConstruction
    },
    name: "/page-not-found"
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    let user = new User(JSON.parse(localStorage.getItem("user")));
    if (to.name !== 'login' && !user.isAuthenticated()){
        next({ name: 'login' })
    }
    else {
        next()
    }
})

export default router;
