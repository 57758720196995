<template>
    <div v-if="!spinning">
        <detail-component :title="$i18n.t('debtor.payment_time')" :data="roundig(data.payment_time) + ' ' +$i18n.t('debtor.days')"> {{}}</detail-component>
        <detail-component :title="$i18n.t('debtor.unpaid_amount')" symbol="&euro;" :data="transformFloat(data.unpaid_amount)"></detail-component>
        <detail-component :title="$i18n.t('debtor.unpaid_invoices')" :data="transformFloat(data.unpaid_invoices)"></detail-component>
        <detail-component :title="$i18n.t('debtor.open_amount')" symbol="&euro;" :data="transformFloat(data.open_amount)"></detail-component>
        <detail-component :title="$i18n.t('debtor.open_orders')" :data="data.open_orders"></detail-component>
    </div>
     <div class="row" v-else>
         <loading-spinner-manual :spinner="spinning"></loading-spinner-manual>
    </div>
</template>

<script>
    import DetailComponent from "@/components/partials/DetailComponent";
    import Debtor from "@/modules/debtor/classes/Debtor";
    import LoadingSpinnerManual from "@/components/partials/LoadingSpinnerManual";

    export default {
        name: "DebitOpenPostsComponent",
        components: {DetailComponent, LoadingSpinnerManual},
        props: [],
        data() {
            return {
                debit: new Debtor(),
                spinning: true,
                data: [],
            }
        },
        mounted() {
            this.getPaymentInfo();
        },
        methods: {
            getPaymentInfo() {
                this.debit.all('/' + this.$route.params.number + "/paymentinfo").then((response) => {
                    this.data = response.data;
                    this.spinning = false;
                });
            },

            roundig(number){
               return Math.round(number);
            },

            transformFloat( number){
                return Number(number).toLocaleString('nl-NL',  {});
            }
        }
    }
   
</script>

<style scoped>

</style>