<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Zoeken', url:'debitSearch'},{name: 'Info', url: 'debitInfo'}]"></breadcrumbs-bar>
        <div v-if="$store.state.debitStore.activeDebtor">
            <h2 class="page__title debit__result-small__title display-inline">{{ $store.state.debitStore.activeDebtor.name }}</h2>
            <p class="debit__result-small display-inline">- Debiteur
                #{{ $store.state.debitStore.activeDebtor.debtor_number }}</p>
        </div>
    </div>
</template>
<script>
    import i18n from '@/i18n';
    import BreadcrumbsBar from '@/components/html/BreadcrumbsBar';
    import Debtor from '@/modules/debtor/classes/Debtor';
    import AlertBar from "@/classes/AlertBar";
    export default {
        name: 'DebitHeader',
        components: { BreadcrumbsBar },
        data() {
            return {
                debit: new Debtor()
            }
        },
        mounted() {
            this.fetchDebtor();
        },
        methods: {
            fetchDebtor() {
                this.debit.id = this.$route.params.number;

                this.debit.show(this.debit).then((response) => {
                    this.$store.commit("setActiveDebtor", response.data.debtor);
                    if(response.data.debtor.actionPoints){
                        if (Object.entries(response.data.debtor.actionPoints).length > 0){
                            let alertBar = new AlertBar();
                            alertBar.route = this.debit.id;
                            alertBar.color = "red";
                            alertBar.title =   i18n.t("debtor.alert.action-point.title");
                            this.$store.commit('addAlert', alertBar);
                        }
                    }
                });
            }
        }
    };
</script>

<style scoped>

</style>