<template>
  <div class="">
    <div class="section section__container">
      <h2 class="page__title">
        Hoe betaald de klant?
      </h2>
      <div class="section">
        <p>Betaalwijze</p>
        <div>
          <label>
            <input type="radio" v-model="paymentType" value="0">
            Kas
          </label>
          <br>
          <label>
            <input type="radio" v-model="paymentType" value="1">
            Pin
          </label>
        </div>
      </div>
      <span class="list__separator"></span>
      <div class="section">
        <button class="button button-half button-green-inverse display-inline">Selecteer alles</button>
        <button class="button button-half button-green-inverse display-inline">Deselecteer alles</button>
      </div>
      <span class="list__separator"></span>
      <div class="section">
        <p>Totaal geselecteerd bedrag</p>
        <p>Totaal ingevuld voor betaling</p>
      </div>
      <span class="list__separator"></span>
      <div class="section">
        <p>Rest bedrag</p>
      </div>
      <span class="list__separator"></span>
      <div class="d-flex flex-row-reverse">
        <button class="button button-half button-green align-right">Afrekenen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckOutCard",
  data() {
    return {
      paymentType: 0
    }
  },
  methods: {
    buttonClicked() {
      this.$emit("buttonEvent");
    }
  }
}
</script>

<style scoped>

</style>
