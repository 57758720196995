<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <h2 class="page__title">Historie</h2>
            <filter-component :filter="history.filter" :options="[1, 3]"></filter-component>
            <div v-for="(inv, index) in history.filter.arrayFilter" v-bind:key="inv">
                    <information-bar :filter="history.filter" :index="index" :object="inv">
                        <template v-slot:title>
                            <h2 class="debit__result__title">{{ inv.invoice_number }}</h2>
                        </template>
                        <template v-slot:content>
                            <div class="row">
                                <information-bar-content header="Factuurdatum" :text="inv.invoice_date"></information-bar-content>
                                <information-bar-content header="Order bedrag" :text="inv.amount"></information-bar-content>
                                <information-bar-content header="Opdracht" :text="inv.order_number"></information-bar-content>
                            </div>
                        </template>
                        <template v-slot:foldout>
                            <div class="row">
                                <information-bar-content header="Leverdatum" :text="inv.delivery_date"></information-bar-content>
                                <information-bar-content header="Routetijd" :text="inv.route_time"></information-bar-content>
                                <information-bar-content header="Routecode" :text="inv.route_code"></information-bar-content>
                            </div>
                            <div class="row">
                                <information-bar-content header="Status" :text="inv.status"></information-bar-content>
                                <information-bar-content header="Verkoper" :text="inv.seller"></information-bar-content>
                                <information-bar-content header="Verkoper 2" :text="inv.seller_second"></information-bar-content>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="list__separator"></span>
                                    <order-packing :data="inv.packing"></order-packing>
                                </div>
                            </div>
                        </template>
                    </information-bar>

            </div>
            <div v-if="history.filter.arrayFilter.length === 0">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="section section__container">
                            <h2 class="page__title">Er is geen geschiedenis</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import History from "@/modules/debtor/classes/History";
    import FilterComponent from "@/components/partials/FilterComponent";
    import Hotkey from "@/classes/helper/Hotkey";
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    import OrderPacking from "@/modules/order/components/OrderPacking";

    export default {
        name: "History",
        components: {OrderPacking, InformationBarContent, InformationBar, FilterComponent},
        data() {
            return {
                headers: [],
                tableData: [],
                history: new History(this.$route.params.number),
                hotkey: new Hotkey(['ArrowUp', 'ArrowDown'])
            }
        },
        mounted() {
            this.getOrders();
        },
        methods: {
            getOrders() {
                this.history.all().then((response) => {
                    this.history.filter.setArrayFilter(response.data);
                });
            }
        },
        watch: {
            '$store.state.hotkeyStore.keyArrowDown': function () {
                if (this.$store.state.hotkeyStore.keyArrowDown) {
                    this.history.filter.increaseSelected()
                }
            },
            '$store.state.hotkeyStore.keyArrowUp': function () {
                if (this.$store.state.hotkeyStore.keyArrowUp) {
                    this.history.filter.decreaseSelected()
                }
            },
        }
    }
</script>

<style scoped>

</style>
