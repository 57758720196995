<template>
    <div>
        <input-component ref="inputSearch" :id="className" :class="[disabled ? 'select-disabled' : '' ]" v-model="showValue" :initial-value="modelValue" no-trans="true" :label="label" @changeEvent="searchElement" @focusEventIn="openSelect" @focusEventOut="closeSelect" :autocomplete="autocomplete" :disabled="disabled" ></input-component>
        <span class="danger__text" :class="{'danger__text-active': danger}">{{ danger_text }}</span>
        <div class="container">
            <div class="row">
                <div class=" col-md-12 container-max" :class="[hiddenClosed ? 'select__closed' : 'select-open' ]">
                    <div class="select__item " v-for="(item, index) in activeItems" v-bind:key="item" v-on:click="clickedItem(item)">
                        {{ item[objectToShow] }}
                        <span class="list__separator" v-if="index !== activeItems.length-1"></span>
                        <span v-else class="list__separator-deactive"></span>
                    </div>
                </div>
            </div>
            <div v-if="importData">
                <button class="button button-green-inverse float-right" v-on:click="openExcelInput">Import</button>
                <input type="file" id="excelFile" accept=".xlsx, .xls, .csv" ref="excelFile" style="visibility: hidden; height: 0; width: 0;" @change="uploadFile"/>
            </div>
        </div>
    </div>
</template>

<script>
    import InputComponent from '@/components/partials/InputComponent';
    import Api from "@/classes/helper/Api";

    export default {
        name: 'SelectWithSearchComponent',
        components: { InputComponent },
        props: [
            'items', 
            'objectToShow', 
            'objectValue', 
            'modelValue', 
            'danger', 
            'danger_text', 
            'label', 
            'separator', 
            'className', 
            'autocomplete', 
            'disabled',
            'importData'
            ],
        emits: ['update:modelValue'],
        data() {
            return {
                api: new Api(),
                hidden: true,
                hiddenClosed: true,
                activeItem: {},
                activeItems: this.items,
                value: [],
                showValue:"",
            };
        },
        methods: {
            clickedItem(item) {
                let values = '';

                if(this.$refs.inputSearch.value === ''){
                    this.value = [];
                }
                
                if (this.separator) {
                    this.value.push(item);
                    
                    let labels = this.labelMapping();
                    values = this.valueMapping();

                    this.showValue = labels.join(', ')
                }else {
                    this.value = [];
                    this.showValue = [];

                    this.value.push(item);

                    let labels = this.labelMapping();
                    values = this.valueMapping();

                    this.showValue = labels.join('')
                }
                
                this.$emit('update:modelValue', values);
            },
            valueMapping() {
                let values = this.value.map(function(object) {
                    return object.[this.$props.objectValue];
                }.bind(this))

                return values
            },
            labelMapping() {
                let labels = this.value.map(function(object) {
                    return object.[this.$props.objectToShow];
                }.bind(this))

                return labels
            },
            searchElement($event) {
                if (this.separator) {
                    let elementToSeparate = $event[1].split(" ");
                    $event[1] = elementToSeparate[elementToSeparate.length-1];
                }
                this.activeItems = this.items.filter((item) => {
                    return item[this.objectToShow].toLowerCase().includes(String($event[1]).toLowerCase())
                });
            },
            uploadFile(event) {
                let file = event.target.files[0];
                let form = new FormData();
                form.append('file', file);
               
                this.api.post("company/parser", form).then((response) => {
                    this.value = response.data;
                    this.showValue = response.data.join();

                    this.$emit('update:modelValue', this.value);
                });
            },
            openExcelInput() {
                this.$refs.excelFile.click();
            },

            openSelect() {
                setTimeout(() => {
                    this.hiddenClosed = false;
                }, 200);
                this.hidden = false;
            },
            closeSelect() {
                 setTimeout(() => {
                    this.hiddenClosed = true;
                }, 200);
            }
        }
    };
</script>

<style scoped>
.container-max {
    max-height: 300px;
}
.select__item {
    display: block;
    transition: color 0.2s ease-in-out;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    position:relative;
    z-index: 99999999;
}

.select__item:hover {
    color: rgba(7, 109, 25, 0.5);
}

.select-open {
    display:inline;
    position: absolute;
    transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
    opacity: 1;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    background-color: white;
    z-index:9999;
}

.select-hidden {
    height: 0;
    
}
.select__closed {
    position: relative;
    z-index: -1000;
    display:none;
}
.select-disabled {
    background-color: lightgray;
}
</style>