<template>
    <div>
        <information-bar :filter="filter" :object="creditor" :no-fold="true">
            <template v-slot:title>
                <h2 class="search__result__title">{{ creditor.naam }}</h2>
            </template>
            <template v-slot:shortcuts>
                <credit-button :number="4" :selected="selected" :active="active" link="creditHistory" :creditor="creditor"
                               tooltip="Historie" shortcut="4" code="HI"></credit-button>
                <credit-button :number="3" :selected="selected" :active="active" link="creditPosts" :creditor="creditor"
                               tooltip="Open posten" shortcut="3" code="OP"></credit-button>
                <credit-button :number="1" :selected="selected" :active="active" link="creditInfo" :creditor="creditor"
                                tooltip="Crediteur info" shortcut="1" code="IN"></credit-button>
            </template>
            <template v-slot:content>
                <information-bar-content header="Crediteurnummer" :text=" creditor.creditor_number "></information-bar-content>
                <information-bar-content header="Adres" :text="creditor.address"></information-bar-content>
                <information-bar-content header="Telefoon" :text="creditor.phone"></information-bar-content>
            </template>
        </information-bar>
    </div>
</template>

<script>
    import InformationBar from "@/components/partials/information/InformationBar";
    import InformationBarContent from "@/components/partials/information/InformationBarContent";
    import CreditButton from "@/modules/creditor/components/CreditButton";
    export default {
        name: "CreditSearchResult",
        components: {CreditButton, InformationBarContent, InformationBar},
        props: ['active', 'creditor', 'selected', 'filter'],

    }
</script>

<style scoped>

</style>