import Filter from '@/classes/helper/Filter';
import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel{
    creditors = [];
    searchQuery = "";
    filter = new Filter();
    constructor() {
        super('creditor')
    }



}