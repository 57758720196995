import GeneralModel from '@/classes/helper/GeneralModel';

export default class extends GeneralModel {
    fillable = ['layout', 'name', 'type'];
    layouts = [];
    layoutsNamedList = [];
    constructor(id = "", route_affix = "") {
        route_affix = "report-layout" + route_affix;
        if (id !== "") {
            super(route_affix + "/" +id)
        } else {
            super(route_affix)
        }
    }
    layoutsParsed() {
        this.layouts.forEach((layout) => {
            layout.layout = JSON.parse(layout.layout);
            this.layoutsNamedList.push(layout.name);
        });
    }
}