import i18n from '@/i18n';
import Reversion from '@/pages/layout/Reversion';
import ReversionCreate from '@/pages/reversion/ReversionCreate';

export default [
    {
        name: 'reversion',
        component: Reversion,
        children: [
            {
                path: "/reversion/create",
                component: ReversionCreate,
                name: "reversionCreate",
                label: i18n.t("reversion_bar.create")
            }
        ]
    }
];