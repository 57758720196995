<template>
    <div>
        <div class="">
            <div class="table__list">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="page__title">{{ $i18n.t('general.reports') }}</h2>
                    </div>
                   
                </div>
                <table class="table" v-if="data.length > 0">
                    <thead>
                    <tr>
                        <th scope="col">Datum</th>
                        <th scope="col">Doel</th>
                        <th scope="col">Type</th>
                        <th scope="col"> </th>

                    </tr>
                    </thead>
                    <tbody v-if="data">
                    <tr v-for="(report, indexCol) in data" v-bind:key="indexCol">
                        <td>{{ report.visited }}</td>
                        <td>{{ report.goal }}</td>
                        <td>{{ report.type }}</td>
                        <td>
                            <button class="button-min button-min-green-inverse" v-on:click="showReport(report)">
                                <i class="fas fa-eye"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <th>Er zijn geen Rapportages</th>
                    </tr>
                    </tbody>
                </table>
                <div v-else>
                    <div class="row">
                        <div class="col-12 text-center">
                            <h2 class="page__title">Er zijn nog geen rapportages</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DebitReportList',
        props: ['data'],
        methods: {
            showReport(report) {
                let debtorNumber = (this.$route.params.number)? 
                this.$route.params.number : this.$store.state.settingsStore.reportSettings.defaultDebit ;

                this.$router.push({
                    name: "debitReportShow", params: {
                        number: debtorNumber,
                        report: report.id
                    }
                })
            }
        }
    };
</script>
<style scoped>

</style>