<template>
    <div class="dashboard_bar">
        <div class="dashboard_bar__container">
            <router-link v-for="link in settingRoutes" class="dashboard_bar__link" v-bind:key="link.path" :to="{name: link.name}">
                {{ link.label }}
                <span class="dashboard_bar__link-underbar"
                      :class="{'dashboard_bar__link-underbar-active' : $route.name === link.name}"></span>
            </router-link>
        </div>
    </div>
</template>

<script>
    let orderRoutesFile = require('../../modules/order/orderRoutes');
    export default {
        name: "OrderBar",
        data() {
            return {
                settingRoutes: []
            }
        },
        created() {
            this.settingRoutes = orderRoutesFile.default[0].children
        }
    }
</script>

<style scoped>

</style>
