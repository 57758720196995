<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Zoeken', url: 'creditSearch'}]"></breadcrumbs-bar>
        <h2 class="page__title">Crediteuren</h2>
        <div class="row">
            <div class="col-md-12">
                <credit-search-component></credit-search-component>
            </div>
        </div>
    </div>

</template>

<script>
    import CreditSearchComponent from "@/modules/creditor/components/CreditSearchComponent";
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    export default {
        name: "CreditSearch",
        components: {BreadcrumbsBar, CreditSearchComponent},
        created() {

        }
    }
</script>

<style scoped>

</style>