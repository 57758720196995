<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <div class="row">
                        <div class="col-md-6">
                            <select-component
                                :separator="false"
                                label="form.rayon"
                                name= "rayon"
                                v-if="users"
                                objectToShow="email"
                                v-model="selectedUser"
                                class_name="reciever col-6 select__input"
                                :data="users"
                                @selectedEvent="selectUser">
                            </select-component>
                        </div>
                        <div class="col-md-6">
                            <button v-if="$store.state.generalStore.user.checkPermissionTo(['manage_visit_schema_import'])" class="button button-green-inverse float-right" v-on:click="openExcelInput">EXCEL importeren
                            </button>
                            <a class="button button-green-inverse float-right" :href="authorizedUrl" target="_blank" style="text-align:center; font-weight:normal">Authorizeren</a>
                            <input type="file" id="excelFile" accept=".xlsx, .xls, .csv" ref="excelFile" style="visibility: hidden; height: 0; width: 0;" @change="uploadFile"/>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6 mb-4">
                <div class="section section__container">
                    <agenda-component :rayon="selectedUser"></agenda-component>
                </div>
            </div>
            <div class="col-md-6">
                <chances-component :label="$i18n.t('general.chances')" :data="chances"></chances-component>
                <chances-component :label="$i18n.t('general.threats')" :data="threats" class="mt-4"></chances-component>
            </div>
        </div>

    </div>
</template>
<script>
    import AgendaComponent from '@/modules/agenda/components/AgendaComponent';
    import ChancesComponent from '@/modules/agenda/components/ChancesComponent';
    import SelectComponent from "@/components/partials/SelectComponent";
    import Lead from "@/modules/agenda/classes/Lead";
    import Api from "@/classes/helper/Api";
    import store from '@/store';

    export default {
        name: 'AgendaIndex',
        components: { ChancesComponent, AgendaComponent,SelectComponent },
        data() {
            return {
                users: [],
                selectedUser: undefined,
                lead: new Lead(),
                api: new Api(),
                threats: [],
                chances: []
            }
        },
        mounted() {
            this.fetchData();
        },
        computed: {
            authorizedUrl: function() {
                return `${store.state.generalStore.apiUrl}/microsoft/auth/redirect`;
            }
        },
        methods: {
            uploadFile(event) {
                let file = event.target.files[0];
                let form = new FormData();
                form.append('file', file);

                if(this.selectedUser){
                    form.append('rayon', this.selectedUser.rayon);
                }
                
                // form.append("_method", "PATCH");
                this.api.post("visitschema/import", form).then(() => {
                    this.lead.alert.createAlert('success', 'Het excel bestand is upgeload');
                });
            },
            openExcelInput() {
                this.$refs.excelFile.click();
            },
            fetchData() {
                this.lead.all().then((response) => {
                    this.chances = response.data.chances
                    this.threats = response.data.threats
                });

                this.api.get("manager-rayon", []).then((response) => {
                    response.data.forEach(user => {
                        this.users.push({label: user.rayon + ' ' + user.fullname, value: user.rayon});
                    });
                });
            },
            selectUser(selectedEvent) {
                this.selectedUser = selectedEvent[1];

            },
        }

    };
</script>

<style scoped>

</style>