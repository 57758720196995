<template>
    <div class="row m0 px-1">
        <h2 class="list__title">{{$i18n.t('general.eduction_info')}}</h2>
    </div>
    <div class="row m0" v-for="training in data" v-bind:key="training">
        <span class="list__separator"/>
        <div class="col-6 pt-3">
            <label>{{training.description}}</label>
        </div>
        <div class="col-6 pt-3">
            <p>{{training.takes_part}}</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "EductionInfo",
        components: {},
        props: ['data']
    }
</script>