<template>
    <div class="section section__container">
        <h2 class="page__title">{{ $t('orders.overview') }}</h2>
    </div>
</template>

<script>
    export default {
        name: "OrderOverview"
    }
</script>

<style scoped>

</style>