<template>
    <div v-if="content.value !== '' && disabled">
        <div class="display-inline edit__buttons">
            <i class="fas fa-edit icon__show" :class="{'icon__hidden':!disabled}" v-on:click="setEditable"></i>
            <i class="fas fa-trash ml-2 icon__show color-danger" :class="{'icon__hidden':!disabled}" v-on:click="deleteLabel"></i>
        </div>
        <input-component :label="content.value" disabled="true" no-trans="true" type="area" v-if="content.capital === 'large'"></input-component>
        <input-component :label="content.value" disabled="true" no-trans="true" v-else></input-component>
       
    </div>
    <div v-else>
        <div class="row">
            <div class="col-12">
                <input-component :initial-value="value" label="Vul hier de waarde in"  no-trans="true" @changeEvent="changedValue">
                    <template v-slot:buttonEnd>
                        <div class="button_end">
                            <i class="fas fa-save icon__show" :class="{'icon__hidden':disabled}" v-on:click="removeEditable"></i>
                        </div>
                    </template>
                </input-component>
            </div>
        </div>
    </div>
</template>

<script>
    import DragElements from "@/plugins/DragDrop/classes/DragElements";
    import InputComponent from "@/components/partials/InputComponent";

    export default {
        name: "DragInput",
        components: {InputComponent},
        props: ['content', 'indexCol', 'indexRow', 'index'],
        data() {
            return {
                disabled: false,
                value: "",
                dragElements: new DragElements(this.indexRow, this.indexCol, this.index)
            }
        },
        created() {
            if (this.content.value !== "") {
                this.value = this.content.value;
                this.disabled = true;
            }
        },
        methods: {
            deleteLabel() {
                this.dragElements.deleteContent();
            },
            changedValue(e) {
                this.value = e[1];
            },
            setEditable() {
                this.value = this.content.value;
                this.disabled = false;
            },
            removeEditable() {
                if (this.value !== "") {
                    this.disabled = true;
                    this.dragElements.setValue(this.value);
                }

            }
        }
    }
</script>

<style scoped>

</style>