<template>
    <div v-if="data">
        <span class="list__separator"></span>
        <detail-component :title="$i18n.t('debtor.number')" :data="data.debtor_number"></detail-component>
        <debit-edit-component v-if="data.name" :debit="debit" name="name" :label="$i18n.t('general.name')" :data="data.name"></debit-edit-component>
        <div v-if="data.contacts" class="row m0 mt-2">
            <div class="col-5"><p class="pt-1">{{$i18n.t('general.contacts')}}</p></div>
            <div class="col-7">
                <div class="row" v-for="contact in data.contacts.contact" v-bind:key="contact">
                    <div class="col-6"><label class="label">{{contact.name}}</label></div>
                    <div class="col-6"><label class="label">{{contact.position}}</label></div>
                    <hr>
                </div>
            </div>
        </div>
        <span class="list__separator"/>
        <debit-edit-component v-if="data.address" :debit="debit" name="adres" :label="$i18n.t('general.address')" :data="data.address"></debit-edit-component>
        <debit-edit-component v-if="data.zipcode"  :debit="debit" name="postkode" :label="$i18n.t('general.zipcode')" :data="data.zipcode"></debit-edit-component>
        <debit-edit-component v-if="data.city"  :debit="debit" name="plaats" :label="$i18n.t('general.place')" :data="data.city"></debit-edit-component>
        <debit-edit-component v-if="data.alfacode"  :debit="debit" name="alfakode" :label="$i18n.t('general.alfa_code')" :data="data.alfacode"></debit-edit-component>
        <debit-edit-component v-if="data.phone"  :debit="debit" name="telefoon" :label="$i18n.t('general.phone')" :data="data.phone"></debit-edit-component>
        <debit-edit-component v-if="data.phone2"  :debit="debit" name="telefoon2" :label="$i18n.t('general.phone') + '2'" :data="data.phone2"></debit-edit-component>
        <debit-edit-component v-if="data.telefax"  :debit="debit" name="telefax" :label="$i18n.t('general.telefax') + '2'" :data="data.telefax"></debit-edit-component>
        <debit-edit-component v-if="data.email"  :debit="debit" name="email" :label="$i18n.t('form.email')" :data="data.email"></debit-edit-component>
        <debit-edit-component v-if="data.email2"  :debit="debit" name="email2" :label="$i18n.t('form.email') + '2'" :data="data.email2"></debit-edit-component>
        <debit-edit-component v-if="data.group" :debit="debit" name="groep" :label="$i18n.t('general.group') + '2'" :data="data.group"></debit-edit-component>
        <debit-edit-component v-if="data.address"  :debit="debit" name="alfakode2" :label="$i18n.t('general.alfa_code') + '2'" :data="data.alfacode2"></debit-edit-component>
        <debit-edit-component v-if="data.address" :debit="debit" name="kortingkode" :label="$i18n.t('general.discount_code')" :data="data.discount_code"></debit-edit-component>
        <debit-edit-component :debit="debit" name="rayon" :label="$i18n.t('general.rayon')" :data="data.rayon"></debit-edit-component>
        <debit-edit-component :debit="debit" name="bonsoort" :label="$i18n.t('general.ticket_kind')" :data="data.receipt_type"></debit-edit-component>
        <debit-edit-component :debit="debit" name="verzendkode" :label="$i18n.t('general.send_code')" :data="data.send_code"></debit-edit-component>
        <debit-edit-component :debit="debit" name="betaalkode"
        :label="$i18n.t('general.payment_code')" :data="data.paymnet_code"></debit-edit-component>
        <debit-edit-component :debit="debit" name="filiaal" :label="$i18n.t('general.branch')" :data="data.branch"></debit-edit-component>
        <debit-info-row v-if="data.number_of_engineers" :debit="debit" name="aantal_monteurs" :label="$i18n.t('general.number_engineers')" :data="data.number_of_engineers"></debit-info-row>
        <debit-info-row v-if="data.accounting_software" :debit="debit" name="boekhoud_software" :label="$i18n.t('general.accounting_software')" :data="data.accounting_software"></debit-info-row>
        <debit-info-row v-if="data.garage_software" :debit="debit" name="garage_software" :label="$i18n.t('general.garage_software')" :data="data.garage_software.garage_software.description"></debit-info-row>

        <div v-if="data.specialisms" class="row m0 mt-2">
            <div class="col-5"><p class="pt-1">{{$i18n.t('general.specialism')}}</p></div>
            <div class="col-7">
                <div v-for="specialism in data.specialisms.specialism" v-bind:key="specialism">
                    <label class="label">{{specialism}}</label>
                </div>
            </div>
        </div>

        <span class="list__separator"/>
        <debit-info-row v-if="data.bovag_member" :debit="debit" name="bovag_lid" :label="$i18n.t('general.bovag_member')" :data="data.bovag_member"></debit-info-row>
        <debit-info-row v-if="data.type_garage" :debit="debit" name="type_garage" :label="$i18n.t('general.type_garage')" :data="data.type_garage"></debit-info-row>
        <debit-info-row v-if="data.apk_monitor" :debit="debit" name="apk_monitor" :label="$i18n.t('general.apk_monitor')" :data="data.apk_monitor.active"></debit-info-row>
        
        <debit-info-row :debit="debit" name="kostech_specialist" :label="$i18n.t('general.tech_specialist')" :data="techSpecialist"></debit-info-row>
        <debit-info-row :debit="debit" name="status" :label="$i18n.t('general.status')" :data="data.status"></debit-info-row>
        <debit-info-row :debit="debit" name="zaterdag_route" :label="$i18n.t('general.saterday_route')" :data="data.saturday_route"></debit-info-row>
    </div>
</template>
<script>
    import DetailComponent from "@/components/partials/DetailComponent";
    import DebitEditComponent from "@/modules/debtor/components/DebitEditComponent";
    import DebitInfoRow from "@/modules/debtor/components/DebitInfoRow";
    
    export default {
        name: "DebitDetails",
        components: {DebitEditComponent, DetailComponent, DebitInfoRow},
        props: ['data', 'debit'],
        mounted() {
        },
        computed: {
            techSpecialist(){
                if(this.$props.data.tech_specialist !== undefined && 'is_tech_specialist' in this.$props.data.tech_specialist ){
                      return this.$props.data.tech_specialist.is_tech_specialist + ' ' + this.$props.data.tech_specialist.number_logged ;
                }
                return '-';
            }
        },
        methods: {
            toggleEdit() {
                if (this.$store.state.generalStore.enableEdit) {
                    this.$store.commit("setEnableEdit", false);
                } else {
                    this.$store.commit("setEnableEdit", true);
                }
            },
        }
    }
</script>

<style scoped>

</style>