<template>
    <div>
        <breadcrumbs-bar :links="[{name: 'Zoeken', url: 'productSearch'}]"></breadcrumbs-bar>
        <h2 class="page__title">{{ $i18n.t('general.product') }}</h2>
        <product-search-component></product-search-component>
    </div>
</template>

<script>
    import ProductSearchComponent from "@/modules/product/components/ProductSearchComponent";
    import BreadcrumbsBar from "@/components/html/BreadcrumbsBar";
    export default {
        name: "ProductSearch",
        components: {BreadcrumbsBar, ProductSearchComponent}
    }
</script>

<style scoped>

</style>