<template>
    <div class="row">
        <div class="col-md-12">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="color-main-green display-inline m0">
                            {{ comment.user }}
                        </p>
                        <p class="color-main-green float-right m0">
                            {{ comment.time }} | {{ comment.date }}
                        </p>
                    </div>
                    <div class="col-md-12" style="word-wrap: break-word">
                        <p>{{ comment.comment }}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ActionInfoShowComment',
        props: ['comment'],
        created() {}
    };
</script>

<style scoped>

.list__separator-white {
    background-color: white;
}
</style>