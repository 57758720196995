<template>
    <div>
        <div class="section section__container mt-3" :id="'filter__search-' + index"
             :class="{'section__container-selected': object.selected}" v-on:click="filter.setSelected(index)">

            <div>
                <slot name="title"></slot>
                <slot name="shortcuts"></slot>
            </div>

            <slot name="content"></slot>
            <div style="position:relative;" v-if="!noFold">
                <div class="chevron__container" v-on:click="toggleFold" :class="{'chevron__active': openFold}">
                    <i class="fas fa-chevron-up" ></i>
                </div>
            </div>


            <div class="hidden" :class="{'open': openFold}">
                <slot name="foldout"></slot>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "InformationBar",
        props: ['filter', 'index', 'object', 'noFold'],
        data() {
            return {
                openFold: false
            }
        },
        methods: {
            toggleFold() {
                this.openFold = !this.openFold
            }
        }
    }
</script>

<style scoped>
.chevron__container {
    position: absolute;
    right: 0;
    bottom: -10px;
    float: right;
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: rotate(180deg);
    transition: 0.2s ease-in-out;
    z-index: 1000;
}

.chevron__active {
    transform: rotate(0deg);
    bottom: -50px;

}

.hidden {
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition: opacity 0.2s ease-in-out, max-height 0.2s ease-in-out;
}

.open {
    overflow: visible;
    opacity: 1;
    max-height: 1000px;
}
</style>