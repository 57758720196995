<template>
<div>
    <div class="row mt-4">
        <div class="col-md-7">
            <order-debtor></order-debtor>
        </div>
        <div class="col-md-3">
            <order-overview></order-overview>
        </div>
        <div class="col-md-2">
            <order-totals></order-totals>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="section section__container pb-5 mb-3">
                <h2 class="page__title">Retour plaatsen</h2>
                <div class="row">
                    <div class="col-md-4">
                        <input-component name="name" @changeEvent="setValue" label="form.salesman"></input-component>
                        <input-component name="code" @changeEvent="setValue" label="form.order_code" class="mt-4"></input-component>
                    </div>
                </div>
                <span class="list__separator"></span>
                <div class="float-right">
                    <button class="button button-green-inverse" v-on:click="sendOrder">Order plaatsen</button>
                    <button class="button button-danger-inverse ml-1">Order leegmaken</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import OrderDebtor from "@/modules/order/components/OrderDebtor";
    import OrderOverview from "@/modules/order/components/OrderOverview";
    import OrderTotals from "@/modules/order/components/OrderTotals";
    import Reversion from "@/classes/Reversion";
    import InputComponent from "@/components/partials/InputComponent";
    export default {
        name: "ReversionCreate",
        components: {InputComponent, OrderTotals, OrderOverview, OrderDebtor},
        data () {
            return {
                reversion: new Reversion
            }
        },
        methods: {
            setValue(val) {
                this.reversion[val[0]] = val[1];
            },
            sendOrder() {
            }
        }
    }
</script>

<style scoped>

</style>