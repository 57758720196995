<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="section section__container">
                    <h2 class="page__title">Debiteur zoeken</h2>
                    <debit-search-debtors class="max-height-400" v-if="!debtor"></debit-search-debtors>
                    <div v-else>
                        <div class="row">
                            <div class="col-md-6">
                                <p>Naam</p>
                                <p>Adress</p>
                            </div>
                            <div class="col-md-6">
                                <p>{{debtor.naam}}</p>
                                <p>{{debtor.adres}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="section section__container">
                    <h2 class="page__title">Manager koppelen</h2>
                    <div>
                        <div v-for="(user, index) in users" v-bind:key="user.email" class="row">
                            <div class="col-md-1">
                                <checkbox-component id="email" v-model="user.checked" :name="user.id" @update:model-value="userClicked($event, index)"></checkbox-component>
                            </div>
                            <div class="col-md-10">
                                <p>{{ user.email }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="debtor">
            <div class="col-md-12">
                <div class="section section__container">
                    <button class="button button-green-inverse" v-on:click="linkUser">Manager koppelen aan debiteur</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DebitSearchDebtors from '@/modules/debtor/components/DebitSearchDebtors';
    import Manager from '@/modules/manager/classes/Manager';
    import CheckboxComponent from '@/components/partials/checkboxComponent';
    import User from "@/classes/User";
    export default {
        name: 'ManagerLinkForm',
        components: { CheckboxComponent, DebitSearchDebtors },
        emits: ['managerLinked'],
        data() {
            return {
                debtor: undefined,
                manager: new Manager(),
                user: new User(),
                users: [],
                userLinked: undefined
            }
        },
        created() {
            this.fetchUsers()
        },
        methods: {
            linkUser() {
                this.manager.user_id = this.users[this.userLinked].id;
                this.manager.debtor_id = this.debtor.debiteurnummer;
                this.manager.store(this.manager).then((response) => {
                    this.$emit('managerLinked', response.data);
                });
            },
            userClicked(user, index) {
                if(this.userLinked === undefined) {
                    this.userLinked = index;
                }else {
                    this.users[this.userLinked].checked = false;
                    this.userLinked = undefined;

                    this.userLinked = index;
                    this.users[index].checked = true;

                }
            },
            fetchUsers() {
                this.user.get("/permission", [{'permission': "vrooam online" }]).then((response) => {
                    this.users = response.data;
                });
            }
        },
        watch: {
            '$store.state.debitStore.selectedDebtor': function() {
                if (this.$store.state.debitStore.selectedDebtor !== undefined) {
                    this.debtor = this.$store.state.debitStore.selectedDebtor;
                    this.$store.commit('setActiveSelectedDebtor', undefined);
                }

            }
        }
    };
</script>

<style scoped>

</style>