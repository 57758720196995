<template>
    <div class="row mt-4 mb-4">
        <div class="col-12">
            <div class="section section__container" v-if="report.activeReport">
                <h2 class="page__title">Rapportage #{{report.id}}</h2>
                <div class="row">
                    <div class="col-md-2">
                        <p v-if="report.activeReport.user">Ingevoerd door:</p>
                        <p>Ingevoerd op:</p>
                        <p>Doel:</p>
                    </div>
                    <div class="col-md-2">
                        <p v-if="report.activeReport.user">{{ report.activeReport.user.person.first_name }} {{ report.activeReport.user.person.last_name}}</p>
                        <p>{{ report.activeReport.visited }}</p>
                        <p>{{ report.activeReport.goal }}</p>
                    </div>
                </div>

                <debit-report-layout-builder :debtor="debtor" :activeReport="report.activeReport" :layouts="report.activeReport.layout" edit="false"></debit-report-layout-builder>
            </div>
        </div>
    </div>
</template>

<script>
    import Debtor from "@/modules/debtor/classes/Debtor";
    import Report from "@/modules/report/classes/Report";
    import DebitReportLayoutBuilder from '@/modules/report/components/DebitReportLayoutBuilder';

    export default {
        name: "DebitReportShow",
        components: {DebitReportLayoutBuilder},
        data() {
            return {
                report: new Report(this.$route.params.report),
                debtorObject: new Debtor(this.$route.params.number),
                debtor: undefined,
            }
        },
        created() {
            this.report.id = this.$route.params.report;
            this.fetchDebtor(this.$route.params.number);
            this.fetchReport();
        },
        methods: {
            fetchReport() {
                this.report.show(this.report).then((response) => {
                    this.report.activeReport = response.data;
                    this.report.activeReport.layout = JSON.parse(this.report.activeReport.layout.layout);
                });
            },
            fetchDebtor(){
                this.debtorObject.show(this.debtorObject).then((response) => {
                    this.debtor = response.data.debtor;
                });
            },
            saveReport(report) {
                report.report_layout_id = this.report.activeReport.report_layout_id;
                this.report.report = JSON.stringify(report);
                
                this.report.update(this.report).then(() => {
                    this.$router.push({name: 'debitReport'});
                });
            },
        }
    }
</script>

<style scoped>

</style>