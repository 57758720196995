// import Dashboard from "@/pages/layout/Dashboard";
// import DashboardOrders from "@/pages/dashboard/DashboardOrders";
// import DashboardDaily from "@/pages/dashboard/DashboardDaily";
// import DashboardWeekly from "@/pages/dashboard/DashboardWeekly";
// import DashboardMonthly from "@/pages/dashboard/DashboardMonthly";
// import DashboardYearly from "@/pages/dashboard/DashboardYearly";
// import DashboardIrel from "@/pages/dashboard/DashboardIrel";
import DashboardCreate from '@/modules/dashboard/pages/DashboardCreate';
import Page from '@/pages/layout/Page';
// import DashboardGeneral from '@/pages/dashboard/DashboardGeneral';
import UnderConstruction from '@/pages/error/UnderConstruction';
import DashboardHeader from '@/modules/dashboard/components/DashboardHeader';
import Standard from '@/pages/layout/Standard';

export default [
    {
        component: Page,
        name: "dashboardPage",
        children: [
            {
                path: "/create",
                component: DashboardCreate,
                name: "dashboardCreate"
            }
        ]
    },
    {
        component: Standard,
        name: 'dashboard',
        children: [
            {
                path: "/dashboard/:index",
                components: {
                    header: DashboardHeader,
                    default: UnderConstruction
                },
                name: "dashboardGeneral"
            },
            {
                path: "/orders",
                components: {
                    header: DashboardHeader,
                    default: UnderConstruction
                },
                name: "dashboardOrders"
            },
            // {
            //     path: "/daily",
            //     component: DashboardDaily,
            //     name: "dashboardDaily"
            // },
            // {
            //     path: "/weekly",
            //     component: DashboardWeekly,
            //     name: "dashboardWeekly"
            // },
            // {
            //     path: "/monthly",
            //     component: DashboardMonthly,
            //     name: "dashboardMonthly"
            // },
            // {
            //     path: "/yearly",
            //     component: DashboardYearly,
            //     name: "dashboardYearly"
            // },
            // {
            //     path: "/iRel",
            //     component: DashboardIrel,
            //     name: "dashboardIrel"
            // },

        ]

    }
];
