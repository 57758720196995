<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <h2 class="page__title">Bonussen</h2>
            <div v-if="years.length === 0">
                <div class="row mt-4">
                    <div class="col-12 text-center">
                        <div class="section section__container">
                            <h2 class="page__title">Er zijn geen Bonussen</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="section section__container">
                            <select-component label="Selecteer een jaar" :data="dataSelect" :selected-value="2016"
                                              @selectedEvent="selectedYear"></select-component>
                        </div>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="section section__container">
                            <h2 class="list__title">
                                bonusbrief
                            </h2>
                            <loading-spinner></loading-spinner>
                            <i-rel-letter :data="bonusData" v-if="bonusData"></i-rel-letter>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="section section__container mb-4" v-if="bonusData">
                            <h2>Verstuur deze bonusbrief</h2>
                            <input-component button-text="Verzenden" label="E-mailadres"
                                             @valueSearched="sendLetter"></input-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import SelectComponent from "@/components/partials/SelectComponent";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    import IRelLetter from "@/modules/debtor/components/iRelLetter";
    import InputComponent from "@/components/partials/InputComponent";
    import Bonus from '@/modules/debtor/classes/Bonus';

    export default {
        name: "DebitBonus",
        components: {InputComponent, IRelLetter, LoadingSpinner, SelectComponent},
        data() {
            return {
                years: [],
                year: 1,
                dataSelect: [],
                bonusData: false,
                basicData: [],
                bonus: new Bonus(this.$route.params.number)
            }
        },
        created() {
            this.fetchBasicData();
        },
        methods: {
            fetchBasicData() {
                this.bonus.all().then((response) => {
                    this.years = response.years;
                });
            },
            sendLetter() {

            },
            selectedYear(value) {
                // this.$store.commit("toggleSpinner");
                // this.api.get("/crm/bonus-letter/fetch", [["debtor_id", this.$route.params.number], ["year", value]]).then((response) => {
                //     this.bonusData = response.data;
                //     this.$store.commit("toggleSpinner");
                // });

                console.log(value);
            }
        }
    }
</script>

<style scoped>

</style>