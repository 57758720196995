export default class {
    arrayFilter = [];
    arrayUnfilteredFilter = [];
    filterDirection = 'down';
    #filterOption = 3;
    selectedItem = undefined;
    useScroll = true;
    options = [];

    constructor() {
    }

    setArrayFilter(arrayFilter) {
        this.arrayFilter = arrayFilter;
        this.arrayUnfilteredFilter = arrayFilter;
    }

    changeFilterDirection() {
        if (this.filterDirection === 'up') {
            this.filterDirection = 'down';
            this.arrayFilter.reverse();
        } else {
            this.filterDirection = 'up';
            this.arrayFilter.reverse();
        }
    }

    setSelected(index) {
        if (this.arrayFilter[this.selectedItem] !== undefined) {
            this.arrayFilter[this.selectedItem].selected = false;
        }
        if (this.useScroll) {
            let element = document.getElementById('filter__search-' + this.selectedItem);
            if (element !== null) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }

        if (this.arrayFilter[index] !== undefined) {
            this.selectedItem = index;
            this.arrayFilter[index].selected = true;
        }
    }

    decreaseSelected() {
        if (0 < this.selectedItem) {
            this.setSelected(this.selectedItem - 1);
        }
    }

    increaseSelected() {
        if (this.arrayFilter.length - 1 > this.selectedItem) {
            this.setSelected(this.selectedItem + 1);
        }
    }

    /*
    * 1 = Number
    * 2 = Name
    * 3 = back to original list
     */
    filterOptions(option) {
        this.filterOption = option;

        switch (option) {
            case 1:
                this.arrayFilter.sort(function (a, b) {
                    return a.number - b.number;
                });
                break;
            case 2:
                this.arrayFilter.sort(function (a, b) {
                    let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
                break;
            case 3:
                this.arrayFilter = this.arrayUnfilteredFilter;
                break;
            case 4:
                this.arrayFilter.sort(function (a, b) {
                    return a.revenue - b.revenue;
                });
                break;
            case 5:
               this.arrayFilter.sort(function (a, b) {
                    let cityA = a.city.toLowerCase(), cityB = b.city.toLowerCase();
                    if (cityA < cityB) {
                        return -1;
                    }
                    if (cityA > cityB) {
                        return 1;
                    }

                    return 0;
                });
                break;
              
        }
    }
}