<template>
    <div>
        <div class="debit__search-container mb-3">
            <div class="">
                <search-input @valueSearched="fetchProduct" @focusEventOut="activeShortcuts = true"
                              @focusEventIn="activeShortcuts = false"></search-input>
                <loading-spinner></loading-spinner>
            </div>
        </div>
        <filter-component :options="[1, 3]" :filter="product.filter"></filter-component>
        <div ref="debit_search">
            <div class="mt-3" v-for="prduct in product.filter.arrayFilter"
                 v-bind:key="prduct.id"
                 :id="'debit__search-'+prduct.number">
                <product-search-result :filter="product.filter" :selected="selectedShortcut" :product="prduct"
                                       :active="prduct.selected"></product-search-result>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchInput from "@/components/partials/SearchInput";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    import Product from "@/modules/product/classes/Product";
    import FilterComponent from '@/components/partials/FilterComponent';
    import ProductSearchResult from "@/modules/product/components/ProductSearchResult";

    export default {
        name: "ProductSearchComponent",
        components: {ProductSearchResult, LoadingSpinner, SearchInput, FilterComponent},
        data() {
            return {
                product: new Product(),
                products: [],
                activeShortcuts: false,
                activeProduct: {},
                selectedShortcut: 1
            }
        },
        created() {
            this.product.hotkey.arrayListedKeys = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Enter'];
            this.product.filter.useScroll = true;
        },
        methods: {
            fetchProduct(searchQuery) {
                this.product.get("", [{"search": searchQuery}]).then((response) => {
                    if (!Array.isArray(response.data)) {
                        response.data = [response.data];
                    }
                    this.products = response.data;
                    this.product.filter.setArrayFilter(this.products);
                    this.product.filter.setSelected(0);
                    if (this.products.length > 0) {
                        this.activeProduct = this.products[0];
                        this.products[0].selected = true;
                    }

                });
            }
        },
        watch: {
            '$store.state.hotkeyStore.keyArrowRight': function (val) {
                if (val) {
                    this.selectedShortcut++;
                    if (this.selectedShortcut > 6) {
                        this.selectedShortcut = 1;
                    }
                }
            },
            '$store.state.hotkeyStore.keyArrowLeft': function (val) {
                if (val) {
                    this.selectedShortcut--;
                    if (this.selectedShortcut < 1) {
                        this.selectedShortcut = 6
                    }
                }
            },
            '$store.state.hotkeyStore.keyArrowUp': function (val) {
                if (val) {
                    this.product.filter.decreaseSelected();
                }
            },
            '$store.state.hotkeyStore.keyArrowDown': function (val) {
                if (val) {
                    this.product.filter.increaseSelected();
                }
            }
        }
    }
</script>

<style scoped>

</style>