<template>
    <div>
        <div class="debit__search-container mb-3">
            <search-input @valueSearched="fetchCredit" @focusEventOut="activeShortcuts = true"
                          @focusEventIn="activeShortcuts = false"></search-input>
            <loading-spinner></loading-spinner>
        </div>
        <filter-component :options="[1, 3]" :filter="credit.filter"></filter-component>
        <div>
            <div ref="debit_search">
                <div class="mt-3 mb-3" v-for="creditor in credit.filter.arrayFilter"
                     v-bind:key="creditor.number"
                     :id="'debit__search-'+creditor.number">
                    <credit-search-result  :filter="credit.filter" :selected="selectedShortcut" :creditor="creditor"
                                           :active="creditor.selected"></credit-search-result>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import SearchInput from "@/components/partials/SearchInput";
    import Credit from "@/modules/creditor/classes/Credit";
    import FilterComponent from "@/components/partials/FilterComponent";
    import LoadingSpinner from "@/components/partials/LoadingSpinner";
    import CreditSearchResult from "@/modules/creditor/components/CreditSearchResult";
    export default {
        name: "CreditSearchComponent",
        components: {CreditSearchResult, LoadingSpinner, FilterComponent, SearchInput},
        data() {
            return {
                credit: new Credit,
                activeShortcuts: false,
                creditors: [],
                activeProduct: {},
                selectedShortcut: 1
            }
        },
        created() {
            this.credit.hotkey.arrayListedKeys = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Enter'];
            this.credit.filter.useScroll = true;
        },
        methods: {
            fetchCredit(searchQuery) {
                this.credit.get("", [{"search": searchQuery}]).then((response) => {
                    if (!Array.isArray(response.data)) {
                        response.data = [response.data];
                    }
                    this.creditors = response.data;
                    this.credit.filter.setArrayFilter(this.creditors);
                    this.credit.filter.setSelected(0);
                    if (this.creditors.length > 0) {
                        this.activeProduct = this.creditors[0];
                        this.creditors[0].selected = true;
                    }
                });
            }
        },
        watch: {
            '$store.state.hotkeyStore.keyArrowRight': function (val) {
                if (val) {
                    this.selectedShortcut++;
                    if (this.selectedShortcut > 6) {
                        this.selectedShortcut = 1;
                    }
                }
            },
            '$store.state.hotkeyStore.keyArrowLeft': function (val) {
                if (val) {
                    this.selectedShortcut--;
                    if (this.selectedShortcut < 1) {
                        this.selectedShortcut = 6
                    }
                }
            },
            '$store.state.hotkeyStore.keyArrowUp': function (val) {
                if (val) {
                    this.credit.filter.decreaseSelected();
                }
            },
            '$store.state.hotkeyStore.keyArrowDown': function (val) {
                if (val) {
                    this.credit.filter.increaseSelected();
                }
            }
        }
    }
</script>

<style scoped>

</style>