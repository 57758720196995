import axios from 'axios';
import store from '@/store';
import router from '@/router';
import Alert from '@/classes/helper/Alert';

export default class {
    apiUrl = `${store.state.generalStore.apiUrl}/api`;
    route_prefix = '';

    constructor(route_prefix = '') {
        this.route_prefix = route_prefix;
        
        if(localStorage.getItem('wholesaler') !== null){
            this.apiUrl = `${localStorage.getItem('wholesaler')}/api`;
        }
    }

    errorHandler(err) {
        if (err.response !== undefined) {
            switch (err.response.status.toString()) {
                case '400':
                    Alert.createAlert('error', 'Er heeft een fout plaats gevonden. Oops');
                    break;
                case '403':
                    Alert.createAlert('warning', 'Uw sessie is verlopen.');
                    router.push({ name: 'login' });
                    break;
                case '404':
                    Alert.createAlert('warning', this.route_prefix + ' is niet gevonden');
                    break;
                case '422':
                    Alert.createAlert('warning', 'Het formulier is niet helemaal correct.');
                    break;
                case '500':
                    Alert.createAlert('error', 'Er heeft een fout plaats gevonden. Oops');
                    break;
            }
        }
    }

    async get(route_affix = '', query_params = [], no_query = false) {
        let query = '';
        if (! no_query) {
            query = '?';
            query_params.forEach((param) => {
                query += Object.keys(param)[0] + '=' + param[Object.keys(param)[0]];
            });
        }

        store.commit('toggleSpinner', true);
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/crm/' + this.route_prefix + route_affix + query).then((response) => {
                store.commit('toggleSpinner', false);
                resolve(response.data);
            }).catch((err) => {
                this.errorHandler(err);
                store.commit('toggleSpinner', false);
                reject(err.response);
            });
        });
    }

    async post(route_affix = '', body) {
        store.commit('toggleSpinner', true);
        return new Promise((resolve, reject) => {
            axios.post(this.apiUrl + '/crm/' + this.route_prefix + route_affix, body).then((response) => {
                store.commit('toggleSpinner', false);
                resolve(response.data);
            }).catch((err) => {
                this.errorHandler(err);
                store.commit('toggleSpinner', false);
                reject(err.response);
            });
        });
    }

    async all(route_affix = '') {
        store.commit('toggleSpinner');
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/crm/' + this.route_prefix + route_affix).then((response) => {
                store.commit('toggleSpinner');
                resolve(response.data);
            }).catch((err) => {
                this.errorHandler(err);
                store.commit('toggleSpinner');
                reject(err.response);
            });
        });
    }

    async allPrefix(route_prefix, route_affix = '', route_post_fix = '',) {
        store.commit('toggleSpinner');
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl + '/crm/' + route_prefix + route_affix + route_post_fix).then((response) => {
                store.commit('toggleSpinner');
                resolve(response.data);
            }).catch((err) => {
                this.errorHandler(err);
                store.commit('toggleSpinner');
                reject(err.response);
            });
        });
    }

    async search(searchQuery, route_affix = '', route_param = undefined, requestType = 'post') {
        store.commit('toggleSpinner', true);
        return new Promise((resolve, reject) => {
            let tmpForm = new FormData();
            if (route_param) {
                tmpForm.append(route_param, searchQuery);
            } else {
                tmpForm.append(this.route_prefix, searchQuery);
            }
            if (requestType === 'post') {
                axios.post(this.apiUrl + '/crm/' + this.route_prefix + '/search' + route_affix, tmpForm).then((response) => {
                    resolve(response.data);
                    store.commit('toggleSpinner');
                }).catch((err) => {
                    this.errorHandler(err);
                    reject(err.response);
                    store.commit('toggleSpinner');
                });
            } else {
                axios.get(this.apiUrl + '/crm/' + this.route_prefix + '/search' + route_affix, tmpForm).then((response) => {
                    resolve(response.data);
                    store.commit('toggleSpinner');
                }).catch((err) => {
                    this.errorHandler(err);
                    reject(err.response);
                    store.commit('toggleSpinner');
                });
            }
        });
    }

    async show(object, route_affix = '') {
        store.commit('toggleSpinner', true);
        return new Promise((resolve, reject) => {
            let uri = this.apiUrl + '/crm/' + this.route_prefix + '/' + object.id;
            if(route_affix !== ''){
                uri = this.apiUrl + '/crm/' + this.route_prefix + '/' + object.id + '/' + route_affix;
            }
            
            axios.get(uri).then((response) => {
                resolve(response.data);
                store.commit('toggleSpinner', false);
            }).catch((err) => {
                this.errorHandler(err);
                reject(err.response);
                store.commit('toggleSpinner', false);
            });
        });
    }

    async store(object, route_affix = '') {
        let fillable = object.fillable;
        let tmpForm = new FormData();
        if (fillable !== undefined) {
            for (let field of fillable) {
                if (object[field] !== undefined) {
                    tmpForm.append(field, object[field]);
                }
            }
        }
        store.commit('toggleSpinner', true);
        return new Promise((resolve, reject) => {
            axios.post(this.apiUrl + '/crm/' + this.route_prefix + route_affix, tmpForm).then((response) => {
                store.commit('toggleSpinner', false);
                resolve(response.data);
            }).catch((err) => {
                reject(err.response);
                this.errorHandler(err);
                store.commit('toggleSpinner', false);
            });
        });
    }

    async update(object, route_affix = '') {
        let fillable = object.fillable;
        let tmpForm = new FormData();
        if (fillable !== undefined) {
            for (let field of fillable) {
                if (object[field] !== undefined) {
                    tmpForm.append(field, object[field]);
                }
            }
        }

        let obj = {};
        tmpForm.forEach((value, key) => obj[key] = value);

        store.commit('toggleSpinner', true);
        return new Promise((resolve, reject) => {
            axios.patch(this.apiUrl + '/crm/' + this.route_prefix + '/' + object.id + route_affix, obj).then((response) => {
                store.commit('toggleSpinner', false);
                resolve(response.data);
            }).catch((err) => {
                reject(err.response);
                this.errorHandler(err);
                store.commit('toggleSpinner', false);
            });
        });
    }

    async delete(object, route_affix = '') {
        store.commit('toggleSpinner');
        return new Promise((resolve, reject) => {
            axios.delete(this.apiUrl + '/crm/' + this.route_prefix + '/' + object.id + route_affix).then((response) => {
                resolve(response.data);
                store.commit('toggleSpinner');
            }).catch((err) => {
                reject(err.response);
                this.errorHandler(err);
                store.commit('toggleSpinner');
            });
        });
    }
}
