<template>
    <div>
        <search-input @valueSearched="filterSearch" @focusEventOut="activeShortcuts = true"
              @focusEventIn="activeShortcuts = false"></search-input>
        <table-component :data="actionPoint.filtered" style="background-color: white">
            <template v-slot:title>
                <h2 class="page__title">{{ title }}</h2>
            </template>
            <template v-slot:header>
                <th>Debiteur</th>
                <th>Debiteur naam</th>
                <th>Aanmaker</th>
                <th>Toegewezen</th>
                <th>Onderwerp</th>
                <th>Beschrijving</th>
                <th>Streefdatum</th>
                <th>Controls</th>
            </template>
            <template v-slot:data>
                <tbody v-for="(actionPoint, index) in actionPoint.filtered" v-bind:key="actionPoint">
                    <tr>
                        <td v-if="actionPoint.company">{{ actionPoint.company.debtor_id }}</td>
                        <td v-else></td>
                        <td v-if="actionPoint.company">{{ actionPoint.company.name }}</td>
                        <td v-else></td>
                        <td v-if="actionPoint.owner">{{ actionPoint.owner.first_name }} {{ actionPoint.owner.last_name }}</td>
                        <td v-else></td>
                        <td v-if="actionPoint.user">{{ actionPoint.user.first_name }} {{ actionPoint.user.last_name }}</td>
                        <td v-else></td>
                        <td>{{ actionPoint.subject }}</td>
                        <td>
                            <p v-if="actionPoint.description < 8"> {{ actionPoint.description }}</p>
                            <p v-else>
                                {{ actionPoint.description.substring(0,12)+"..." }}
                            </p>
                        </td>
                        <td>{{ actionPoint.expires_at }}
    <!--                        <div v-if="actionPoint.expires_at" class="display-inline ml-2">-->
    <!--                            <div class="text-center color-main-green color-main-green-hover pointer display-inline"-->
    <!--                            v-on:click="addDays(actionPoint, index, 1)">-->
    <!--                                <div>-->
    <!--                                    <i class="fas fa-plus font-large"> 1</i>-->
    <!--                                    <span-->
    <!--                                        class="button-small-line color-main-green color-main-green-hover"></span>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div-->
    <!--                                class="text-center color-main-green color-main-green-hover pointer display-inline ml-2"-->
    <!--                                v-on:click="addDays(actionPoint, index, 7)">-->
    <!--                                <div>-->
    <!--                                    <i class="fas fa-plus font-large"> 7</i>-->
    <!--                                    <span-->
    <!--                                        class="button-small-line color-main-green color-main-green-hover"></span>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->
                        </td>
                        <td>
                            <div class="row">
                                <div class="col-xl-6" v-if="!noControles">
                                    <button class="button button-small button-green-inverse"
                                            v-on:click="finishAction(actionPoint, index)">Afvinken
                                    </button>
                                </div>
                                <div class="col-xl-6">
                                    <div class="text-center color-main-green color-main-green-hover pointer"
                                         :class="{'color-main-green-active': index===actionPointShows}">
                                        <div class="openWatch" v-on:click="showAction(actionPoint, index)">
                                            <i class="fas fa-search"></i>
                                            <span
                                                class="button-small-line color-main-green color-main-green-hover"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="shownActionPoint === actionPoint && show">
                        <td colspan="8">
                            <action-info-show @newComment="newComment" :actionPoint="actionPoint" id="showActionPoint"></action-info-show>  
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:empty>
                <p>Er zijn geen {{ title }}</p>
            </template>
        </table-component>
    </div>
</template>
<script>
    import TableComponent from '@/components/partials/TableComponent';
    import SearchInput from "@/components/partials/SearchInput";
    import ActionInfoShow from "@/modules/action-point/components/ActionInfoShow";

    export default {
        name: "ActionTabTable",
        components: {TableComponent, SearchInput, ActionInfoShow},
        props: ['actionPoint', 'actionPointShows', 'title', 'type', 'noControles'],
        emits: ['showAction', 'finishAction', 'addDays', 'newComment'],
        data() {
            return {
                shownActionPoint: '',
                show: false,
            }
        },
        created() {
            this.actionPoint.searchFilter('');
        },
        methods: {
            addDays(actionPoint, index, days) {
                this.$emit('addDays', actionPoint, index, days, this.type);
            },
            showAction(actionPoint, index) {
                if(actionPoint === this.shownActionPoint || this.shownActionPoint === ''  ){
                    this.show = !this.show;
                }else {
                    this.show = true;
                }
                
                this.shownActionPoint = actionPoint;
                this.$emit('showAction', actionPoint, index);
            },

            newComment(comment) {
                this.$emit('newComment', comment);
            },
            finishAction(actionPoint, index) {
                this.$emit('finishAction', actionPoint, index);
            },
            filterSearch(search){
                this.actionPoint.searchFilter(search);
            }
        }
    }
</script>

<style scoped>

</style>