const dashboardStore = {
    state: {
        selectedWidget: undefined,
        currentRowWidth: 0,
        layout: [],
        dashboardLayouts: [],
    },
    mutations: {
        setWidget(state, value) {
           state.selectedWidget = value;
        },
        setLayout(state, value) {
            let tmpLayout = [];
            value.forEach((row) => {
                let tmpRow = [];
                row.forEach((col) => {
                    if (col.settings) {
                        tmpRow.push(col);
                    }
                });
                if (tmpRow.length > 0) {
                    tmpLayout.push(tmpRow);
                }
            })
            state.layout = tmpLayout;
        },
        setDashboardLayouts(state, value) {
            state.dashboardLayouts = value;
        }
    },
    actions: {},
    getters: {}
};

export default dashboardStore;
